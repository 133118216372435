import React, { useContext, useState, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { Button, GridCol, GridRow, H3, Paragraph, Table } from "govuk-react";
import Moment from "moment";
import { RequestTranslation } from "../../../service/translation";
import { FormContext } from "../../../data/contexts/contexts";
import DataAccessService from "../../../service/httpUtility";

const styles = {
  table: {
    display: "block",
  },
  tableCell: {
      fontSize: "0.95rem",
  },
  button: {
      fontSize: "0.95rem",
      marginBottom: 0,
      width: "100%",
  },
  "@media (max-width: 960px)": {
    table: {
      overflowX: "auto",
      whiteSpace: "nowrap",
    },
  },
};

const OpenOrders = ({ classes }) => {
  const [formContext, setFormContext] = useContext(FormContext);
  const openOrders = formContext?.crqData?.OpenOrders || false;
  const crqData = formContext?.crqData || null;
  const orgType = formContext?.orgType || null;
  const tableHeaders = ["Order number", "Order type", "Quantity", "Order date", "Delivery date", "Status", ""];
  const RequestLabels = RequestTranslation(orgType, crqData.TestingApproach);
  const {
    ORDER_OVERVIEW: { OPEN_ORDERS_HEADING, OPEN_ORDERS_SUB_HEADING, NO_OPEN_ORDERS_TEXT, CANCEL_BUTTON_ENABLED_TEXT, CANCEL_BUTTON_DISABLED_TEXT },
  } = RequestLabels;
  const history = useHistory();

  const generateTableHeaders = () => {
    return <Table.Row>{tableHeaders.map((header) => <Table.CellHeader>{header}</Table.CellHeader>)}</Table.Row>;
  };

  const generateOpenOrdersRows =  () => {
    return openOrders && openOrders.length && openOrders.map((order, index) => {
        return (<Table.Row>
            <Table.Cell className={classes.tableCell} data-automatedtestingid={`order-number-${index}`}>{order?.orderNumber}</Table.Cell>
            <Table.Cell className={classes.tableCell} data-automatedtestingid={`order-type-${index}`}>{order?.orderType}</Table.Cell>
            <Table.Cell className={classes.tableCell} data-automatedtestingid={`order-quantity-${index}`}>{order?.testRequested || 0}</Table.Cell>
            <Table.Cell className={classes.tableCell} data-automatedtestingid={`order-date-created-${index}`}>{Moment(order?.createdDate)?.format("DD/MM/YYYY")}</Table.Cell>
            <Table.Cell className={classes.tableCell} data-automatedtestingid={`order-delivery-date-${index}`}>{order?.deliveryDate || "Not available"}</Table.Cell>
            <Table.Cell className={classes.tableCell} data-automatedtestingid={`order-status-${index}`}>{order?.status}</Table.Cell>
            <Table.Cell className={classes.tableCell}>{generateCancellationButton(order, index)}</Table.Cell>
        </Table.Row>) || "";
    }) || "";
  };
  
  const generateCancellationButton = (order, index) => {
    return <Button
        onClick={() => startOrderCancellation(order?.orderId, order?.orderNumber)}
        className={classes.button}
        disabled={order?.orderCancellationRequested}
        data-automatedtestingid={`order-cancel-button-${index}`}>
            {order?.orderCancellationRequested ? CANCEL_BUTTON_DISABLED_TEXT : CANCEL_BUTTON_ENABLED_TEXT}</Button>;
  };

  const startOrderCancellation = (orderId, orderCancellationNumber) => {
    setFormContext({
        ...formContext,
        orderId,
        orderCancellationNumber,
    });
    history.push({
        pathname: "/cancellation-request",
    });
  };

  return (
    <>
        <GridRow>
            <GridCol setWidth="two-thirds">
                <H3>{OPEN_ORDERS_HEADING}</H3>
                <Paragraph>{openOrders && openOrders?.length ? OPEN_ORDERS_SUB_HEADING : NO_OPEN_ORDERS_TEXT}</Paragraph>
            </GridCol>
        </GridRow>
        {openOrders && openOrders?.length > 0 &&
          <Table head={generateTableHeaders()} className={classes.table}>{generateOpenOrdersRows()}</Table>
        }
    </>
  );
};

export default withRouter(withStyles(styles)(OpenOrders));

OpenOrders.defaultProps = {};

OpenOrders.propTypes = {
  classes: PropTypes.shape({
    tableCell: PropTypes.string,
    button: PropTypes.string,
  }).isRequired,
};
