import React, { useEffect, useState } from "react";
import { LoadingBox } from "govuk-react";
import TrainingConfirmationForm from "./TrainingConfirmationForm";
import ErrorComponent from "../Error";

const TrainingConfirmation = () => {
  const [serviceErrorFormSub, setServiceErrorFormSub] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [serviceErrorFormSub]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loader]);

  const handleError = (msg) => {
    setServiceErrorFormSub(msg);
  };

  const handleLoader = (hideShowloader) => {
    setLoader(hideShowloader);
  };

  return (
    <>
      {serviceErrorFormSub &&
      <ErrorComponent message={serviceErrorFormSub} />}
      <LoadingBox
        loading={loader}
        backgroundColor="#fff"
        timeIn={100}
        timeOut={100}
        backgroundColorOpacity={0.85}
        spinnerColor="#000"
      >
        <TrainingConfirmationForm
          handleSubError={handleError}
          handleLoader={handleLoader}
        />
      </LoadingBox>
    </>
  );
};

export default TrainingConfirmation;
