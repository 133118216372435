import React, { useContext } from "react";
import { Button, H4, Panel, Paragraph } from "govuk-react";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { useHistory, withRouter } from "react-router-dom";
import { useAnalytics } from "@cube/global-components";
import { CancellationReceivedLabels } from "../../data/constants";
import DataAccessService from "../../service/httpUtility";
import { FormContext } from "../../data/contexts/contexts";

const styles = {
  btnSubmit: {
    marginTop: "30px",
  }, 
  SuccessPanel: {
    backgroundColor: "#00703c",
  },
};

const {
  PANEL_TITLE,
  BOLD_TITLE,
  HELP_TEXT,
  FINISH_BUTTON,
} = CancellationReceivedLabels;

const CancellationReceived = ({
  classes,
}) => {
  const { triggerEvent } = useAnalytics();
  const [formContext, setFormContext] = useContext(FormContext);

  // eslint-disable-next-line no-unused-vars

  const windowAllowed = localStorage.getItem("answer");
  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: "nhs:test:organisation-order:CancellationReceived",
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "CancellationReceived",
        },
      },
    };
    triggerEvent(analyticsData);
  } else {
    window.digitalData = null;
  }
  localStorage.setItem("endOfForm", true);

  const history = useHistory();
  const backToOverview = () => {
    DataAccessService.sendUON(formContext.crqId)
      .then((result) => {
        setFormContext({
          crqId: result.cqcRegistrationID,
          crqData: result,
          orgType: result.OrganisationType,
        });
        history.push({
          pathname: "/overview",
        });
      })
      .catch((e) => {
        console.log("Error from Salesforce", e);
      });
  };

  return (
    <>
      <div data-automatedtestingid="orderform-cancellation-panel">
        <Panel
          className={classes.SuccessPanel}
          title={PANEL_TITLE}
        />
      </div>
      <H4>{BOLD_TITLE}</H4>

      <div style={{ wordWrap: "break-word" }}>
        <Paragraph>
          {HELP_TEXT}
        </Paragraph>
      </div>

      <Button
        className={classes.btnSubmit}
        onClick={backToOverview}
        data-automatedtestingid="orderform-finish-button"
      >
        {FINISH_BUTTON}
      </Button>
    </>
  );
};

CancellationReceived.propTypes = {
  classes: PropTypes.shape({
    btnSubmit: PropTypes.string,
    SuccessPanel: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.string,
      secondaryContactEmail: PropTypes.string,
      secondaryContactSource: PropTypes.string,
      oun: PropTypes.string,
    })
  }).isRequired,
};

export default withRouter(withStyles(styles)(CancellationReceived));
