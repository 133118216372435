const CancellationRequestLabels = {
  ERROR_MESSAGES: {
    DEFAULT: {
      SELECT_AN_OPTION: "Select an option",
      FAILURE_ERR_MSG: "An error occured.",
    },
  },
  INFO: {
    DEFAULT: {
      HEADING:
        "Requesting a cancellation",
      SUB_HEADING: "Are you sure you want to cancel the following order: ",
      INFO_TEXT_1:
        "Important: Your order may still be delivered if it has already reached our courier services. If you no longer require these test kits please refuse the delivery and they will be returned.",
      INFO_TEXT_2:
        "In the case you accept the ordered test kits please store them in line with manufacturer's guidelines in the boxes, in a dry environment and at an ambient temperature which is usually between 2-30 degrees centigrade (°C).",
      YES: "Yes",
      NO: "No",
    },
  },
};

export default CancellationRequestLabels;
