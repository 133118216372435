import React from "react";
import PropTypes from "prop-types";
import { InputField } from "govuk-react";
import { Field } from "react-final-form";
import withStyles from "react-jss";
import { inputWidthStyles } from "../../../data/jss/Input.component.jss";
import inputWidthTranslator from "../../../service/jss";
import { textValidator } from "./lib";

const styles = inputWidthStyles;

const TextInput = ({ label, name, hint, errMessage, inputWidth, classes }) => (
  <Field
    validate={textValidator(errMessage)}
    label={label}
    name={name}
    render={({ input, meta }) => (
      <InputField
        {...input}
        meta={meta}
        value={input.value}
        input={{ ...input, type: "text" }}
        name={name}
        hint={[hint]}
        className={inputWidthTranslator(inputWidth, classes)}
      >
        {label}
      </InputField>
    )}
  />
);

export default withStyles(styles)(TextInput);

TextInput.defaultProps = {
  name: "",
  hint: "",
  label: "",
  errMessage: "",
};

TextInput.propTypes = {
  name: PropTypes.string,
  errMessage: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
};
