import React from "react";
import {
  GridCol,
  GridRow,
  H1,
  H2,
  LeadParagraph,
  Link,
  Paragraph,
} from "govuk-react";

import withStyles from "react-jss";
import PropTypes from "prop-types";

const styles = {
  gridRow: {
    marginBottom: "30px",
  },
  fakeSize: {
    width: "auto",
  },
  "@media (min-width: 1020px)": {
    fakeSize: {
      width: "960px",
    },
  },
};

const UONHeading = ({
  header,
  subHeader,
  helpText,
  linkText,
  linkTitle,
  linkURL,
  info,
  classes,
  ariaLabel,
}) => {
  const ReactRouterLinkRenderer = ({ href, children }) => (
    href.match(/^\//)
      ? <Link aria-label={ariaLabel} to={href}>{children}</Link>
      : <a aria-label={ariaLabel} rel="noopener noreferrer" target="_blank" href={href}>{children}</a>
  );

  ReactRouterLinkRenderer.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  return (
    // We're faking the size of our grid, if there is no info to display.
    // This needs to be redone at some point.
    <GridRow
      className={`${classes.gridRow} ${
        !info
          ? classes.fakeSize
          : null
      }`}
    >
      <GridCol setWidth="two-thirds">
        <H1>{header}</H1>
        <H2>{subHeader}</H2>
        {info && <LeadParagraph>{info}</LeadParagraph>}
        <Paragraph>{helpText}</Paragraph>
        <Paragraph linkRenderer={ReactRouterLinkRenderer}>{`${linkText} [${linkTitle}](${linkURL}).`}</Paragraph>
      </GridCol>
    </GridRow>
  );
};

export default withStyles(styles)(UONHeading);

UONHeading.defaultProps = {
  ariaLabel: null,
  info: null,
  helpText: null,
  linkText: null,
  linkTitle: null,
  linkURL: null,
};

UONHeading.propTypes = {
  classes: PropTypes.shape({
    buttonRow: PropTypes.string,
    UrnSubmit: PropTypes.string,
    gridRow: PropTypes.string,
    fakeSize: PropTypes.string,
  }).isRequired,
  ariaLabel: PropTypes.string,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  info: PropTypes.string,
  helpText: PropTypes.string,
  linkText: PropTypes.string,
  linkTitle: PropTypes.string,
  linkURL: PropTypes.string,
};
