const CharRestrictionSets = {
  ContactForm: {
    contactFirstName: '[!@#$%^&*()?":{}|<>]',
    contactLastName: '[!@#$%^&*()?":{}|<>]',
    contactJobTitle: '[!@#$%^*"?{}|<>]',
    contactOrgName: '[@#$%^*"{}|<>]',
    contactEmail: '[!#$%^*"?{}|<>]',
    contactConfirmEmail: '[!#$%^*"?{}|<>]',
    contactDriver: "[#$%^*{}|<>]",
  },
  DeliveryInstructionsForm: {
    uvRestrictionInformation: '[@#$%^*"{}|<>]',
  },
};

export default CharRestrictionSets;
