/* eslint-disable no-unused-vars */
import { validateName } from "./validationHelpers.service";

const populationFormValidation = (
  values,
  requestLabels,
  allowZeroValFOrVP = true,
  isSymptomaticRegime
) => {
  const {
    ERROR_MESSAGES: {
      TOTAL_NON_STAFF_NOTELIGIBLE_COHORTPOOLING_CANT_EXCEED_NUMBEROF_NONSTAFF_ERR_MSG,
      TOTAL_STAFF_NOTELIGIBLE_COHORTPOOLING_CANT_EXCEED_NUMBEROF_STAFF_ERR_MSG,
      TOTAL_NUMBER_OF_RETESTKITS_ERROR,
      REFERRER_CONFIRMATION_EMAIL_ADDRESS,
      PRIMARY_CONFIRMATION_EMAIL_ADDRESS,
      PRIMARY_CONTACT_FIRST_NAME_ALPHA_ERROR,
      PRIMARY_CONTACT_LAST_NAME_ALPHA_ERROR,
      PRIMARY_CONTACT_JOB_ALPHA_ERROR,
      REFERRER_JOB_ALPHA_ERROR,
      REFERRER_CONTACT_FIRST_NAME_ALPHA_ERROR,
      REFERRER_CONTACT_LAST_NAME_ALPHA_ERROR,
      REFERRER_ORG_ERROR,
      TOTAL_STAFF_ZERO,
      TOTAL_NON_STAFF_ZERO,
      SYMPTOMATIC_STAFF_NON_STAFF_ZERO,
    },
  } = requestLabels;

  const errors = {};
  const allStaffFieldsDisplayed =
    values.nonStaffMultiplier && values.staffMultiplier;

  const nonStaffZeroErr = () => {
    return isSymptomaticRegime ? SYMPTOMATIC_STAFF_NON_STAFF_ZERO : TOTAL_NON_STAFF_ZERO;
  };

  const staffZeroErr = () => {
    return isSymptomaticRegime ? SYMPTOMATIC_STAFF_NON_STAFF_ZERO : TOTAL_STAFF_ZERO;
  };

	// #10069 changes to errors depend on Population Testing approach
  if (
    parseInt(values.totalNumberOfResidents, 10) < 1 &&
    parseInt(values.totalNumberOfStaff, 10) < 1
  ) {
    errors.totalNumberOfResidents = nonStaffZeroErr();
    errors.totalNumberOfStaff = staffZeroErr();
  }

  if (!allStaffFieldsDisplayed) {
    if (
      values.totalNumberOfResidents &&
      parseInt(values.totalNumberOfResidents, 10) < 1
    ) {
      errors.totalNumberOfResidents = nonStaffZeroErr();
    }

    if (
      values.totalNumberOfStaff &&
      parseInt(values.totalNumberOfStaff, 10) < 1
    ) {
      errors.totalNumberOfStaff = staffZeroErr();
    }
  }

  if (values.contactEmailConfirm !== values.contactEmail) {
    errors.contactEmailConfirm = PRIMARY_CONFIRMATION_EMAIL_ADDRESS;
  }
  if (
    values.secondaryContactSource === "yes" &&
    values.secondaryContactConfirmEmail !== values.secondaryContactEmail
  ) {
    errors.secondaryContactConfirmEmail = REFERRER_CONFIRMATION_EMAIL_ADDRESS;
  }

  // Cohort pooling validation rules
  if (
    parseInt(values.noOfNonStaffUnsuitableGroupTest, 10) >
    parseInt(values.totalNumberOfResidents, 10)
  ) {
    errors.noOfNonStaffUnsuitableGroupTest =
      TOTAL_NON_STAFF_NOTELIGIBLE_COHORTPOOLING_CANT_EXCEED_NUMBEROF_NONSTAFF_ERR_MSG;
  }

  if (
    parseInt(values.noOfStaffUnsuitableGroupTest, 10) >
    parseInt(values.totalNumberOfStaff, 10)
  ) {
    errors.noOfStaffUnsuitableGroupTest =
      TOTAL_STAFF_NOTELIGIBLE_COHORTPOOLING_CANT_EXCEED_NUMBEROF_STAFF_ERR_MSG;
  }

  if (
    parseInt(values.noOfRemainingIndvRetestKits, 10) >
    parseInt(values.totalNumberOfStaff, 10) +
      parseInt(values.totalNumberOfResidents, 10)
  ) {
    errors.noOfRemainingIndvRetestKits = TOTAL_NUMBER_OF_RETESTKITS_ERROR;
  }

  // END cohort pooling validation rules
  if (!validateName(values.contactFirstName)) {
    errors.contactFirstName = PRIMARY_CONTACT_FIRST_NAME_ALPHA_ERROR;
  }

  if (!validateName(values.contactLastName)) {
    errors.contactLastName = PRIMARY_CONTACT_LAST_NAME_ALPHA_ERROR;
  }

  if (!validateName(values.contactJobTitle)) {
    errors.contactJobTitle = PRIMARY_CONTACT_JOB_ALPHA_ERROR;
  }

  if (!validateName(values.secondaryContactFirstName)) {
    errors.secondaryContactFirstName = REFERRER_CONTACT_FIRST_NAME_ALPHA_ERROR;
  }

  if (!validateName(values.secondaryContactLastName)) {
    errors.secondaryContactLastName = REFERRER_CONTACT_LAST_NAME_ALPHA_ERROR;
  }

  if (!validateName(values.secondaryContactOrganisation)) {
    errors.secondaryContactOrganisation = REFERRER_ORG_ERROR;
  }

  if (!validateName(values.secondaryContactJobTitle)) {
    errors.secondaryContactJobTitle = REFERRER_JOB_ALPHA_ERROR;
  }

  return errors;
};

export default populationFormValidation;
