/* eslint-disable */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Caption, GridCol, GridRow, H1 } from "govuk-react";
import withStyles from "react-jss";
import { InputComponent } from "@cube/global-components";
import { FormContext } from "../../../data/contexts/contexts";

const styles = {
  gridMargin: {
    marginTop: "30px",
  },
  header: {
    marginBottom: "10px",
  },
};

const CommunityTestingFields = ({ classes }) => {
  const [formContext] = useContext(FormContext);
  const automationIdPrefix = "orderform-oppotestingfields";
  const inputWidth = "input-width-4";

  return (
    <>
      <GridRow>
        <GridCol>
          <header>
            <Caption>{formContext?.orderTitle}</Caption>
            <H1>Number of pallets needed</H1>
          </header>
        </GridCol>
      </GridRow>
      <GridRow className={classes.gridMargin}>
        <GridCol setWidth="two-thirds">
          <InputComponent
            initialValue={formContext?.totalNumberOfResidents}
            type="number"
            label="Number of pallets needed"
            name="totalNumberOfResidents"
            errMessage="Enter number of pallets needed"
            width={inputWidth}
            automatedtestingId={`${automationIdPrefix}-lft-inputcomponent-totalNumberOfResidents`}
          />
        </GridCol>
      </GridRow>
    </>
  );
};

export default withStyles(styles)(CommunityTestingFields);

CommunityTestingFields.propTypes = {
  classes: PropTypes.shape({
    gridMargin: PropTypes.string,
  }).isRequired,
  orgType: PropTypes.string.isRequired,
  testingType: PropTypes.string.isRequired,
};
