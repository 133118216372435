import { RequestLabels } from "../../data/constants";

const ContactFormTranslation = () => {
  const labels = RequestLabels;

  labels.ERROR_MESSAGES = {
    ...labels.ERROR_MESSAGES,
    ...labels.ERROR_MESSAGES.DEFAULT,
  };

  return {
    ...labels,
  };
};

export default ContactFormTranslation;
