/* eslint-disable prefer-template */
/* eslint-disable array-callback-return */
/* eslint-disable func-names */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
const HelperService = {
  trimString(value) {
    return value?.replace(/\s+/g, "");
  },

  checkRestricted(src, restricted) {
    return !!src?.split("").some((ch) => restricted.indexOf(ch) !== -1);
  },

  getDaysBewtweenDates(startDate, endDate) {
    let a = [];
    for (
      let d = new Date(startDate);
      d <= endDate;
      d.setDate(d.getDate() + 1)
    ) {
      a.push(new Date(d));
    }
    return a;
  },

  onHandleErrorClick(targetName) {
    document.getElementsByName(targetName)[0].scrollIntoView();
  },
  onHandleErrorClickAlternative(targetName) {
    document.querySelector(`[data-errorscrolltarget='${targetName}']`).scrollIntoView();
  },
};

export default HelperService;
