/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from "prop-types";
import { UONTranslation } from "../../service/translation";
import {
  InputComponent,
} from "@cube/global-components";

const OrganisationLabels = UONTranslation();
const {
  INFO: {
    UNIQUE_ID_LABEL,
    UNIQUE_ID_NAME,
  },
} = OrganisationLabels;


const UONField = ({
  errMsgBackendPresent
}) => {
  return (
    <InputComponent
      automatedtestingId="orderform-uonform-inputcomponent-number"
      errMessage="Enter a UON number"
      errMsgBackend="Enter a correct 8-digit unique organisation number"
      errMsgBackendPresent={
        errMsgBackendPresent.length > 0 && errMsgBackendPresent[0]
      }
      label={UNIQUE_ID_LABEL}
      name={UNIQUE_ID_NAME}
      type="number"
      width="input-width-20"
    />
  );
};

UONField.propTypes = {
  errMsgBackendPresent: PropTypes.any
};

export default UONField;