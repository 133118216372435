/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Caption, GridCol, GridRow, H1, Paragraph } from "govuk-react";
import withStyles from "react-jss";
import { InputComponent } from "@cube/global-components";
import { RequestTranslation } from "../../../service/translation";
import { FormContext } from "../../../data/contexts/contexts";

const styles = {
  gridMargin: {
    marginTop: "30px",
  },
};

const PopulationTestingFields = ({ classes, orgType, testingType, errMsgBackendPresent, isSymptomaticRegime, customErrorMessages }) => {
  const [formContext] = useContext(FormContext);

  const automationIdPrefix = "orderform-poptestingfields";
  const inputWidth = "input-width-4";
  const RequestLabels = RequestTranslation(orgType);
  const {
    REQUEST_INPUTS: {
      FORM_TESTING_HEADING,
      FORM_TESTING_SYMPTOMATIC_HEADING,
      FORM_OPPORTUNISTIC_TESTING_SUB_HEADING,
      FORM_INDIVIDUAL_TESTING_SUB_HEADING,
      FORM_GROUP_TESTING_SUB_HEADING,
      FORM_TESTING: {
        TESTING_TOTAL_NON_STAFF_PT,
        TESTING_TOTAL_STAFF_PT,
        TESTING_SYMPTOMATIC_NON_STAFF_PT,
        TESTING_SYMPTOMATIC_STAFF_PT,
        TESTING_TOTAL_NON_STAFF_NOTELIGIBLE_COHORTPOOLING,
        TESTING_TOTAL_NUMBER_OF_KITS_REMAINING,
        TESTING_TOTAL_STAFF_NOTELIGIBLE_COHORTPOOLING,
        TESTING_TOTAL_STAFF_HINT_PT,
        TESTING_TOTAL_NON_STAFF_HINT_PT,
        TESTING_SYMPTOMATIC_STAFF_HINT_PT,
        TESTING_SYMPTOMATIC_NON_STAFF_HINT_PT,
      },
    },
  } = RequestLabels;

  const nonStaffLabel = () => {
    return isSymptomaticRegime ? TESTING_SYMPTOMATIC_NON_STAFF_PT : TESTING_TOTAL_NON_STAFF_PT;
  };

  const staffLabel = () => {
    return isSymptomaticRegime ? TESTING_SYMPTOMATIC_STAFF_PT : TESTING_TOTAL_STAFF_PT;
  };

  const staffHintText = () => {
    return isSymptomaticRegime ? TESTING_SYMPTOMATIC_STAFF_HINT_PT : TESTING_TOTAL_STAFF_HINT_PT;
  };

  const nonStaffHintText = () => {
    return isSymptomaticRegime ? TESTING_SYMPTOMATIC_NON_STAFF_HINT_PT : TESTING_TOTAL_NON_STAFF_HINT_PT;
  };

  const mainHeading = () => {
    return isSymptomaticRegime ? FORM_TESTING_SYMPTOMATIC_HEADING : FORM_TESTING_HEADING;
  };

  return (
    <>
      {formContext?.showOrderFormForTestingType === "Lateral Flow Testing" ? (
        <>
          <GridRow className={classes.gridMargin}>
            <GridCol>
              <header>
                <Caption>{formContext?.orderTitle}</Caption>
                <H1>{mainHeading()}</H1>
              </header>
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol setWidth="two-thirds">
              <Paragraph>{FORM_OPPORTUNISTIC_TESTING_SUB_HEADING}</Paragraph>
            </GridCol>
          </GridRow>
          {formContext?.staffMultiplier === true && (
            <GridRow className={classes.gridMargin}>
              <GridCol setWidth="two-thirds">
                <InputComponent
                  aria-hidden={formContext?.staffMultiplier === true ? false : true}
                  initialValue={formContext?.totalNumberOfStaff}
                  type="number"
                  label={staffLabel()}
                  name="totalNumberOfStaff"
                  errMessage={customErrorMessages?.staffEmptyErrorMessage}
                  errMessageNumber={customErrorMessages?.staffZeroErrorMessage}
                  errMsgBackend={customErrorMessages?.staffLowerNumberErrorMessage}
                  errMsgBackendPresent={(errMsgBackendPresent?.length > 0 && errMsgBackendPresent[0] === "totalNumberOfStaff") || (errMsgBackendPresent?.length > 1 && errMsgBackendPresent[1] === "totalNumberOfStaff") ? ("totalNumberOfStaff"):("")}
                  hint={staffHintText()}
                  width={inputWidth}
                  automatedtestingId={`${automationIdPrefix}-lft-inputcomponent-totalNumberOfStaff`}
                />
              </GridCol>
            </GridRow>
          )}

          {formContext?.nonStaffMultiplier === true && (
            <GridRow className={classes.gridMargin}>
              <GridCol setWidth="two-thirds">
                <InputComponent
                  initialValue={formContext?.totalNumberOfResidents}
                  type="number"
                  label={nonStaffLabel()}
                  name="totalNumberOfResidents"
                  errMessage={customErrorMessages?.nonStaffEmptyErrorMessage}
                  errMessageNumber={customErrorMessages?.nonStaffZeroErrorMessage}
                  errMsgBackend={customErrorMessages?.nonStaffLowerNumberErrorMessage}
                  errMsgBackendPresent={(errMsgBackendPresent?.length > 0 && errMsgBackendPresent[0] === "totalNumberOfResidents") || (errMsgBackendPresent?.length > 1 && errMsgBackendPresent[1] === "totalNumberOfResidents") ? ("totalNumberOfResidents"):("")}
                  width={inputWidth}
                  hint={nonStaffHintText()}
                  automatedtestingId={`${automationIdPrefix}-lft-inputcomponent-totalNumberOfResidents`}
                />
              </GridCol>
            </GridRow>
          )}
        </>
      ) : (
        <>
          <GridRow className={classes.gridMargin}>
            <GridCol>
              {formContext?.showOrderFormForTestingType === "Cohort Pooling" ? (
                <header>
                  <Caption>{formContext?.orderTitle}</Caption>
                  <H1>Total number of people to be tested</H1>
                </header>
              ) : (
                <header>
                  <Caption>{formContext?.orderTitle}</Caption>
                  <H1>{mainHeading()}</H1>
                </header>
              )}
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol setWidth="two-thirds">
              {formContext?.showOrderFormForTestingType === "Cohort Pooling" ? (
                <Paragraph>{FORM_GROUP_TESTING_SUB_HEADING}</Paragraph>
              ) : (
                <Paragraph>{FORM_INDIVIDUAL_TESTING_SUB_HEADING}</Paragraph>
              )}
            </GridCol>
          </GridRow>
          {formContext?.staffMultiplier === true && (
            <GridRow className={classes.gridMargin}>
              <GridCol setWidth="two-thirds">
                <InputComponent
                  aria-hidden={formContext?.staffMultiplier === true ? false : true}
                  initialValue={formContext?.totalNumberOfStaff}
                  type="number"
                  label={staffLabel()}
                  name="totalNumberOfStaff"
                  errMessage={customErrorMessages?.staffEmptyErrorMessage}
                  errMessageNumber={customErrorMessages?.staffZeroErrorMessage}
                  errMsgBackend={customErrorMessages?.staffLowerNumberErrorMessage}
                  errMsgBackendPresent={(errMsgBackendPresent?.length > 0 && errMsgBackendPresent[0] === "totalNumberOfStaff") || (errMsgBackendPresent?.length > 1 && errMsgBackendPresent[1] === "totalNumberOfStaff") ? ("totalNumberOfStaff"):("")}
                  hint={staffHintText()}
                  width={inputWidth}
                  automatedtestingId={`${automationIdPrefix}-inputcomponent-totalNumberOfStaff`}
                />
              </GridCol>
            </GridRow>
          )}
          {formContext?.nonStaffMultiplier === true && (
            <GridRow className={classes.gridMargin}>
              <GridCol setWidth="two-thirds">
                <InputComponent
                  initialValue={formContext?.totalNumberOfResidents}
                  type="number"
                  label={nonStaffLabel()}
                  name="totalNumberOfResidents"
                  errMessage={customErrorMessages?.nonStaffEmptyErrorMessage}
                  errMessageNumber={customErrorMessages?.nonStaffZeroErrorMessage}
                  errMsgBackend={customErrorMessages?.nonStaffLowerNumberErrorMessage}
                  errMsgBackendPresent={(errMsgBackendPresent?.length > 0 && errMsgBackendPresent[0] === "totalNumberOfResidents") || (errMsgBackendPresent?.length > 1 && errMsgBackendPresent[1] === "totalNumberOfResidents") ? ("totalNumberOfResidents"):("")}
                  width={inputWidth}
                  hint={nonStaffHintText()}
                  automatedtestingId={`${automationIdPrefix}-inputcomponent-totalNumberOfResidents`}
                />
              </GridCol>
            </GridRow>
          )}
          {testingType === "Cohort Pooling" && (
            <>
              <GridRow className={classes.gridMargin}>
                <GridCol setWidth="two-thirds">
                  <InputComponent
                    aria-hidden="true"
                    initialValue={formContext?.noOfNonStaffUnsuitableGroupTest}
                    type="number"
                    name="noOfNonStaffUnsuitableGroupTest"
                    label={TESTING_TOTAL_NON_STAFF_NOTELIGIBLE_COHORTPOOLING}
                    errMessage="Enter a number"
                    errMessageNumber="Enter a number"
                    width={inputWidth}
                    hint="Pooled testing tests multiple people together. If anyone has coronavirus symptoms, has tested positive in the past or may not be able to isolate due to their health, they should not take part in pooled testing."
                    automatedtestingId={`${automationIdPrefix}-inputcomponent-cohort-noOfNonStaffUnsuitableGroupTest`}
                  />
                </GridCol>
              </GridRow>

              <GridRow className={classes.gridMargin}>
                <GridCol setWidth="two-thirds">
                  <InputComponent
                    aria-hidden="true"
                    initialValue={formContext?.noOfStaffUnsuitableGroupTest}
                    type="number"
                    name="noOfStaffUnsuitableGroupTest"
                    label={TESTING_TOTAL_STAFF_NOTELIGIBLE_COHORTPOOLING}
                    errMessage="Enter a number"
                    errMessageNumber="Enter a number"
                    width={inputWidth}
                    automatedtestingId={`${automationIdPrefix}-inputcomponent-cohort-noOfStaffUnsuitableGroupTest`}
                  />
                </GridCol>
              </GridRow>
              <GridRow className={classes.gridMargin}>
                <GridCol setWidth="two-thirds">
                  <InputComponent
                    aria-hidden="true"
                    initialValue={formContext?.noOfRemainingIndvRetestKits}
                    type="number"
                    name="noOfRemainingIndvRetestKits"
                    label={TESTING_TOTAL_NUMBER_OF_KITS_REMAINING}
                    errMessage="Enter a number"
                    errMessageNumber="Enter a number"
                    width="input-width-4"
                    hint="Use these before ordering more."
                    automatedtestingId={`${automationIdPrefix}-inputcomponent-cohort-noOfRemainingIndvRetestKits`}
                  />
                </GridCol>
              </GridRow>
            </>
          )}
        </>
      )}
    </>
  );
};

export default withStyles(styles)(PopulationTestingFields);

PopulationTestingFields.propTypes = {
  classes: PropTypes.shape({
    gridMargin: PropTypes.string,
  }).isRequired,
  errMsgBackendPresent: PropTypes.any,
  orgType: PropTypes.string.isRequired,
  testingType: PropTypes.string.isRequired,
};
