import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "govuk-react";
import { Field } from "react-final-form";

const CheckboxComponent = ({
  componentChecked,
  label,
  name,
  text,
  className,
  onChange,
  automatedtestingId,
}) => (
  <Field
    label={label}
    name={name}
    type="checkbox"
    className={className}
    render={({ input, meta }) =>
      componentChecked ? (
        <Checkbox
          {...input}
          checked
          value={input.value}
          meta={meta}
          name={name}
          className={className}
          onChange={onChange}
          data-automatedtestingid={automatedtestingId || ""}
        >
          {text}
        </Checkbox>
      ) : (
        <Checkbox
          {...input}
          value={input.value}
          meta={meta}
          name={name}
          className={className}
          onChange={onChange}
          data-automatedtestingid={automatedtestingId || ""}
        >
          {text}
        </Checkbox>
      )
    }
  />
);

export default CheckboxComponent;

CheckboxComponent.defaultProps = {
  componentChecked: false,
};

CheckboxComponent.propTypes = {
  componentChecked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};
