﻿import React from "react";
import PropTypes from "prop-types";
import {Field} from "react-final-form";
import {ErrorText, HintText, Label, LabelText} from "govuk-react";
import Autocomplete from "accessible-autocomplete/dist/lib/accessible-autocomplete.react.min";

const AutoSuggestComponent = ({label, hint, name, initialValue, className, source, onConfirm, showAllValues, autoSelect, fallbackErrorMsg}, children) => (
  <Field
    label={label}
    name={name}
    className={className}
    render={({ input, meta }) => {
      return (
        <>
            <Label name={name}>
                {label && <LabelText style={{marginBottom: '15px'}}>{label}</LabelText>}
                {hint && <HintText >{hint}</HintText>}
                {fallbackErrorMsg && <ErrorText>{fallbackErrorMsg}</ErrorText>}
              <div style={{width: '75%'}}> {/* Wdith needs to be handled genericly with a oneOf prop. */}
                  <Autocomplete defaultValue={initialValue} source={source} onConfirm={onConfirm} {...input} autoselect={autoSelect} showAllValues={showAllValues} />
              </div>
            </Label>
        </>
      )
    }}
  />
);

export default AutoSuggestComponent;

AutoSuggestComponent.defaultProps = {
   showAllValues: true,
   autoSelect: true,
   initialValue: '',
}

AutoSuggestComponent.propTypes = {
   label: PropTypes.string.isRequired,
   initialValue: PropTypes.string,
   hint: PropTypes.string,
   name: PropTypes.string.isRequired,
   className: PropTypes.string.isRequired,
   source: PropTypes.func.isRequired,
   onConfirm: PropTypes.func.isRequired,
   showAllValues: PropTypes.bool,
   autoSelect: PropTypes.bool,
   fallbackErrorMsg: PropTypes.string,
};
