import React from "react";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { GridCol, GridRow } from "govuk-react";
import { ErrorSummary } from "@cube/global-components";
import { ErrorLabels } from "../../data/constants/index";

const styles = {};

const { STANDARD_ERR_HEADER } = ErrorLabels;

const ErrorComponent = ({ message }) => {
  return (
    <div data-automatedtestingid="orderform-error-errorcomponent">
      <GridRow>
        <GridCol setWidth="two-thirds">
          <ErrorSummary heading={STANDARD_ERR_HEADER} description={message} errors={[]} />
        </GridCol>
      </GridRow>
    </div>
  );
};

export default withStyles(styles)(ErrorComponent);

ErrorComponent.defaultProps = {
  message: "",
};
ErrorComponent.propTypes = {
  message: PropTypes.string,
};
