import React from "react";
import PropTypes from "prop-types";
import OpportunisticTestingFields from "./OpportunisticTestingFields.component";
import PopulationTestingFields from "./PopulationTestingFields.components";
import ReturnBoxesTestingFields from "./ReturnBoxesTestingFields.components";
import BulkTestingFields from "./BulkTestingFields.component";
import CommunityTestingFields from "./CommunityTestingFields.component";

const TestingFields = ({ orgType, testingApproach, testingType, errMsgBackend, errMsgBackendPresent, isSymptomaticRegime, customErrorMessages}) => {
  if (testingType === "Return Boxes") {
    return (
      <ReturnBoxesTestingFields errMsgBackend={errMsgBackend} errMsgBackendPresent={errMsgBackendPresent} orgType={orgType} testingType={testingType} />
    );
  }

  if (testingApproach === "Opportunistic Testing") {
    return (
      <OpportunisticTestingFields orgType={orgType} testingType={testingType} errMsgBackendPresent={errMsgBackendPresent} />
    );
  }

  if (testingApproach === "Bulk Testing") {
    return (
      <BulkTestingFields orgType={orgType} testingType={testingType} />
    );
  }

  if (testingApproach === "Community Testing") {
    return (
      <CommunityTestingFields orgType={orgType} testingType={testingType} />
    );
  }

  return (
    <PopulationTestingFields orgType={orgType} testingType={testingType} errMsgBackendPresent={errMsgBackendPresent} isSymptomaticRegime={isSymptomaticRegime} customErrorMessages={customErrorMessages} />
  );
};

export default TestingFields;

TestingFields.defaultProps = {
  errMsgBackend: null,
  errMsgBackendPresent: null
};

TestingFields.propTypes = {
  errMsgBackend: PropTypes.string,
  errMsgBackendPresent: PropTypes.string,
  orgType: PropTypes.string.isRequired,
  testingApproach: PropTypes.string.isRequired,
  testingType: PropTypes.string.isRequired,
};
