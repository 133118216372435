import React from "react";
import PropTypes from "prop-types";
import { InputField } from "govuk-react";
import { Field } from "react-final-form";
import withStyles from "react-jss";
import { inputWidthStyles } from "../../../../data/jss/Input.component.jss";
import inputWidthTranslator from "../../../../service/jss";

const styles = inputWidthStyles;

const checkRestricted = (src, restricted) => {
  return !!src?.split("").some((ch) => restricted.indexOf(ch) !== -1);
};

const TextInput = ({
  initialValue,
  label,
  name,
  id,
  hint,
  errMessage,
  width,
  classes,
  notrequired,
  automatedtestingId,
  restrictedCharacters,
  disallowNumbers,
  errMsgBackend,
  errMsgBackendPresent
}) => {
  const textValidator = (errMessage, errMsgBackend, errMsgBackendPresent) => (value) => {
    if (errMsgBackendPresent === "externalReference"){
      return errMsgBackend;
    }
    if (checkRestricted(value, restrictedCharacters)) {
      return `${label} must not include ${restrictedCharacters}`;
    } else if (!value) {
      return errMessage ? errMessage : "Required";
    } else if (!value || value.trim().length == 0) {
      return errMessage ? errMessage : "Required";
    } 
    else {
      return undefined;
    }
  };

  const textValidatorNotRequired = (errMessage) => (value) => {
    if (checkRestricted(value, restrictedCharacters)) {
      return `${label} must not include ${restrictedCharacters}`;
    }
  };

  const preventNumberInput = (e) => {
    if (disallowNumbers && !(e.which > 31 && (e.which < 48 || e.which > 57))) {
      e.preventDefault();
    }
  };

  return (
    <Field
      validate={notrequired === true ? textValidatorNotRequired(errMessage) : textValidator(errMessage, errMsgBackend, errMsgBackendPresent)}
      label={label}
      name={name}
      initialValue={initialValue}
      render={({ input, meta }) => (
        <InputField
          {...input}
          meta={meta}
          value={input.value}
          input={{
            ...input,
            type: "text",
            "data-automatedtestingid": automatedtestingId,
          }}
          id={id}
          name={name}
          hint={[hint]}
          className={inputWidthTranslator(width, classes)}
          onKeyPress={(e) => preventNumberInput(e)}
        >
          {label}
        </InputField>
      )}
    />
  );
};

export default withStyles(styles)(TextInput);

TextInput.defaultProps = {
  id: "",
  name: "",
  hint: "",
  label: "",
  width: "full-width",
  errMessage: "",
  errMsgBackend: "",
  errMsgBackendPresent: "",
  initialValue: "",
  restrictedCharacters: "",
};

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  errMessage: PropTypes.string,
  errMsgBackend: PropTypes.string,
  errMsgBackendPresent: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.string,
  notrequired: PropTypes.bool,
  initialValue: PropTypes.string,
  restrictedCharacters: PropTypes.string,
};
