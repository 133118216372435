﻿// eslint-disable-next-line consistent-return
const inputWidthTranslator = (inputWidth, classes) => {

  const widthOptions = [
    'input-width-20',
    'input-width-10',
    'input-width-5',
    'input-width-4',
    'input-width-3',
    'input-width-2',
    'width-full',
    'three-quarters',
    'two-thirds',
    'one-half',
    'one-third',
    'one-quarter'
  ];

  if(widthOptions.includes(inputWidth)){
    return classes[inputWidth];
  }
};

export default inputWidthTranslator;