﻿import React, { useState } from "react";
import PropTypes from "prop-types";
import {TextArea, HintText} from "govuk-react";
import {Field} from "react-final-form";
import withStyles from "react-jss";

const checkRestricted = (src, restricted) => {
  return !!src?.split("").some((ch) => restricted.indexOf(ch) !== -1);
};
const styles = {
	formError: {
		paddingLeft: "15px",
		borderLeft: "4px solid #d4351c",
	},
	noMargin: {
		marginBottom: 0,
	},
};

const TextAreaComponent = ({errorMsgAlternative, label, name, hint, className, restrictedCharacters, maxLength, showCharactersText, isRequired, errMessage, automatedtestingId, classes}) => {

	const [charactersLeft, setCharactersLeft] = useState(maxLength);

	const textValidator = (value) => {
		if(checkRestricted(value, restrictedCharacters)){
			return `${errorMsgAlternative !== undefined ? errorMsgAlternative : label} ${restrictedCharacters}`;
		}
		if (!value && isRequired) {
			return errMessage ? errMessage : "Required";
		}
	};

	const onTextareaValueChange = (e) => {
		if (maxLength) {
			e.target.value = e.target.value.substr(0, maxLength)
		}
		updateNumberOfCharactersValue(e);
	};

	const updateNumberOfCharactersValue = (e) => {
		const numberToDisplay = maxLength - e.target.value.length;
		if (numberToDisplay >= 0 && showCharactersText) {
			setCharactersLeft(numberToDisplay);
		}
	};

	return (<Field
		validate={textValidator}
		label={label}
		name={name}
		type="textarea"
		className={className}
		render={({ input, meta }) => (
			<>
				<TextArea
					{...input}
					meta={meta}
					name={name}
					className={className}
					hint={hint}
					input={{
						...input,
						"data-testid": `${name}-textarea`,
						onChange: onTextareaValueChange,
					}}
					value={input.value}
					data-automatedtestingid={automatedtestingId || ""}
				>
					{label}
				</TextArea>
				{showCharactersText && <HintText className={meta.touched && meta.error ? `${classes.formError} ${classes.noMargin}` : `${classes.noMargin}`}>You have {charactersLeft} characters remaining.</HintText>}
			</>
		)}
	/>);
}

export default withStyles(styles)(TextAreaComponent);

TextAreaComponent.defaultProps = {
  errorMsgAlternative: undefined,
};

TextAreaComponent.propTypes = {
  classes: PropTypes.shape({
	formError: PropTypes.string,
	noMargin: PropTypes.string,
  }).isRequired,
  errorMsgAlternative: PropTypes.string,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  }),
};
