import React, { useContext, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import {
  Button,
  GridCol,
  GridRow,
  H1,
  ListItem,
  LoadingBox,
  Paragraph,
  UnorderedList,
} from "govuk-react";
import { useAnalytics } from "@cube/global-components";
import { RequestTranslation } from "../../service/translation";
import { FormContext } from "../../data/contexts/contexts";
import { Form } from "react-final-form";

const styles = {
  gridRowPositionRel: {
    position: "relative",
    paddingBottom: "10px",
  },
  detailsListItemBorderBottom: {
    position: "absolute",
    width: "calc(64% - 20px)",
    display: "block",
    borderBottom: "2px solid #e3e3e3",
    bottom: "17px",
    left: "15px",
  },
  gridMargin: {
    marginTop: "10px",
    "& div": {
      marginBottom: null,
    },
  },
  buttonMargin: {
    marginTop: "15px",
  },
};

const OrgDetails = ({ classes }) => {
  const { triggerEvent } = useAnalytics();

  const [formContext, setFormContext] = useContext(FormContext);

  const [loader, setLoader] = useState(false);
  const crqData = formContext?.crqData || null;
  const orgType = formContext?.orgType || null;
  const RequestLabels = RequestTranslation(orgType, crqData.TestingApproach);
  const {
    ORG_DETAILS: {
      ORG_HEADING,
      ORG_SUB_HEADING,
      ORG_SUB_HEADING_FIRST,
      ORG_NAME,
      ORG_STREET,
      ORG_CITY,
      ORG_POSTCODE,
      ORG_HELP,
      ORG_HELP_COMMUNITY,
      ORG_HELP_COMMUNITY_PART_2,
      ORG_HELP_COMMUNITY_PART_3,
    },
  } = RequestLabels;
  const history = useHistory();
  const currentPath = window.location.pathname;
  const windowAllowed = localStorage.getItem("answer");
  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: "nhs:test:organisation-order:details",
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "details",
        },
      },
    };
    triggerEvent(analyticsData);
  } else {
    window.digitalData = null;
  }

  const emailLinkRender = ({ href, children }) => <a href={href}>{children}</a>;
  const getContactInfotext = () => {
    if (formContext?.testingApproach === "Community Testing") {
      return (
        <div>
          <Paragraph linkRenderer={emailLinkRender}>
            {ORG_HELP_COMMUNITY}
          </Paragraph>
          <Paragraph>{ORG_HELP_COMMUNITY_PART_2}</Paragraph>
          <Paragraph>{ORG_HELP_COMMUNITY_PART_3}</Paragraph>
        </div>
      );
    }
    return <Paragraph>{ORG_HELP}</Paragraph>;
  };

  const onSubmit = (event) => {
    setLoader(true);

    setFormContext({
      ...formContext,
      orderAddressId: null,
      pageLocation: "/test-numbers",
      prevLocation: currentPath,
    });
    sessionStorage.setItem("postCodeDisable", false);
    history.push({ pathname: "test-numbers" });
    setLoader(false);
  };

  return (
    <>
      <LoadingBox
        loading={loader}
        backgroundColor="#fff"
        timeIn={100}
        timeOut={100}
        backgroundColorOpacity={0.85}
        spinnerColor="#000"
      >
        <GridRow>
          <GridCol setWidth="two-thirds">
            <header><H1>{ORG_HEADING}</H1></header>
          </GridCol>
        </GridRow>
        {ORG_SUB_HEADING && (
          <GridRow>
            <GridCol setWidth="two-thirds">
              <Paragraph>{ORG_SUB_HEADING}</Paragraph>
            </GridCol>
          </GridRow>
        )}
        <GridRow>
          <GridCol setWidth="two-thirds">
            <Paragraph>{ORG_SUB_HEADING_FIRST}</Paragraph>
          </GridCol>
        </GridRow>

        <div className={classes.gridMargin}></div>

        <div data-automatedtestingid="orderform-orgdetails-orgname">
          <GridRow className={`${classes.gridRowPositionRel}`}>
            <GridCol setWidth="one-third">
              <Paragraph>{`**${ORG_NAME}**`}</Paragraph>
            </GridCol>
            <GridCol setWidth="one-third">
              <Paragraph>{crqData.OrganisationName}</Paragraph>
            </GridCol>
            <span className={classes.detailsListItemBorderBottom}></span>
          </GridRow>
        </div>

        {crqData.Street && (
          <div data-automatedtestingid="orderform-orgdetails-orgstreet">
            <GridRow className={classes.gridRowPositionRel}>
              <GridCol setWidth="one-third">
                <Paragraph>{`**${ORG_STREET}**`}</Paragraph>
              </GridCol>
              <GridCol setWidth="two-third">
                <Paragraph>{crqData.Street}</Paragraph>
              </GridCol>
              <span className={classes.detailsListItemBorderBottom}></span>
            </GridRow>
          </div>
        )}
        <div data-automatedtestingid="orderform-orgdetails-orgcity">
          <GridRow className={classes.gridRowPositionRel}>
            <GridCol setWidth="one-third">
              <Paragraph>{`**${ORG_CITY}**`}</Paragraph>
            </GridCol>
            <GridCol setWidth="two-third">
              <Paragraph>{crqData.City}</Paragraph>
            </GridCol>
            <span className={classes.detailsListItemBorderBottom}></span>
          </GridRow>
        </div>

        <div data-automatedtestingid="orderform-orgdetails-orgpostcode">
          <GridRow className={classes.gridRowPositionRel}>
            <GridCol setWidth="one-third">
              <Paragraph>{`**${ORG_POSTCODE}**`}</Paragraph>
            </GridCol>
            <GridCol setWidth="two-thirds">
              <Paragraph>{crqData.Postcode}</Paragraph>
            </GridCol>
            <span className={classes.detailsListItemBorderBottom}></span>
          </GridRow>
        </div>

        <div className={classes.gridMargin}></div>

        <GridRow>
          <GridCol setWidth="two-thirds">{getContactInfotext()}</GridCol>
        </GridRow>
        <Form
          onSubmit={onSubmit}
          render={() => {
            return (
              <form>
                <Button
                  onClick={onSubmit}
                  className={classes.buttonMargin}
                  data-automatedtestingid="orderform-continue-button"
                >
                  Continue
                </Button>
              </form>
            );
          }}
        />
      </LoadingBox>
    </>
  );
};
export default withRouter(withStyles(styles)(OrgDetails));

OrgDetails.defaultProps = {
  classes: {
    buttonMargin: "",
    detailsListItemBorderBottom: "",
    gridMargin: "",
    gridRowPositionRel: "",
  }
};

OrgDetails.propTypes = {
  classes: PropTypes.shape({
    buttonMargin: PropTypes.string,
    detailsListItemBorderBottom: PropTypes.string,
    gridMargin: PropTypes.string,
    gridRowPositionRel: PropTypes.string,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      crqData: PropTypes.shape({
        City: PropTypes.string,
        Postcode: PropTypes.string,
        Street: PropTypes.string,
        TestingApproach: PropTypes.string,
        cqcRegistrationID: PropTypes.string,
      }).isRequired,
      orgType: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
