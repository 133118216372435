import React from "react";
import PropTypes from "prop-types";
import {Radio} from "govuk-react";
import {Field} from "react-final-form";

const RadioComponent = ({value, label, name, displayInline, isChecked}) => (

    
  <Field
    value={value}
    type="radio"
    name={name}
    render={({input}) => (
      
      <Radio name={name} {...input} inline = {displayInline} checked = {isChecked}>
        {label}
      </Radio>        
        
    )}
  />
  
  
  
);

export default RadioComponent;

RadioComponent.defaultValues = {
    displayInline: false
}

RadioComponent.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  displayInline: PropTypes.bool,
  isChecked: PropTypes.bool
};
