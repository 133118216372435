import React from "react";
import PropTypes from "prop-types";
import { InputField, Select } from "govuk-react";
import { Field } from "react-final-form";
import { inputWidthStyles } from "../../../data/jss/Input.component.jss";
import inputWidthTranslator from "../../../service/jss";
import withStyles from "react-jss";

const styles = {
  ...inputWidthStyles,
  selectField: {
    '& select': {
      background: '#fff',
    }
  },
  selectFieldWidth: {
    '& select': {
      width: '130px !important',
    }
  }
};

const SelectComponent = ({
  name,
  defaultValue,
  initialValue,
  label,
  hint,
  onChange,
  options,
  customWidth,
  width,
  classes,
  automatedtestingId,
  value,
}) => {
  return (
    <Field
      value={value || defaultValue}
      initialValue={initialValue || ""}
      name={name}
      render={({ input }) => (
        <Select
          {...input}
          input={{ ...input, "data-automatedtestingid": automatedtestingId, "data-testid": `${name}-select` }}
          name={name}
          hint={[hint]}
          label={label}
          onChange={onChange}
          className={`${inputWidthTranslator(width, classes)} ${classes.selectField} ${customWidth ? classes.selectFieldWidth : ''}`}>
          {options.map((option) => {
            return (
              value === option.value ? (
                <option key={option.value} value={option.value} selected>
                  {option.label}
                </option>
              ) : (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              )
            )
          })}
        </Select>
      )}
    />
  )
};

export default withStyles(styles)(SelectComponent);

SelectComponent.defaultProps = {
  hint: "",
  label: "",
  defaultValue: "",
  value: "",
  width: "width-full",
  onChange: () => onChange(input.value === "" || input.value === "no"),
};

SelectComponent.propTypes = {
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  value: PropTypes.string,
  width: PropTypes.oneOf([
    'input-width-20',
    'input-width-10',
    'input-width-5',
    'input-width-4',
    'input-width-3',
    'input-width-2',
    'width-full',
    'three-quarters',
    'two-thirds',
    'one-half',
    'one-third',
    'one-quarter',
  ]
  ),
};
