import React from "react";
import PropTypes from "prop-types";
import {InputField} from "govuk-react";
import {Field} from "react-final-form";
import {inputWidthStyles} from "../../../data/jss/Input.component.jss";
import inputWidthTranslator from "../../../service/jss";
import withStyles from "react-jss";

const styles = inputWidthStyles;


const emailValidator = (errMessage) => (value) => {
  const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{0,})$/i;
  return regex.test(value) ? undefined : errMessage;
};

const EmailInput = ({label, name, hint, errMessage, inputWidth, classes }) => (

  <Field
    validate={emailValidator(errMessage)}
    label={label}
    name={name}
    render={({input, meta}) => (
      <InputField
        {...input}
        meta={meta}
        value={input.value}
        input={{...input, type: "email"}}
        name={name}
        hint={[hint]}
        className={inputWidthTranslator(inputWidth, classes)}
      >
        {label}
      </InputField>
    )}
  />
);

export default withStyles(styles)(EmailInput);

EmailInput.defaultProps = {
  name: "",
  hint: "",
  label: "",
  errMessage: "",
};

EmailInput.propTypes = {
  name: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  errMessage: PropTypes.string
};
