/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useState, useEffect } from "react";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import {
  GridCol,
  GridRow,
  H1,
  Paragraph,
  UnorderedList,
  ListItem,
} from "govuk-react";
import { FormContext } from "../../../data/contexts/contexts";
import { CheckboxComponent } from "../../Inputs";

const styles = {
  checkBoxAlign: {
    "& span": {
      padding: "0 0.5rem",
      marginTop: "-0.2rem",
    },
  },
  title: {
    marginBottom: "40px !important",
  },
  gridMargin: {
    marginTop: "20px !important",
  },
  postCode: {
    width: "40%",
  },
  postCodeInput: {
    flexBasis: "50%",
  },
  buttonRight: {
    paddingLeft: "5px",
  },
  h2Margin: {
    "& h2": {
      margin: "30px 0px 0px 0px !important",
    },
  },
  autoCompleteError: {
    borderLeft: "4px solid #d4351c",
    paddingLeft: "10px",
    "& input": {
      border: "4px solid #d4351c",
    },
  },
  secondaryButton: {
    flex: 1,
    backgroundColor: "#f3f2f1 !important",
    color: "#000 !important",
    boxShadow: "0 2px 0 #929191 !important",
    height: "40px",
    fontSize: "16px",
    top: "40px",
    left: "5px",
    width: "50%",
    flexBasis: "50%",
    marginBottom: "0px",
    borderLeft: "50px",
    padding: "5px",
    "&:hover, &:active, &:focus": {
      top: "40px !important",
      color: "#000",
      backgroundColor: "#dbdad9 !important",
    },
  },
  formError: {
    paddingLeft: "15px",
    borderLeft: "4px solid #d4351c",
  },
  changeButton: {
    color: "#1d70b8",
    paddingLeft: "8px",
    fontSize: "1.1875rem",
  },
  changeButtonTwo: {
    color: "#1d70b8",
    fontSize: "1.1875rem",
  },
  hidden: {
    display: "none",
  },
  row: {
    flex: 1,
    borderRadius: 2,
    marginHorizontal: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexShrink: 0,
  },
  ul: {
    list_style_type: "none",
  },
  marginBottom0: {
    marginBottom: "0",
  },
  marginBottom20: {
    marginBottom: "20px !important",
  },
  formErrMsg: {
    color: "#d4351c",
    fontSize: "1.1875rem",
    lineHeight: "1.31579",
    marginBottom: "15px !important",
    fontWeight: "700",
  },
};

const TrainingConfirmationFields = ({
  classes,
  orgType,
  submitUserAggErrorMessage,
}) => {
  const [formContext, setFormContext] = useContext(FormContext);
  const [checked, setChecked] = useState(
    formContext?.trainingConfirmation || false
  );

  const [displayErrorMessage, setDisplayErrorMessage] = useState(null);

  useEffect(() => {
    setDisplayErrorMessage(submitUserAggErrorMessage);
  }, [submitUserAggErrorMessage]);

  const onChangeTermsAndConditions = () => {
    setChecked(!checked);
    setDisplayErrorMessage(checked);
  };

  return (
    <>
      <GridRow>
        <GridCol setWidth="two-thirds">
          <header>
            <H1 className={classes.title}>Confirm before you continue</H1>
          </header>
          <Paragraph>
            All staff members involved in testing are required to complete the
            latest training before using these test kits.
          </Paragraph>

          <UnorderedList>
            <ListItem>
              <a
                href="https://go.tessello.co.uk/TestDeviceTraining/Login.aspx?ts=637719507877693653"
                target="_blank"
                rel="noreferrer"
              >
                View the latest training (opens in new tab).
              </a>
            </ListItem>
          </UnorderedList>
        </GridCol>
      </GridRow>

      <GridRow className={classes.gridMargin}>
        <GridCol setWidth="two-thirds" onClick={onChangeTermsAndConditions}>
          <div className={displayErrorMessage ? classes.formError : ""}>
            {displayErrorMessage && (
              <div
                className={classes.formErrMsg}
                data-automatedtestingid="legal-declaration-form--error"
              >
                Select to continue
              </div>
            )}
            <CheckboxComponent
              componentChecked={checked}
              name="trainingConfirmation"
              text="By placing this order, I confirm that all staff members who will carry out testing have completed the latest training"
              label=""
              className=""
            />
          </div>
        </GridCol>
      </GridRow>
    </>
  );
};

export default withStyles(styles)(TrainingConfirmationFields);

TrainingConfirmationFields.propTypes = {
  classes: PropTypes.shape({
    gridMargin: PropTypes.string,
    checkBoxAlign: PropTypes.string,
    formError: PropTypes.string,
    formErrMsg: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  orgType: PropTypes.string.isRequired,
};
