import React, { useState } from "react";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import {
  Button,
  Details,
  GridCol,
  GridRow,
  H1,
  H2,
  LoadingBox,
  Main,
  Paragraph,
  Table,
} from "govuk-react";
import { Form } from "react-final-form";
import { RadioComponent } from "@cube/global-components";
import { useAnalytics } from "../../../service/adobe-analytics";
import { AdobeAnalyticsURL } from "../../../data/constants";

const styles = {
  gridMargin: {
    marginTop: "30px",
  },
  SubmitButtonFontSize: {
    fontSize: "0.95em",
  },
  formError: {
    paddingLeft: "15px",
    borderLeft: "5px solid #d4351c",
  },
  formErrMsg: {
    color: "#d4351c",
    fontSize: "1.1875rem",
    lineHeight: "1.31579",
    marginBottom: "15px",
    fontWeight: "700",
  },
};

const CookieChoice = ({ classes }) => {
  const windowAllowed = localStorage.getItem("answer");

  const { triggerEvent } = useAnalytics();
  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: "nhs:test:organisation-order:choose-cookies",
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "choose-cookies",
        },
      },
    };
    triggerEvent(analyticsData);
  } else {
    window.digitalData = null;
  }
  const location = useLocation();
  const adobeAnalyticsURL = AdobeAnalyticsURL;

  const [loader, setLoader] = useState(false);
  const history = useHistory();

  const options = [
    { value: "true", label: "Use cookies to measure my website use" },
    { value: "false", label: "Do not use cookies to measure my website use" },
  ];
  const [loaded, setLoaded] = useState(false);

  const onSubmit = (value) => {
    if (value.cookieChoices) {
      const boolean = value.cookieChoices === "true" ? true : false;
      if (boolean === true) {
        localStorage.removeItem("answerNo");
        localStorage.setItem("answer", true);

        const loadScript = (callback) => {
          const existingScript = document.getElementById("adobeAnalytics");

          if (!existingScript) {
            const script = document.createElement("script");
            script.src = adobeAnalyticsURL;
            localStorage.setItem("scriptUrl", adobeAnalyticsURL);
            script.id = "adobeAnalytics";
            script.setAttribute("type", "text/javascript");
            document.body.appendChild(script);
            localStorage.removeItem("answerNo");

            script.onload = () => {
              if (callback) callback();
            };
          }
          if (existingScript && callback) callback();
        };

        loadScript(() => {
          setLoaded(true);
        });
      } else {
        const removeScript = (callback) => {
          const existingScript = document.getElementById("adobeAnalytics");
          const scriptListTwo = document.querySelectorAll("script");
          const convertedNodeList = Array.from(scriptListTwo);
          const testScript = convertedNodeList.find(
            (script) => script.id === "adobeAnalytics"
          );
          localStorage.removeItem("answer");
          localStorage.setItem("answerNo", true);
          (function () {
            const cookies = document.cookie.split("; ");
            const info = document.cookie;
            const cookieCheck = document.cookie;
            for (let c = 0; c < cookies.length; c++) {
              const d = window.location.hostname.split(".");
              while (d.length > 0) {
                const cookieBase =
                  encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
                  "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
                  d.join(".") +
                  " ;path=";
                const p = location.pathname.split("/");
                document.cookie = cookieBase + "/";
                while (p.length > 0) {
                  document.cookie = cookieBase + p.join("/");
                  p.pop();
                }
                d.shift();
              }
            }
          })();
          if (existingScript) {
            testScript.parentNode.removeChild(testScript);
          }
        };
        removeScript(() => {
          setLoaded(false);
        });
      }
      history.push({
        pathname: "/cookies-saved",
      });
    }
  };
  return (
    <LoadingBox
      loading={loader}
      backgroundColor="#fff"
      timeIn={100}
      timeOut={100}
      backgroundColorOpacity={0.85}
      spinnerColor="#000"
    >
      <Main className={[classes.w75p, classes.zeroPadding, classes.w100p]}>
        <GridRow className={classes.headingRow}>
          <GridCol setWidth="two-thirds">
            <header>
              <H1>Choose which cookies we use</H1>
            </header>
            <Paragraph>
              Let us know which cookies we can use. We&apos;ll use a cookie to save
              your settings.
            </Paragraph>
            <H2>Cookies that measure website use (analytics cookies)</H2>
            <Paragraph>
              These cookies store information about how you use our website,
              such as the links you click on. We use them to help make our
              website better.
            </Paragraph>
          </GridCol>
        </GridRow>
        <GridRow>
          <GridCol setWidth="two-thirds">
            <Details summary="List of cookies that measure website use">
              <Table>
                <Table.Row>
                  <Table.CellHeader>Name</Table.CellHeader>
                  <Table.Cell
                    setWidth="one-sixth"
                    style={{ fontWeight: "bold" }}
                  >
                    Purpose
                  </Table.Cell>
                  <Table.Cell
                    setWidth="one-sixth"
                    style={{ fontWeight: "bold" }}
                  >
                    Expires
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>cookie_policy</Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Remembers your cookies settings on our website
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth" setHeight="5px">
                    1 year
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>AMCV_#</Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Used by Adobe Analytics. Tell us if you&apos;ve used our site
                    before
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">2 years</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>AMCVS_#AdobeOrg</Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Used by Adobe Analytics. Tell us how you use our site
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    When you close the browser
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    com.adobe.reactor.dataElementCookiesMigrated
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Used by Adobe Analytics. Includes data elements set to
                    capture usage of our website
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">No expiry</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>demdex</Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Used by Adobe Analytics. Allows cross-domain analytics of
                    NHS properties
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">180 days</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>s_cc</Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Used by Adobe Analytics. Checks if cookies work in your web
                    browser
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    When you close the browser
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>s_getNewRepeat</Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Used by Adobe Analytics. Tells us if you&apos;ve used our website
                    before
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">30 days</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>s_ppn</Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Used by Adobe Analytics. Tells us how you use our website by
                    reading the previous page you visited
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">1 day</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>s_sq</Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Used by Adobe Analytics. Remembers the last link you clicked
                    on
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    When you close the browser
                  </Table.Cell>
                </Table.Row>
              </Table>
            </Details>
          </GridCol>
        </GridRow>
        <GridRow>
          <GridCol>
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, value }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    {options.map((opts) => (
                      <div key={opts.label} className={classes}>
                        <RadioComponent
                          name="cookieChoices"
                          label={opts.label}
                          value={opts.value}
                        />
                      </div>
                    ))}

                    <GridRow className={classes.gridMargin}>
                      <GridCol>
                        <Button
                          data-automatedtestingid="orderform-continue-button"
                          marginTop="50px"
                          onClick={onSubmit}
                        >
                          Save my cookie preferences
                        </Button>
                      </GridCol>
                    </GridRow>
                  </form>
                );
              }}
            />
          </GridCol>
        </GridRow>
      </Main>
    </LoadingBox>
  );
};
export default withRouter(withStyles(styles)(CookieChoice));

CookieChoice.propTypes = {
  classes: PropTypes.shape({
    gridMargin: PropTypes.string,
    buttonRow: PropTypes.string,
    headingRow: PropTypes.string,
    w75p: PropTypes.string,
    sectionBreakMargin: PropTypes.string,
    paddingTop025: PropTypes.string,
    zeroPadding: PropTypes.string,
    w100p: PropTypes.string,
    w62p: PropTypes.string,
  }).isRequired,
};
