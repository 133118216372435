/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Caption, GridCol, GridRow, H1 } from "govuk-react";
import withStyles from "react-jss";
import { InputComponent } from "@cube/global-components";
import { FormContext } from "../../../data/contexts/contexts";

const styles = {
  gridMargin: {
    marginTop: "30px",
  },
};

const ReturnBoxesTestingFields = ({
  classes,
  errMsgBackend,
  errMsgBackendPresent,
  orgType,
  testingType,
}) => {
  const [formContext] = useContext(FormContext);
  const inputWidth = "input-width-4";
  return (
    <>
      <GridRow className={classes.gridMargin}>
        <GridCol>
          <header>
            <Caption>{formContext?.orderTitle}</Caption>
            <H1>Number needed</H1>
          </header>
        </GridCol>
      </GridRow>
      {formContext?.nonStaffMultiplier === true && (
        <GridRow className={classes.gridMargin}>
          <GridCol setWidth="two-thirds">
            <InputComponent
              initialValue={formContext?.totalNumberOfResidents}
              type="number"
              label="Number needed"
              name="totalNumberOfResidents"
              errMessage="Enter a number"
              errMessageNumber="Enter a number"
              errMsgBackend={errMsgBackend}
              errMsgBackendPresent={
                errMsgBackendPresent.length > 0 && errMsgBackendPresent[0]
              }
              width={inputWidth}
              automatedtestingId="orderform-returnboxestestingfields-inputcomponent-totalNumberOfResidents"
            />

            <InputComponent
              aria-hidden="true"
              name="totalNumberOfStaff"
              type="hidden"
              initialValue="0"
              label=""
            />
            <InputComponent
              aria-hidden="true"
              name="noOfRemainingIndvRetestKits"
              type="hidden"
              initialValue="0"
              label=""
            />
            <InputComponent
              aria-hidden="true"
              name="noOfNonStaffUnsuitableGroupTest"
              type="hidden"
              initialValue="0"
              label=""
            />
            <InputComponent
              aria-hidden="true"
              name="noOfStaffUnsuitableGroupTest"
              type="hidden"
              initialValue="0"
              label=""
            />
          </GridCol>
        </GridRow>
      )}
    </>
  );
};

export default withStyles(styles)(ReturnBoxesTestingFields);

ReturnBoxesTestingFields.propTypes = {
  classes: PropTypes.shape({
    gridMargin: PropTypes.string,
  }).isRequired,
  errMsgBackend: PropTypes.string,
  errMsgBackendPresent: PropTypes.any,
  orgType: PropTypes.string.isRequired,
  testingType: PropTypes.string.isRequired,
};
