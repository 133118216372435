import { UONLabels } from "../../data/constants";

const UONTranslation = (organisation) => {
  const labels = UONLabels;
  labels.INFO = { ...labels.INFO, ...labels.INFO.DEFAULT };
  labels.ERROR_MESSAGES = {
    ...labels.ERROR_MESSAGES,
    ...labels.ERROR_MESSAGES.DEFAULT,
  };

  return {
    ...labels,
  };
};

export default UONTranslation;
