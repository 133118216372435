/* eslint-disable */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Caption, GridCol, GridRow, H1, Paragraph } from "govuk-react";
import withStyles from "react-jss";
import { RequestTranslation } from "../../../service/translation";
import { InputComponent } from "@cube/global-components";
import { FormContext } from "../../../data/contexts/contexts";

const styles = {
  gridMargin: {
    marginTop: "30px",
  },
  header: {
    marginBottom: "10px",
  },
};

const OpportunisticTestingFields = ({
  classes,
  orgType,
  testingType,
  errMsgBackendPresent,
}) => {
  const [formContext] = useContext(FormContext);
  const automationIdPrefix = "orderform-oppotestingfields";
  const inputWidth = "input-width-4";

  const RequestLabels = RequestTranslation(orgType);
  const {
    REQUEST_INPUTS: {
      FORM_TESTING_HEADING,
      FORM_GROUP_TESTING_SUB_HEADING,
      FORM_TESTING: {
        TESTING_TOTAL_NON_STAFF_NOTELIGIBLE_COHORTPOOLING,
        TESTING_TOTAL_NUMBER_OF_KITS_REMAINING,
      },
    },
  } = RequestLabels;

  return (
    <>
      {formContext?.showOrderFormForTestingType === "Lateral Flow Testing" ? (
        <>
          <GridRow className={classes.gridMargin}>
            <GridCol>
              <header>
                <Caption>{formContext?.orderTitle}</Caption>
                <H1>{FORM_TESTING_HEADING}</H1>
              </header>
            </GridCol>
          </GridRow>

          <GridRow className={classes.gridMargin}>
            <GridCol setWidth="two-thirds">
              {formContext?.nonStaffMultiplier === true && (
                <InputComponent
                  initialValue={formContext?.totalNumberOfResidents}
                  type="number"
                  label="Number of tests needed"
                  name="totalNumberOfResidents"
                  errMessage="Enter number of tests needed"
                  errMessageNumber="Enter number of tests needed"
                  errMsgBackend="Enter a lower number"
                  errMsgBackendPresent={
                    errMsgBackendPresent?.length > 0 && errMsgBackendPresent[0]
                  }
                  width={inputWidth}
                  automatedtestingId={`${automationIdPrefix}-lft-inputcomponent-totalnumberofresidents`}
                />
              )}
            </GridCol>
          </GridRow>

          {formContext?.staffMultiplier === true && (
            <InputComponent
              aria-hidden={formContext?.staffMultiplier === true ? false : true}
              name="totalNumberOfStaff"
              type="hidden"
              initialValue="0"
              label=""
              automatedtestingId={`${automationIdPrefix}-lft-inputcomponent-totalnumberofstaff`}
            />
          )}
        </>
      ) : (
        <>
          <GridRow className={classes.gridMargin}>
            <GridCol setWidth="two-thirds">
              {formContext?.showOrderFormForTestingType === "Cohort Pooling" ? (
                <header>
                  <Caption>{formContext?.orderTitle}</Caption>
                  <H1>Total number of people to be tested</H1>
                </header>
              ) : (
                <header>
                  <Caption>{formContext?.orderTitle}</Caption>
                  <H1 className={classes.header}>{FORM_TESTING_HEADING}</H1>
                </header>
              )}
            </GridCol>
          </GridRow>

          {formContext?.showOrderFormForTestingType === "Cohort Pooling" && (
            <GridRow>
              <GridCol setWidth="two-thirds">
                <Paragraph>{FORM_GROUP_TESTING_SUB_HEADING}</Paragraph>
              </GridCol>
            </GridRow>
          )}

          {formContext?.nonStaffMultiplier === true && (
            <GridRow className={classes.gridMargin}>
              <GridCol setWidth="two-thirds">
                <InputComponent
                  initialValue={formContext?.totalNumberOfResidents}
                  type="number"
                  label="Number of tests needed"
                  name="totalNumberOfResidents"
                  errMessage="Enter number of tests needed"
                  errMessageNumber="Enter number of tests needed"
                  errMsgBackend="Enter a lower number"
                  errMsgBackendPresent={
                    errMsgBackendPresent?.length > 0 && errMsgBackendPresent[0]
                  }
                  width={inputWidth}
                  automatedtestingId={`${automationIdPrefix}-inputcomponent-totalnumberofresidents`}
                />
              </GridCol>
            </GridRow>
          )}
          {formContext?.staffMultiplier === true && (
            <InputComponent
              aria-hidden={formContext?.staffMultiplier === true ? false : true}
              initialValue={formContext?.totalNumberOfStaff || 0}
              name="totalNumberOfStaff"
              type="hidden"
              label=""
              automatedtestingId={`${automationIdPrefix}-inputcomponent-totalnumberofstaff`}
            />
          )}

          {testingType === "Cohort Pooling" ? (
            <>
              <GridRow className={classes.gridMargin}>
                <GridCol setWidth="two-thirds">
                  <InputComponent
                    aria-hidden="true"
                    initialValue={formContext?.noOfStaffUnsuitableGroupTest || 0}
                    name="noOfStaffUnsuitableGroupTest"
                    type="hidden"
                    label=""
                    automatedtestingId={`${automationIdPrefix}-cohort-inputcomponent-noofstaffunsuitablegrouptest`}
                  />

                  <InputComponent
                    aria-hidden="true"
                    initialValue={formContext?.noOfNonStaffUnsuitableGroupTest}
                    type="number"
                    name="noOfNonStaffUnsuitableGroupTest"
                    label={TESTING_TOTAL_NON_STAFF_NOTELIGIBLE_COHORTPOOLING}
                    errMessage="Enter a number"
                    errMessageNumber="Enter a number"
                    width={inputWidth}
                    hint="Pooled testing tests multiple people together. If anyone has coronavirus symptoms, has tested positive in the past or may not be able to isolate due to their health, they should not take part in pooled testing."
                    automatedtestingId={`${automationIdPrefix}-cohort-inputcomponent-noofnonstaffunsuitablegrouptest`}
                  />
                </GridCol>
              </GridRow>

              <GridRow className={classes.gridMargin}>
                <GridCol setWidth="two-thirds">
                  <InputComponent
                    aria-hidden="true"
                    initialValue={formContext?.noOfRemainingIndvRetestKits}
                    type="number"
                    name="noOfRemainingIndvRetestKits"
                    label={TESTING_TOTAL_NUMBER_OF_KITS_REMAINING}
                    errMessage="Enter a number"
                    errMessageNumber="Enter a number"
                    width={inputWidth}
                    hint="Use these before ordering more."
                    automatedtestingId={`${automationIdPrefix}-cohort-inputcomponent-noofremainingindvretestkits`}
                  />
                </GridCol>
              </GridRow>
            </>
          ) : (
            <>
              <InputComponent
                aria-hidden="true"
                initialValue={formContext?.noOfStaffUnsuitableGroupTest || 0}
                name="noOfStaffUnsuitableGroupTest"
                type="hidden"
                label=""
                automatedtestingId={`${automationIdPrefix}-inputcomponent-noofstaffunsuitablegrouptest`}
              />
              <InputComponent
                aria-hidden="true"
                initialValue={formContext?.noOfNonStaffUnsuitableGroupTest || 0}
                name="noOfNonStaffUnsuitableGroupTest"
                type="hidden"
                label=""
                automatedtestingId={`${automationIdPrefix}-inputcomponent-noofnonstaffunsuitablegrouptest`}
              />
              <InputComponent
                aria-hidden="true"
                initialValue={formContext?.noOfRemainingIndvRetestKits || 0}
                name="noOfRemainingIndvRetestKits"
                type="hidden"
                label=""
                automatedtestingId={`${automationIdPrefix}-inputcomponent-noofremainingindvretestkits`}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default withStyles(styles)(OpportunisticTestingFields);

OpportunisticTestingFields.propTypes = {
  classes: PropTypes.shape({
    gridMargin: PropTypes.string,
  }).isRequired,
  errMsgBackendPresent: PropTypes.any,
  orgType: PropTypes.string.isRequired,
  testingType: PropTypes.string.isRequired,
};
