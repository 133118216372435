import React from "react";
import {
  GridCol,
  GridRow,
  H1,
  Link,
  Paragraph,
} from "govuk-react";
import { useAnalytics } from "@cube/global-components";

const OrgNotEligible = (classes) => {
  const { triggerEvent } = useAnalytics();
  const windowAllowed = localStorage.getItem("answer");
  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: "nhs:test:organisation-order:not-eligible-to-order",
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "not-eligible-to-order",
        },
      },
    };
    triggerEvent(analyticsData);
  } else {
    window.digitalData = null;
  }
  return (
    <GridRow>
      <GridCol setWidth="two-thirds">
        <header>
          <H1>You cannot place an order for your organisation</H1>
        </header>
        <p>For the latest guidance & testing policy, please visit - <a href="https://www.gov.uk/coronavirus" id="coronavirus" target="_blank">Coronavirus (COVID-19): guidance and support - GOV.UK (www.gov.uk)</a>.
          </p>
        <Paragraph>
          If you think this is a mistake, call the contact centre:
          **119**
          (free from mobiles and landlines).
        </Paragraph>
      </GridCol>
    </GridRow>
  );
};

export default OrgNotEligible;
