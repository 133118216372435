import React from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { SelectComponent } from "@cube/global-components";

const styles = {
    flexDisplay: {
        display: "flex",
        alignItems: "center",
    },
    timeColon: {
        margin: "35px 5px 0",
        fontSize: "35px",
        fontWeight: "bold",
    },
    formErrMsg: {
        color: "#d4351c",
        fontSize: "1.1875rem",
        lineHeight: "1.31579",
        marginBottom: "15px",
        fontWeight: "700",
    },
};

const TimeField = ({
    classes,
    hourLabel,
    minuteLabel,
    hourName,
    minuteName,
    hourAutomationTestingID,
    minuteAutomationTestingID,
    error,
    initialValues,
}) => {
    const hoursOptions = () => {
        const keys = [ ...Array(24).keys() ];
        const formattedHours = keys.map( index => ( index.toString().length < 2 ? '0' : '' ) + index++);
        const formattedHoursOptions = formattedHours.map( hour => ({label: hour, value: hour}));
        formattedHoursOptions.unshift({label: '', value: ''});
        return formattedHoursOptions;
    };

    const minutesOptions = () => {
        const keys = [...new Array(4).keys()];
        const formattedMinutes = keys.map( index => (index == 0 ? '0' : '') + index++ * 15);
        const formattedMinutesOptions = formattedMinutes.map( minute => ({label: minute, value: minute}));
        formattedMinutesOptions.unshift({label: '', value: ''})
        return formattedMinutesOptions;
    };
    const onChange = () => {}

    return (
        <div>
            {error && (
                <div
                    className={classes.formErrMsg}
                    data-automatedtestingid={`${hourName}-${minuteName}-error`}
                >
                {error}
                </div>
            )}
            <div className={classes.flexDisplay}>
                <SelectComponent
                    automatedtestingId={hourAutomationTestingID || ""}
                    defaultValue=""
                    initialValue={initialValues.hour || ""}
                    name={hourName || ""}
                    options={hoursOptions()}
                    label={hourLabel}
                    onChange={onChange}
                />
                <span className={classes.timeColon}>:</span>
                <SelectComponent
                    automatedtestingId={minuteAutomationTestingID || ""}
                    defaultValue=""
                    initialValue={initialValues.minute || ""}
                    name={minuteName || ""}
                    options={minutesOptions()}
                    label={minuteLabel}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default withStyles(styles)(TimeField);

TimeField.defaultProps = {
};

TimeField.propTypes = {
    classes: PropTypes.shape({
        gridMargin: PropTypes.string,
        flexDisplay: PropTypes.string,
        timeColon: PropTypes.string,
        formErrMsg: PropTypes.string
    }).isRequired,
    hourLabel: PropTypes.string,
    minuteLabel: PropTypes.string,
    hourName: PropTypes.string,
    minuteName: PropTypes.string,
    hourAutomationTestingID: PropTypes.string,
    minuteAutomationTestingID: PropTypes.string,
    error: PropTypes.string,
    initialValues: PropTypes.shape({
        hour: PropTypes.string,
        minute: PropTypes.string
    }),
};
