/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from "react";
import { useHistory, withRouter } from "react-router-dom";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { Button } from "govuk-react";
import { Form } from "react-final-form";
import { ErrorLabels } from "../../../data/constants";
import { RequestTranslation } from "../../../service/translation";
import DailyTestingFields from "./DailyTestingFields/DailyTestingFields.component";
import { FormContext } from "../../../data/contexts/contexts";

const styles = {
  gridMargin: {
    marginTop: "30px",
  },
};

const { FAILURE_ERR_MSG } = ErrorLabels;

const DailyTestingForm = ({
  classes,
  handleSubError,
  handleLoader,
  setErrorArray,
  setErrorMessage,
}) => {
  const [formContext, setFormContext] = useContext(FormContext);
  const crqId = formContext?.crqId || "";
  const orgData = formContext?.crqData || null;
  const orgType = formContext?.orgType || null;
  const history = useHistory();
  const RequestLabels = RequestTranslation(orgType);
  const {
    REQUEST_INPUTS: { FORM_SUBMIT_BTN_CONTINUE },
  } = RequestLabels;

  const validateFields = (e) => {
    return true;
  };

  const onSubmit = (value) => {
    if (!value.orgDailyContactTesting) {
      let errorArr = [];
      errorArr.push({
        targetName: "orgDailyContactTesting",
        text:
          "Select whether anyone at your organisation is doing daily contact testing",
      });

      setErrorArray(errorArr);
      setErrorMessage("There is a problem");
    } else {
      const finalValueToPersist = { ...value };

      setFormContext({
        ...formContext,
        ...finalValueToPersist,
        orgDailyContactTesting: !!+value.orgDailyContactTesting,
        orgWeekendTestingCommitment: false,
      });

      handleLoader(true);

      history.push({
        pathname: "/test-numbers",
      });

      handleLoader(false);
      handleSubError(null);
    }
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <form id="dailyContactTesting" onSubmit={handleSubmit}>
              <DailyTestingFields />              
              <Button
                onClick={event => validateFields(event)}
                className={classes.gridMargin}
                data-automatedtestingid="orderform-continue-button"
              >
                {FORM_SUBMIT_BTN_CONTINUE}
              </Button>
            </form>
          );
        }}
      />
    </>
  );
};

export default withRouter(withStyles(styles)(DailyTestingForm));

DailyTestingForm.defaultProps = {
  setErrorArray: () => {},
  setErrorMessage: () => {}
};

DailyTestingForm.propTypes = {
  classes: PropTypes.shape({
    gridMargin: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      crqData: PropTypes.shape({
        City: PropTypes.string,
        OrganisationName: PropTypes.string,
        Postcode: PropTypes.string,
        Street: PropTypes.string,
        TestingApproach: PropTypes.string,
        cqcRegistrationID: PropTypes.string,
      }).isRequired,
      crqId: PropTypes.string.isRequired,
      orgType: PropTypes.string.isRequired,
    }),
  }).isRequired,
  handleSubError: PropTypes.func.isRequired,
  handleLoader: PropTypes.func.isRequired,
  setErrorArray: PropTypes.func,
  setErrorMessage: PropTypes.func,
};
