/* eslint-disable import/prefer-default-export */
import { useCallback } from "react";

export const useAnalytics = () => {
  const triggerEvent = useCallback((object) => {
    window.digitalData = { ...window.digitalData, ...object };
  });
  return {
    triggerEvent,
  };
};
