/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { Button, GridCol, GridRow, Paragraph } from "govuk-react";
import { Form } from "react-final-form";
import { useAnalytics } from "@cube/global-components";
import { ErrorLabels } from "../../data/constants";
import { RequestTranslation } from "../../service/translation";
import TestingFields from "./TestingFields";
import {
  opportunisticFormValidation,
  populationFormValidation,
  communityFormValidation,
} from "../../service/validation";
import DataAccessService from "../../service/httpUtility";
import { FormContext } from "../../data/contexts/contexts";

const styles = {
  gridMargin: {
    marginTop: "30px",
  },
};

const RequestLabels = RequestTranslation();
const {
  ERROR_MESSAGES: {
    TOTAL_NON_STAFF_BLANK_ERR_MSG,
    TOTAL_STAFF_BLANK_ERR_MSG,
    TOTAL_STAFF_NOTELIGIBLE_COHORTPOOLING_BLANK_ERR_MSG,
    TOTAL_NON_STAFF_NOTELIGIBLE_COHORTPOOLING_BLANK_ERR_MSG,
    TOTAL_NUMBER_OF_RETESTKITS_BLANK_ERROR,
    TOTAL_STAFF_ZERO,
    TOTAL_NON_STAFF_ZERO,
    SYMPTOMATIC_STAFF_NON_STAFF_ZERO,
    TESTING_TOTAL_STAFF_EMTPY_PT,
    TESTING_TOTAL_NON_STAFF_EMTPY_PT,
    TESTING_SYMPTOMATIC_STAFF_NON_STAFF_EMPTY_PT,
    TESTING_TOTAL_STAFF_LOWER_NUMBER_PT,
    TESTING_TOTAL_NON_STAFF_LOWER_NUMBER_PT,
    TESTING_SYMPTOMATIC_STAFF_NON_STAFF_LOWER_NUMBER_PT,
  },
  REQUEST_INPUTS: {
    TESTNUMBER_HELP,
    TESTNUMBER_HELP_COMMUNITY_ALTERNATIVE,
    TESTNUMBER_HELP_COMMUNITY_PART_2,
    TESTNUMBER_HELP_COMMUNITY_PART_3,
  },
} = RequestLabels;

const TestNumbersForm = ({
  classes,
  handleSubError,
  handleLoader,
  setErrorArray,
  setErrorMessage,
}) => {
  const [errMsgBackendPresent, setErrMsgBackendPresent] = useState([]);
  const [errMsgBackend, setErrMsgBackend] = useState("The total number must be greater than 0");
  const [formContext, setFormContext] = useContext(FormContext);
  const orgType = formContext?.orgType || null;
  const { triggerEvent } = useAnalytics();
  const history = useHistory();
  const [redirected, setRedirected] = useState(
    formContext?.redirected || false
  );
  const windowAllowed = localStorage.getItem("answer");
  const fromSummary = !!history.location?.state?.fromSummary;
  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: "nhs:test:organisation-order:test-numbers",
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "test-numbers",
        },
      },
    };

    triggerEvent(analyticsData);
  } else {
    window.digitalData = null;
  }

  const RequestLabels = RequestTranslation(orgType);
  const {
    REQUEST_INPUTS: { FORM_SUBMIT_BTN_CONTINUE },
  } = RequestLabels;

  const validate = (values) => {
    if (formContext.testingApproach === "Opportunistic Testing") {
      return opportunisticFormValidation(values, RequestLabels);
    }

    if (orgType === "Visiting Professional") {
      return populationFormValidation(values, RequestLabels, true, isSymptomaticRegime());
    }
    if (formContext.testingApproach === "Bulk Testing") {
      if (
        values.totalNumberOfResidents &&
        parseInt(values.totalNumberOfResidents, 10) < 1
      ) {
        const errors = {};
        errors.totalNumberOfResidents =
          "Currently you can only order 1 carton of test kits. Please change the number of cartons needed.";
        return errors;
      }
      if (
        values.totalNumberOfResidents &&
        parseInt(values.totalNumberOfResidents, 10) > 1
      ) {
        const errors = {};
        errors.totalNumberOfResidents =
          "Currently you can only order 1 carton of test kits. Please change the number of cartons needed.";
        return errors;
      }
    }
    if (formContext.testingApproach === "Community Testing") {
      return communityFormValidation(values);
    }

    return populationFormValidation(values, RequestLabels, false, isSymptomaticRegime());
  };

  const validateFields = () => {
    const orgForm = window.testingFields;
    const { elements } = orgForm;
    const errorArr = [];
    setErrMsgBackendPresent([]);

    if (
      formContext?.nonStaffMultiplier === true &&
      formContext.testType === "Return Boxes"
    ) {
      if (parseInt(elements.totalNumberOfResidents.value.toString(), 10) < 1) {
        const targetName = "totalNumberOfResidents";
        errorArr.push({
          targetName,
          text: "The total number must be greater than 0",
        });
        setErrMsgBackend("The total number must be greater than 0");
        setErrMsgBackendPresent((error) => [
          ...error,
          targetName
        ]);
      }

      if (!elements.totalNumberOfResidents.value) {
        errorArr.push({
          targetName: "totalNumberOfResidents",
          text: "Enter a number",
        });
      }

      if (
        formContext.testType !== "Return Boxes" &&
        !elements.totalNumberOfResidents.value
      ) {
        errorArr.push({
          targetName: "totalNumberOfResidents",
          text: TOTAL_NON_STAFF_BLANK_ERR_MSG,
        });
      }
    }

    // Bulk Testing
    if (formContext?.testingApproach === "Bulk Testing") {
      if (
        elements.totalNumberOfResidents &&
        !elements.totalNumberOfResidents.value
      ) {
        errorArr.push({
          targetName: "totalNumberOfResidents",
          text: "Enter number of cartons needed",
        });
      }

      if (
        elements.totalNumberOfResidents.value &&
        elements?.totalNumberOfResidents?.value.toString() !== "1"
      ) {
        errorArr.push({
          targetName: "totalNumberOfResidents",
          text: "Currently you can only order 1 carton of test kits. Please change the number of cartons needed.",
        });
      }
    }

    // Community Testing
    if (formContext?.testingApproach === "Community Testing") {
      if (!elements.totalNumberOfResidents.value) {
        errorArr.push({
          targetName: "totalNumberOfResidents",
          text: "Enter number of pallets needed",
        });
      }

      if (
        elements.totalNumberOfResidents.value &&
        elements?.totalNumberOfResidents?.value.toString() === "0"
      ) {
        errorArr.push({
          targetName: "totalNumberOfResidents",
          text: "Number of pallets must be more than 0",
        });
      }

      if (
        formContext.orderTitle === "Rapid lateral flow tests for use on-site" &&
        elements.totalNumberOfResidents.value &&
        parseInt(elements.totalNumberOfResidents.value.toString(), 10) > 4
      ) {
        errorArr.push({
          targetName: "totalNumberOfResidents",
          text: "Currently you can order maximum 4 pallets of test kits. Please change the number of pallets needed.",
        });
      }

      if (
        formContext.orderTitle ===
          "Rapid lateral flow tests for home testing" &&
        elements.totalNumberOfResidents.value &&
        parseInt(elements.totalNumberOfResidents.value.toString(), 10) > 35
      ) {
        errorArr.push({
          targetName: "totalNumberOfResidents",
          text: "Currently you can order maximum 35 pallets of test kits. Please change the number of pallets needed.",
        });
      }
    }

    if (
      formContext?.nonStaffMultiplier === true &&
      formContext.testingApproach === "Opportunistic Testing" &&
      formContext.testType !== "Return Boxes"
    ) {
      if (parseInt(elements.totalNumberOfResidents.value.toString(), 10) < 1) {
        errorArr.push({
          targetName: "totalNumberOfResidents",
          text: "The total number of tests you order must be more than 0",
        });
      }

      if (
        formContext?.nonStaffMultiplier === true &&
        !elements.totalNumberOfResidents.value
      ) {
        errorArr.push({
          targetName: "totalNumberOfResidents",
          text: TOTAL_NON_STAFF_BLANK_ERR_MSG,
        });
      }
    }

    if (
      (formContext?.staffMultiplier === true ||
        formContext?.nonStaffMultiplier === true) &&
      formContext.testingApproach === "Population Testing" &&
      formContext.testType !== "Return Boxes"
    ) {
      if (elements.totalNumberOfStaff && !elements.totalNumberOfStaff.value) {
        errorArr.push({
          targetName: "totalNumberOfStaff",
          text: staffEmptyErrorMessage(),
        });
      }

      if (
        formContext?.nonStaffMultiplier === true &&
        elements.totalNumberOfResidents &&
        !elements.totalNumberOfResidents.value
      ) {
        errorArr.push({
          targetName: "totalNumberOfResidents",
          text:
            formContext?.testingApproach === "Population Testing"
              ? nonStaffEmptyErrorMessage()
              : "Enter a number of non-staff to be tested",
        });
      }

      const allStaffFieldsDisplayed =
        formContext.nonStaffMultiplier && formContext.staffMultiplier;

      if (!allStaffFieldsDisplayed) {
        if (
          formContext?.staffMultiplier === true &&
          elements?.totalNumberOfStaff?.value.toString() === "0"
        ) {
          errorArr.push({
            targetName: "totalNumberOfStaff",
            text: staffZeroErrorMessage()
          });
        }

        if (
          formContext?.nonStaffMultiplier === true &&
          elements?.totalNumberOfResidents?.value.toString() === "0"
        ) {
          errorArr.push({
            targetName: "totalNumberOfResidents",
            text: nonStaffZeroErrorMessage(),
          });
        }
      } else {
        // #10069 changes to the error messages occur because of Population Testing
        if (
          elements?.totalNumberOfStaff?.value.toString() === "0" &&
          elements?.totalNumberOfResidents?.value.toString() === "0"
        ) {
          errorArr.push({
            targetName: "totalNumberOfStaff",
            text:
              formContext?.testingApproach === "Population Testing"
                ? staffZeroErrorMessage()
                : "The total number of tests you order must be more than 0",
          });
          errorArr.push({
            targetName: "totalNumberOfResidents",
            text:
              formContext?.testingApproach === "Population Testing"
                ? nonStaffZeroErrorMessage()
                : "The total number of tests you order must be more than 0",
          });
        }
      }
    }

    // IF VALUE IS UNDEFINED
    if (
      formContext?.testType === "Cohort Pooling" &&
      elements.noOfStaffUnsuitableGroupTest.value.length === 0
    ) {
      errorArr.push({
        targetName: "noOfStaffUnsuitableGroupTest",
        text: TOTAL_STAFF_NOTELIGIBLE_COHORTPOOLING_BLANK_ERR_MSG,
      });
    }

    if (
      formContext?.testType === "Cohort Pooling" &&
      elements.noOfNonStaffUnsuitableGroupTest.value.length === 0
    ) {
      errorArr.push({
        targetName: "noOfNonStaffUnsuitableGroupTest",
        text: TOTAL_NON_STAFF_NOTELIGIBLE_COHORTPOOLING_BLANK_ERR_MSG,
      });
    }

    if (
      formContext?.testType === "Cohort Pooling" &&
      elements.noOfRemainingIndvRetestKits.value.length === 0
    ) {
      errorArr.push({
        targetName: "noOfRemainingIndvRetestKits",
        text: TOTAL_NUMBER_OF_RETESTKITS_BLANK_ERROR,
      });
    }

    if (errorArr.length > 0) {
      setErrorArray(errorArr);
      setErrorMessage("There is a problem");
      return false;
    }

    handleLoader(true);
    setErrorMessage(null);
    setErrorArray(null);
    return true;
  };

  const onSubmit = (e) => {
    const orgForm = window.testingFields;
    const { elements } = orgForm;

    let staff = formContext?.totalNumberOfStaff;
    let residents = formContext?.totalNumberOfResidents;

    if (staff && formContext?.staffMultiplier === true) {
      if (parseInt(elements.totalNumberOfStaff.value, 10) !== staff) {
        staff = parseInt(elements.totalNumberOfStaff.value, 10) || 0;
      }
    }

    if (residents && formContext?.nonStaffMultiplier === true) {
      if (parseInt(elements.totalNumberOfResidents.value, 10) !== residents) {
        residents = parseInt(elements.totalNumberOfResidents.value, 10) || 0;
      }
    } else {
      if (formContext?.nonStaffMultiplier === true) {
        residents = parseInt(elements.totalNumberOfResidents.value, 10) || 0;
      }
    }

    const addedField = Math.max(
      (formContext?.staffMultiplier ? (staff ? parseInt(staff, 10) : 0) : 0) +
        (formContext?.nonStaffMultiplier ? parseInt(residents, 10) : 0)
    );

    const totalNumberOfStaff =
      formContext?.staffMultiplier === true && elements.totalNumberOfStaff
        ? staff !== formContext?.totalNumberOfStaff
          ? staff
          : parseInt(elements.totalNumberOfStaff.value, 10)
        : 0;

    const totalNumberOfResidents =
      formContext?.nonStaffMultiplier === true
        ? residents !== formContext?.totalNumberOfResidents
          ? residents
          : parseInt(elements.totalNumberOfResidents.value, 10)
        : 0;

    const finalValueToPersist = {
      ...formContext,
      totalStaff: parseInt(addedField, 10),
      totalNumberOfStaff: parseInt(totalNumberOfStaff, 10),
      totalNumberOfResidents: parseInt(totalNumberOfResidents, 10),
      // eslint-disable-next-line radix
      noOfNonStaffUnsuitableGroupTest: parseInt(
        e.noOfNonStaffUnsuitableGroupTest,
        10
      ),
      // eslint-disable-next-line radix
      noOfStaffUnsuitableGroupTest: parseInt(
        e.noOfStaffUnsuitableGroupTest,
        10
      ),
      // eslint-disable-next-line radix
      noOfRemainingIndvRetestKits: parseInt(e.noOfRemainingIndvRetestKits, 10),
    };

    DataAccessService.sendRequestForm({
      cqcRegistrationID: formContext?.crqId,
      testingTypeID: formContext?.testingTypeID,
      orgType: formContext?.orgType,
      createOrder: false,
      ...finalValueToPersist,
    })
      .then((val) => {
        const redirected = fromSummary;
        const pageLocation =
        formContext?.testType === "Cohort Pooling" ||
        formContext?.testType === "Individual Testing" ||
        formContext?.testType === "Lateral Flow Testing"
            ? "/test-numbers-boxes"
            : formContext?.showOrderFormForTestingType === "Return Boxes" &&
              redirected
            ? "/confirm-choices"
            : formContext?.showOrderFormForTestingType === "Return Boxes"
            ? "/contact"
            : "/weekendtesting";

        handleLoader(false);
        handleSubError(null);

        setFormContext({
          ...formContext,
          ...finalValueToPersist,
          redirected: fromSummary,
          reselectType: false,
          pageLocation: `${pageLocation}`,
          ...val,
        });

        history.push({
          pathname: pageLocation,
        });
      })
      .catch((err) => {
        const thisArray = [];

        const errorResponse = err?.response?.data?.errMessage.split(";");

        // UNNECESSARY HACK. PROPER ERROR OBJECT FROM SF WOULD BE NICE
        if (errorResponse && errorResponse.length > 1) {
          errorResponse.forEach(function (element, index) {
            // #10032 need to ensure that the correct error message is shown for
            // return boxes if the user has put in a larger number than SF has
            // set as the acceptable limit
            const txt =
              formContext?.showOrderFormForTestingType === "Return Boxes"
                ? element.match(/lower number/i)
                  ? "Enter a lower number"
                  : isSymptomaticRegime() ? TESTING_SYMPTOMATIC_STAFF_NON_STAFF_LOWER_NUMBER_PT : element.replace("to be tested", "in your setting")
                : isSymptomaticRegime() ? TESTING_SYMPTOMATIC_STAFF_NON_STAFF_LOWER_NUMBER_PT : element.replace("to be tested", "in your setting");
            const targetName =
              index === 0 ? "totalNumberOfResidents" : "totalNumberOfStaff";
            setErrMsgBackend(txt);
            thisArray.push({
              targetName,
              text: txt,
            });
            setErrMsgBackendPresent((error) => [...error, targetName]);
          });
        } else {
          const errMessage = err?.response?.data?.errMessage;
          const targetName =
            errorResponse[0] === "Enter a lower number of staff to be tested"
              ? "totalNumberOfStaff"
              : "totalNumberOfResidents";
          // #10032 need to ensure that the correct error message is shown for
          // return boxes if the user has put in a larger number than SF has
          // set as the acceptable limit
          const txt =
            formContext?.showOrderFormForTestingType === "Return Boxes"
              ? errMessage.match(/lower number/i)
                ? "Enter a lower number"
                : isSymptomaticRegime() ? TESTING_SYMPTOMATIC_STAFF_NON_STAFF_LOWER_NUMBER_PT : errMessage.replace("to be tested", "in your setting")
              : isSymptomaticRegime() ? TESTING_SYMPTOMATIC_STAFF_NON_STAFF_LOWER_NUMBER_PT : errMessage.replace("to be tested", "in your setting");
          setErrMsgBackend(txt);
          thisArray.push({
            targetName,
            text: txt,
          });
          setErrMsgBackendPresent((error) => [...error, targetName]);
        }

        setErrorArray(thisArray);
        setErrorMessage("There is a problem");

        handleLoader(false);
      });

    return true;
  };

  const emailLinkRender = ({ href, children }) => <a href={href}>{children}</a>;

  const getContactInfotext = () => {
    if (formContext?.testingApproach === "Community Testing") {
      return (
        <div>
          <Paragraph linkRenderer={emailLinkRender}>
            {TESTNUMBER_HELP_COMMUNITY_ALTERNATIVE}
          </Paragraph>
          <Paragraph>{TESTNUMBER_HELP_COMMUNITY_PART_2}</Paragraph>
          <Paragraph>{TESTNUMBER_HELP_COMMUNITY_PART_3}</Paragraph>
        </div>
      );
    }
    return (
      <div>
        <Paragraph>{TESTNUMBER_HELP}</Paragraph>
      </div>
    );
  };

  const handleFieldsOnBlur = (event) => {
    event.preventDefault();
  };

  const isSymptomaticRegime = () => {
    return formContext?.testingRegime === "Symptomatic Testing On-Site" || formContext?.testingRegime === "Symptomatic Testing Self-Test";
  };

  const staffZeroErrorMessage = () => {
    return isSymptomaticRegime() ? SYMPTOMATIC_STAFF_NON_STAFF_ZERO : TOTAL_STAFF_ZERO;
  };

  const nonStaffZeroErrorMessage = () => {
    return isSymptomaticRegime() ? SYMPTOMATIC_STAFF_NON_STAFF_ZERO : TOTAL_NON_STAFF_ZERO;
  };

  const staffEmptyErrorMessage = () => {
    return isSymptomaticRegime() ? TESTING_SYMPTOMATIC_STAFF_NON_STAFF_EMPTY_PT : TESTING_TOTAL_STAFF_EMTPY_PT;
  };

  const nonStaffEmptyErrorMessage = () => {
    return isSymptomaticRegime() ? TESTING_SYMPTOMATIC_STAFF_NON_STAFF_EMPTY_PT : TESTING_TOTAL_NON_STAFF_EMTPY_PT;
  };

  const staffLowerNumberErrorMessage = () => {
    return isSymptomaticRegime() ? TESTING_SYMPTOMATIC_STAFF_NON_STAFF_LOWER_NUMBER_PT : TESTING_TOTAL_STAFF_LOWER_NUMBER_PT;
  };

  const nonStaffLowerNumberErrorMessage = () => {
    return isSymptomaticRegime() ? TESTING_SYMPTOMATIC_STAFF_NON_STAFF_LOWER_NUMBER_PT : TESTING_TOTAL_NON_STAFF_LOWER_NUMBER_PT;
  };

  const customErrorMessages = () => {
    return {
      staffZeroErrorMessage: staffZeroErrorMessage(),
      nonStaffZeroErrorMessage: nonStaffZeroErrorMessage(),
      staffEmptyErrorMessage: staffEmptyErrorMessage(),
      nonStaffEmptyErrorMessage: nonStaffEmptyErrorMessage(),
      staffLowerNumberErrorMessage: staffLowerNumberErrorMessage(),
      nonStaffLowerNumberErrorMessage: nonStaffLowerNumberErrorMessage(),
    };
  };

  return (
    <>
      <Form
        initialValues={formContext}
        onSubmit={(e) => onSubmit(e)}
        validate={validate}
        render={({ handleSubmit }) => {
          return (
            <form id="testingFields" onSubmit={handleSubmit}>
              <TestingFields
                orgType={orgType}
                testingApproach={formContext.testingApproach}
                testingType={formContext.testType}
                errMsgBackend={errMsgBackend}
                errMsgBackendPresent={errMsgBackendPresent}
                isSymptomaticRegime={isSymptomaticRegime()}
                customErrorMessages={customErrorMessages()}
              />
              <Button
                className={classes.gridMargin}
                data-automatedtestingid="orderform-continue-button"
                onClick={(event) => validateFields(event)}
                onMouseDown={(event) => handleFieldsOnBlur(event)}
              >
                Continue
              </Button>

              <GridRow className={classes.gridRow}>
                <GridCol setWidth="two-thirds">{getContactInfotext()}</GridCol>
              </GridRow>
            </form>
          );
        }}
      />
    </>
  );
};

export default withRouter(withStyles(styles)(TestNumbersForm));

TestNumbersForm.defaultProps = {
  setErrorMessage: () => {},
};

TestNumbersForm.propTypes = {
  classes: PropTypes.shape({
    gridMargin: PropTypes.string,
    gridRow: PropTypes.string,
  }).isRequired,
  setErrorMessage: PropTypes.func,
  handleSubError: PropTypes.func.isRequired,
  handleLoader: PropTypes.func.isRequired,
};
