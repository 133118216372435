import React from "react";
import PropTypes from "prop-types";
import { Radio } from "govuk-react";
import { Field } from "react-final-form";

const RadioComponent = ({ componentChecked, value, label, name, disabled, onChange, automatedtestingId }) => (
  <Field
    value={value}
    type="radio"
    name={name}
    render={({ input }) =>
      componentChecked ? (
        <Radio disabled={disabled} name={name} checked onChange={onChange} data-automatedtestingid={automatedtestingId || ""}>
          {label}
        </Radio>
      ) : (
        <Radio disabled={disabled} name={name} {...input} onChange={onChange} data-automatedtestingid={automatedtestingId || ""}>
          {label}
        </Radio>
      )
    }
  />
);

export default RadioComponent;

RadioComponent.defaultProps = {
  componentChecked: false
};

RadioComponent.propTypes = {
  componentChecked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
