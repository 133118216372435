import React from "react";
import {Field} from "react-final-form";
import withStyles from "react-jss";
import {InputField} from "govuk-react";
import PropTypes from "prop-types";
import {inputWidthStyles} from "../../../../data/jss/Input.component.jss";
import inputWidthTranslator from "../../../../service/jss";

const styles = inputWidthStyles;

const NumberInput = ({label, name, initialValue, hint, errMessage, errMessageNumber, errMsgBackend, errMsgBackendPresent, width, classes, notrequired, automatedtestingId, onInput}) => {
  const numberValidator = (errorMessage, errorMessageNumber,errMsgBackend, errMsgBackendPresent) => (value) => {
    const errMsg = !errorMessage ? "Required" : errorMessage;
    const numRegex = /^\d+$/;
    if (value && !numRegex.test(value)) {
      return errorMessageNumber;
    }
    if (value === 0) {
      return undefined;
    }
    if (isNaN(value)) {
      return errMsg;
    }
    if(errMsgBackendPresent === "totalNumberOfResidents" || errMsgBackendPresent === "totalNumberOfStaff" || errMsgBackendPresent === "organisationNumber"){
      return errMsgBackend;
    }
    return value ? undefined : errMsg;
  };

  return (
    <Field
      validate={notrequired === true ? false : numberValidator(errMessage, errMessageNumber, errMsgBackend, errMsgBackendPresent)}
      label={label}
      name={name}
      initialValue={initialValue}
      render={({input, meta}) => (
        <InputField
          {...input}
          meta={meta}
          value={parseInt(input.value,10)}
          input={{...input, type: "number", "data-automatedtestingid": automatedtestingId, min: 0}}
          onKeyPress={e => { if (e.which > 31 && (e.which < 48 || e.which > 57)) { e.preventDefault();}}}
          name={name}
          hint={[hint]}
          className={inputWidthTranslator(width, classes)}
          onInput={onInput}
        >
          {label}
        </InputField>
      )}
    />
  );
};

export default withStyles(styles)(NumberInput);

NumberInput.defaultProps = {
  name: "",
  hint: "",
  label: "",
  width: "width-full",
  errMessage: "",
  errMessageNumber: "",
  errMsgBackend: "",
  errMsgBackendPresent: "",
  notrequired: true,
  initialValue: ""
};

NumberInput.propTypes = {
  name: PropTypes.string,
  errMessage: PropTypes.string,
  errMessageNumber: PropTypes.string,
  errMsgBackend: PropTypes.string,
  errMsgBackendPresent: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  notrequired: PropTypes.bool,
  width: PropTypes.string,
  initialValue: PropTypes.string,
};