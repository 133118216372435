import React from "react";
import PropTypes from "prop-types";
import { EmailInput, NumberInput, TextInputNoError } from "./InputField";

const InputComponentNoError = ({
  label,
  name,
  hint,
  errMessageNumber,
  inputWidth,
  type,
}) => {
  switch (type) {
    case "text":
      return (
        <TextInputNoError
          label={label}
          hint={hint}
          name={name}
          inputWidth={inputWidth}
        />
      );

    case "number":
      return (
        <NumberInput
          label={label}
          errMessageNumber={errMessageNumber}
          hint={hint}
          name={name}
          inputWidth={inputWidth}
        />
      );
    case "email":
      return (
        <EmailInput
          label={label}
          hint={hint}
          name={name}
          inputWidth={inputWidth}
        />
      );
    default:
      return null;
  }
};

export default InputComponentNoError;

InputComponentNoError.defaultProps = {
  hint: "",
  errMessageNumber: "",
  inputWidth: ""
};

InputComponentNoError.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "email", "number", "hidden"]).isRequired,
  hint: PropTypes.string,
  label: PropTypes.string.isRequired,
  errMessageNumber: PropTypes.string,
  inputWidth: PropTypes.oneOf([
    "input-width-20",
    "input-width-10",
    "input-width-5",
    "input-width-4",
    "input-width-3",
    "input-width-2",
    "width-full",
    "three-quarters",
    "two-thirds",
    "one-half",
    "one-third",
    "one-quarter",
  ]),
};
