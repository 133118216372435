import React, { useContext } from "react";
import {
  Button,
  H4,
  Panel,
  Paragraph, 
} from "govuk-react";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { useHistory, withRouter } from "react-router-dom";
import { useAnalytics } from "@cube/global-components";
import { ConfirmationLabels } from "../../data/constants";
import { FormContext } from "../../data/contexts/contexts";
import DataAccessService from "../../service/httpUtility";
 
const styles = {
  btnSubmit: {
    marginTop: "30px",
  },
  SuccessPanel: {
    backgroundColor: "#00703c",
  },
};

const {
  FORM_SUCCESS_PANEL,
  FORM_SUCCESS_BTN_TITLE,
  FORM_SUCCESS_MAIN_MSG_FIRST,
  FORM_SUCCESS_MAIN_MSG_REFERRER,
  FORM_SUCCESS_MAIN_MSG_SECOND,
  FORM_SUCCESS_MAIN_MSG_THIRD,
  FORM_SUCCESS_MAIN_MSG_FOURTH,
  FORM_SUCCESS_MSG_HEADING,
  FORM_SUCCESS_SUB_HEADING_FIRST,
} = ConfirmationLabels;

const ConfirmationComponent = ({
  classes,
  location: {
    state: { email, secondaryContactEmail, secondaryContactSource, oun },
  },
}) => {
  const { triggerEvent } = useAnalytics();

  // eslint-disable-next-line no-unused-vars
  const [formContext, setFormContext] = useContext(FormContext);

  const windowAllowed = localStorage.getItem("answer");
  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: "nhs:test:organisation-order:submission",
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "submission",
        },
      },
    };
    triggerEvent(analyticsData);
  } else {
    window.digitalData = null;
  }
  localStorage.setItem("endOfForm", true);

  const history = useHistory();
  const backToOverview = () => {
    DataAccessService.sendUON(oun)
      .then((result) => {
        setFormContext({
          crqId: result.cqcRegistrationID,
          crqData: result,
          orgType: result.OrganisationType,
        });
        history.push({
          pathname: "/overview",
        });
      })
      .catch((e) => {
        console.log("Error from Salesforce", e);
      });
  };

  return (
    <>
      <Panel className={classes.SuccessPanel} title={FORM_SUCCESS_PANEL} />
      <H4>{FORM_SUCCESS_MSG_HEADING}</H4>

      <div style={{ wordWrap: "break-word" }}>
        <Paragraph>{FORM_SUCCESS_SUB_HEADING_FIRST}</Paragraph>
        <Paragraph>
          {`${FORM_SUCCESS_MAIN_MSG_FIRST} **${email}** ${
            secondaryContactSource === "yes" &&
            secondaryContactEmail !== undefined
              ? `${FORM_SUCCESS_MAIN_MSG_REFERRER} **${secondaryContactEmail}**`
              : ""
          } ${FORM_SUCCESS_MAIN_MSG_SECOND}`}
        </Paragraph>
        <Paragraph>{FORM_SUCCESS_MAIN_MSG_THIRD}</Paragraph>
        <Paragraph>
          {FORM_SUCCESS_MAIN_MSG_FOURTH}
        </Paragraph>
      </div>

      <Button
        className={classes.btnSubmit}
        onClick={backToOverview}
        data-automatedtestingid="orderform-continue-button"
      >
        {FORM_SUCCESS_BTN_TITLE}
      </Button>
    </>
  );
};

ConfirmationComponent.propTypes = {
  classes: PropTypes.shape({
    btnSubmit: PropTypes.string,
    SuccessPanel: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.string,
      secondaryContactEmail: PropTypes.string,
      secondaryContactSource: PropTypes.string,
      oun: PropTypes.string,
    })
  }).isRequired,
};

export default withRouter(withStyles(styles)(ConfirmationComponent));
