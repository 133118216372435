const UONLabels = {
  ERROR_MESSAGES: {
    DEFAULT: {
      UON_ERROR_HEADING: "Your organisation number has not been accepted.",
      UON_ERROR_EMPTY_FIELDS:
        "Please enter your Unique Organisation Number (UON)",
      UON_ERROR_ORG_DESC:
        "This could be because you have entered an invalid organisation number. Please check and try again.",
      UON_ERROR_DES_2:
        "Check if your organisation has already ordered tests. At this time we are not able to process re-ordering of test kits.",
      UON_ERROR_DES_3:
        "If you think you qualify for coronavirus testing and are experiencing difficulties registering please call the helpline on 119",
      UON_ERROR_DES_4:
        "If you think you qualify for coronavirus testing and are experiencing difficulties registering please call the helpline on 119.",
    },
  },
  INFO: {
    DEFAULT: {
      UNIQUE_ID_HEADING:
        "Order coronavirus (COVID-19) tests for your organisation",
      UNIQUE_ID_SUB_HEADING: "Enter your unique organisation number (UON)",
      UNIQUE_ID_LABEL: "Enter your UON",
      UNIQUE_ID_NAME: "organisationNumber",
      HELP_TEXT:
        "This is the 8-digit number the National Testing Programme sent you by email.",
      LINK_TEXT: "If you do not know your UON, you can search for it",
      LINK_TITLE: " using your existing organisation ID",
      LINK_URL:
        "https://organisation-number-lookup.test-for-coronavirus.service.gov.uk",
      LINK_ARIA_LABEL: "Link will take you to the UON lookup page",
    },
  },
};

export default UONLabels;
