import React, { useContext, useState } from "react";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { GridCol, GridRow, H1, Paragraph } from "govuk-react";
import { FormContext } from "../../../data/contexts/contexts";
import { CheckboxComponent } from "../../Inputs";
import { RequestTranslation } from "../../../service/translation";

const styles = {
  gridMargin: {
    marginTop: "30px",
  },
  checkBoxAlign: {
    "& span": {
      padding: "0 0.5rem",
      marginTop: "-0.2rem",
    },
  },
};

const WeekendTestingFields = ({ classes, orgType, select }) => {
  const [formContext, setFormContext] = useContext(FormContext);
  const [checked, setChecked] = useState(formContext?.orgWeekendTestingCommitment || false);
  const RequestLabels = RequestTranslation(orgType);

  const onChangeValue = () => {
    setChecked(!checked);
    select(!checked);
  };

  const {
    REQUEST_INPUTS: {
      FORM_WEEKEND_TESTING_HEADING,
      FORM_WEEKEND_TESTING_SUB_HEADING,
      FORM_WEEKEND_TESTING: {
        ORG_WEEKEND_TESTING_TEXT,
        ORG_WEEKEND_TESTING_COMMITMENT_LABEL,
        ORG_WEEKEND_TESTING_COMMITMENT,
      },
    },
  } = RequestLabels;

  return (
    <>
      <GridRow className={classes.gridMargin}>
        <GridCol setWidth="two-thirds">
          <header>
            <H1>{FORM_WEEKEND_TESTING_HEADING}</H1>
          </header>
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol setWidth="two-thirds">
          <Paragraph>{`**${FORM_WEEKEND_TESTING_SUB_HEADING}**`}</Paragraph>
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol setWidth="two-thirds">
          <Paragraph>{ORG_WEEKEND_TESTING_TEXT}</Paragraph>
        </GridCol>
      </GridRow>
      <GridRow className={classes.gridMargin}>
        <GridCol onClick={onChangeValue} setWidth="two-thirds">
          <CheckboxComponent
            automatedtestingId="orderform-weekendtestingfields-checkboxcomponent-orgWeekendTestingCommitment"
            className="orderform-weekendtestingfields-checkbox"
            componentChecked={checked}
            label={ORG_WEEKEND_TESTING_COMMITMENT_LABEL}
            name="orgWeekendTestingCommitment"
            text={ORG_WEEKEND_TESTING_COMMITMENT}
          />
        </GridCol>
      </GridRow>
    </>
  );
};

export default withStyles(styles)(WeekendTestingFields);

WeekendTestingFields.defaultProps = {
  select: () => {}
};

WeekendTestingFields.propTypes = {
  classes: PropTypes.shape({
    gridMargin: PropTypes.string,
    checkBoxAlign: PropTypes.string,
  }).isRequired,
  orgType: PropTypes.string.isRequired,
  select: PropTypes.func
};
