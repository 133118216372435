const RequestLabels = {
  ERROR_MESSAGES: {
    DEFAULT: {
      ERROR_MESSAGE_NUMBER_FIELDS: "Enter a valid number, for example 50",
      TOTAL_NUMBER_OF_KITS_ERROR_MESSAGE:
        "The total number of test kits must be greater than zero",
      TOTAL_NUMBER_OF_KITS_BLANK_ERR_MSG: "Enter the total number of test kits",
      TOTAL_NON_STAFF_ERROR_MESSAGE:
        "The total number of non-staff must be greater than zero",
      TOTAL_NON_STAFF_BLANK_ERR_MSG: "Enter number of tests needed",
      TOTAL_NON_STAFF_NOTELIGIBLE_COHORTPOOLING_BLANK_ERR_MSG:
        "Enter the total number of non-staff not suitable for pooled testing",
      TOTAL_NON_STAFF_NOTELIGIBLE_COHORTPOOLING_CANT_EXCEED_NUMBEROF_NONSTAFF_ERR_MSG:
        "Total number of non-staff not suitable for pooled testing cannot be greater than total number of non-staff",
      TOTAL_NUMBER_OF_RETESTKITS_ERROR:
        "Total number of retestkits cannot be greater than the sum of total number of non-staff & total number of staff",
      TOTAL_NUMBER_OF_RETESTKITS_BLANK_ERROR:
        "Enter the total number of unused individual swab test kits",
      EMAIL_ERROR_MESSAGE:
        "Enter an email address in the correct format, like name@example.com",
      EMAIL_ERROR_MESSAGE_CONFIRM:
        "Confirm the email address in the correct format, like name@example.com",
      EMAIL_DUPLICATE_ERROR_MESSAGE:
        "The secondary contact email address cannot be the same as the primary contact email address.",
      STAFF_NON_STAFF_GREATER_THAN_ZERO_ERROR:
        "The total number of tests you order must be more than 0",
      TOTAL_STAFF_BLANK_ERR_MSG: "Enter a number of staff to be tested",
      TOTAL_STAFF_NOTELIGIBLE_COHORTPOOLING_BLANK_ERR_MSG:
        "Enter the total number of staff not suitable for pooled testing",
      TOTAL_STAFF_NOTELIGIBLE_COHORTPOOLING_CANT_EXCEED_NUMBEROF_STAFF_ERR_MSG:
        "Total number of staff not suitable for pooled testing cannot be greater than total number of staff",
      PRIMARY_CONTACT_BLANK_ERR_MSG:
        "Enter the UK telephone number of the primary contact",
      PHONE_NUMBER_INVALID_ERR_MSG:
        "Enter a valid phone number",
      REFERRER_CONFIRMATION_EMAIL_ADDRESS: "Email addresses do not match",
      PRIMARY_CONFIRMATION_EMAIL_ADDRESS: "Email addresses do not match",
      PRIMARY_CONTACT_FIRST_NAME_ERROR_MSG:
        "Enter the first name of the primary contact",
      PRIMARY_CONTACT_LAST_NAME_ERROR_MSG:
        "Enter the last name of the primary contact",
      PRIMARY_CONTACT_FIRST_NAME_ALPHA_ERROR:
        "Enter a valid primary contact first name",
      PRIMARY_CONTACT_LAST_NAME_ALPHA_ERROR:
        "Enter a valid primary contact last name",
      PRIMARY_CONTACT_JOB_ALPHA_ERROR:
        "Enter a valid primary contact job title",
      PRIMARY_ORGANISATION_ALPHA_ERROR:
        "Enter a valid organisation of the primary contact",
      REFERRER_JOB_ALPHA_ERROR: "Enter a valid additional contact job title",
      REFERRER_CONTACT_FIRST_NAME_ALPHA_ERROR:
        "Enter a valid additional contact first name",
      REFERRER_CONTACT_LAST_NAME_ALPHA_ERROR:
        "Enter a valid additional contact last name",
      REFERRER_ORG_ERROR: "Enter a valid additional contact organisation name",
      PRIMARY_JOB_TITLE_ERROR_MSG: "Enter the job title of the primary contact",
      REFERRER_FIRST_NAME_ERROR_MSG:
        "Enter the first name of the additional contact",
      REFERRER_FIRST_NAME_ALPHA_ERROR:
        "Enter a valid first name of the additional contact",
      REFERRER_LAST_NAME_ERROR_MSG:
        "Enter the last name of the additional contact",
      REFERRER_LAST_NAME_ALPHA_ERROR:
        "Enter a valid last name of the additional contact",
      REFERRER_JOB_TITLE_ERROR_MSG:
        "Enter the job title of the additional contact",
      REFERRER_JOB_TITLE_ALPHA_ERROR:
        "Enter a valid job title of the additional contact",
      REFERRER_CONTACT_NUMBER_BLANK:
        "Enter the UK telephone number of the additional contact",
      REFERRER_ORGANISATION_BLANK:
        "Enter the organisation of the additional contact",
      REFERRER_ORGANISATION_ALPHA_ERROR:
        "Enter a valid organisation of the additional contact",
      REFERRER_ERROR_MESSAGE_EMAIL:
        "Enter an email address in the correct format, like name@example.com",
      REFERRER_ERROR_MESSAGE_CONFIRM:
        "Confirm the email address in the correct format, like name@example.com",
      CONTACT_ERR_HEADING: "There is a problem",
      DELEVERY_NOTES_ERROR: "Instructions to prevent a failed delivery must not include ",
      TOTAL_STAFF_ZERO: "The total number of staff in your setting must be more than 0",
      TOTAL_NON_STAFF_ZERO: "The total number of non-staff in your setting must be more than 0",
      SYMPTOMATIC_STAFF_NON_STAFF_ZERO: "Total number must be more than 0",
      TESTING_TOTAL_STAFF_EMTPY_PT: "Enter total number of staff in your setting",
      TESTING_TOTAL_NON_STAFF_EMTPY_PT: "Enter total number of non-staff in your setting",
      TESTING_SYMPTOMATIC_STAFF_NON_STAFF_EMPTY_PT: "Enter a number",
      TESTING_TOTAL_STAFF_LOWER_NUMBER_PT: "Enter a lower number of staff in your setting",
      TESTING_TOTAL_NON_STAFF_LOWER_NUMBER_PT: "Enter a lower number of non-staff in your setting",
      TESTING_SYMPTOMATIC_STAFF_NON_STAFF_LOWER_NUMBER_PT: "Enter a lower number",
    },
    CARE_HOME: {
      TOTAL_NON_STAFF_BLANK_ERR_MSG: "Enter a number of non-staff to be tested",
      TOTAL_NON_STAFF_ERROR_MESSAGE:
        "The total number of non-staff must be greater than zero",
    },
    PRISON: {
      TOTAL_NON_STAFF_BLANK_ERR_MSG: "Enter a number of non-staff to be tested",
      TOTAL_NON_STAFF_ERROR_MESSAGE:
        "The total number of non-staff must be greater than zero",
    },
    DENTIST: {
      TOTAL_NON_STAFF_BLANK_ERR_MSG: "Enter a number of non-staff to be tested",
      TOTAL_NON_STAFF_ERROR_MESSAGE:
        "The total number of non-staff must be greater than zero",
    },
    SCHOOL: {
      TOTAL_NON_STAFF_BLANK_ERR_MSG: "Enter a number of non-staff to be tested",
      TOTAL_NON_STAFF_ERROR_MESSAGE:
        "The total number of non-staff must be greater than zero",
    },
  },
  ORG_DETAILS: {
    DEFAULT: {
      ORG_HEADING: "Check your organisation's address",
      REQ_SUB_HEADING: "", // Calculated field
      ORG_SUB_HEADING_FIRST:
        "These details are taken from your unique organisation number (UON). This is where we'll send your order.",
      ORG_HELP: "If these details need updating, call the helpline: **119** (free from mobiles and landlines)",
      ORG_HELP_COMMUNITY: "If you require any further information please email [TCTSupply@dhsc.gov.uk](mailto:TCTSupply@dhsc.gov.uk) with your enquiry, including the nature of your enquiry in the email subject.",
      ORG_HELP_COMMUNITY_PART_2: "You will receive a response between 9am and 5pm.",
      ORG_HELP_COMMUNITY_PART_3: "Alternatively, please contact your Regional Co-ordinator or Regional Team.",
      ORG_NAME: "Organisation name",
      ORG_STREET: "Street",
      ORG_CITY: "City or town",
      ORG_POSTCODE: "Postcode",
    },
  },
  ORDER_OVERVIEW: {
    DEFAULT: {
      HEADING: "Order tests and supplies",
      INDIVIDUAL_DESCRIPTION:
        "These kits contain a swab and plastic tube to be sent to a lab for testing. Results are given in a few days.",
      GROUP_DESCRIPTION: "For pilot test schemes only.",
      LFT_DESCRIPTION:
        "This is where a swab is tested using a disposable, hand-held device. You'll be shown a result in less than 30 minutes.",
      SUB_HEADING: "", // Calculated field
      SUB_HEADING_FIRST: "",
      OPEN_ORDERS_HEADING: "Open orders you can request a cancellation for",
      OPEN_ORDERS_SUB_HEADING: "Only orders not yet assigned for delivery are shown below.",
      NO_OPEN_ORDERS_TEXT: "You do not have any open orders. If this is wrong, call the contact centre: **119** (free from mobiles and landlines).",
      CANCEL_BUTTON_ENABLED_TEXT: "Cancel order",
      CANCEL_BUTTON_DISABLED_TEXT: "Cancellation request received",
    },
  },
  REQUESTOR_INFO: {
    DEFAULT: {
      REQ_ID_HEADING: "Organisation ID:",
      REQ_SUB_HEADING: "", // Calculated field
      REQ_SUB_HEADING_FIRST:
        "These details are automatically filled in based on your unique organisation number. We'll deliver tests to this address.",
      REQ_SUB_HEADING_SECOND: "Test kits will be delivered to this address.",
      REQ_DESCRIPTION_HEADING: "Delivery address",
      REQ_INFO_HEADING: "Delivery address",
      REQ_FORM_EMAIL_HELP:
        "You'll be sent an email to confirm your order and another when your test kits have been sent.",
      REQ_ORG_NAME: "Organisation name",
      REQ_ORG_STREET: "Street",
      REQ_ORG_CITY: "City",
      REQ_ORG_POSTCODE: "Postcode",
    },
  },
  REQUEST_INPUTS: {
    DEFAULT: {
      REQ_FORM_EMAIL_HELP:
        "You'll be sent an email to confirm your order and another when your test kits have been sent.",
      FORM_TESTING_HEADING: "Number of tests needed",
      FORM_TESTING_SYMPTOMATIC_HEADING: "Number of symptomatic people",
      FORM_TESTING_HEADING_CONFIRMATION: "Confirmation of tests ordered",
      FORM_TESTING_CAPTION_LATERALFLOW: "Lateral flow testing",
      FORM_TESTING_CAPTION_INDIVIDUALTESTING: "Individual testing",
      FORM_TESTING_CAPTION_COHORTPOOLINGTESTING: "Pooled testing",
      FORM_TESTING_SUB_HEADING: "We'll send you some extra tests as spares.",
      FORM_OPPORTUNISTIC_TESTING_SUB_HEADING:
        "We’ll calculate how many tests to send you based on the number(s) below.",
      FORM_INDIVIDUAL_TESTING_SUB_HEADING:
        "We’ll calculate how many tests to send you based on the number(s) below.",
      FORM_GROUP_TESTING_SUB_HEADING:
        "We'll send you some extra tests as spares.",
      FORM_TESTING: {
        TESTING_TOTAL_NUMBER_OF_KITS: "Total number of test kits requested",
        TESTING_TOTAL_NUMBER_OF_KITS_REMAINING:
          "If you have any unused individual swab test kits, enter the number you have left.",
        TESTING_TOTAL_NON_STAFF: "Total number of non-staff to be tested",
        TESTING_TOTAL_NON_STAFF_PT: "Total number of non-staff in your setting",
        TESTING_TOTAL_STAFF_PT: "Total number of staff in your setting",
        TESTING_SYMPTOMATIC_NON_STAFF_PT: "Number of symptomatic non-staff in your setting",
        TESTING_SYMPTOMATIC_STAFF_PT: "Number of symptomatic staff in your setting",
        TESTING_TOTAL_STAFF_HINT_PT: "Include all permanent, temporary and agency staff.",
        TESTING_SYMPTOMATIC_STAFF_HINT_PT: "Include only staff who were symptomatic in your service over the past 6 weeks, and who no longer have a supply of symptomatic test kits.",
        TESTING_SYMPTOMATIC_NON_STAFF_HINT_PT: "Include only service users, pupils or residents who were symptomatic in your service over the past 6 weeks, and who no longer have a supply of test kits.",
        TESTING_TOTAL_NON_STAFF_HINT_PT: "This could include school pupils or residents.",
        TESTING_TOTAL_NON_STAFF_HINT:
          "This could include school pupils or residents.",
        TESTING_TOTAL_STAFF: "Total number of staff to be tested",
        TESTING_TOTAL_STAFF_HINT:
          "Include all permanent, temporary and agency staff.",
        TESTING_TOTAL_STAFF_NOTELIGIBLE_COHORTPOOLING:
          "Total number of staff not suitable for pooled testing",
        TESTING_TOTAL_NON_STAFF_NOTELIGIBLE_COHORTPOOLING:
          "Total number of non-staff not suitable for pooled testing",
        TESTING_TOTAL_STAFF_HELP_TEXT:
          "All staff working at the organisation, including agency workers.",
        TESTING_TOTAL_STAFF_EMTPY_PT: "Enter total number of staff in your setting",
        TESTING_TOTAL_NON_STAFF_EMTPY_PT: "Enter total number of non-staff in your setting",
        TESTING_SYMPTOMATIC_STAFF_NON_STAFF_EMPTY_PT: "Enter a number",
        TESTING_TOTAL_NON_STAFF_PT_ZERO: "The total number of non-staff in your setting must be more than 0",
        TESTING_TOTAL_STAFF_PT_ZERO: "The total number of staff in your setting must be more than 0",
      },
      FORM_WEEKEND_TESTING_HEADING: "Weekend testing commitment",
      FORM_WEEKEND_TESTING_SUB_HEADING:
        "This only applies to organisations that operate at weekends, for example care homes.",
      FORM_WEEKEND_TESTING: {
        ORG_WEEKEND_TESTING_TEXT:
          "If you can, do your testing at the weekend. This spreads our workload across the week and helps us process more kits faster. Weekend testing is any testing done between Friday and Monday, with at least one day of testing on a Saturday or Sunday. However, if your test kits arrive early in the week do not delay testing and get them straight back to us as normal.",
        ORG_WEEKEND_TESTING_COMMITMENT_LABEL: "Weekend testing commitment",
        ORG_WEEKEND_TESTING_COMMITMENT:
          "Tick this box to confirm that you can do your testing over a weekend.",
      },
      FORM_PRIMARY_HEADING: "Manager contact information",
      FORM_PRIMARY_HEADING_COMMUNITY: "Delivery site manager information",
      FORM_PRIMARY_SUB_HEADING:"Enter the contact details of the person who'll be responsible for testing. This will normally be the registered manager. We'll email all delivery details and test instructions to them.",
      FORM_PRIMARY_SUB_HEADING_PART_2:"If someone different will be receiving the delivery of the test kits, add their details to the 'Additional contact' section below.",
      FORM_PRIMARY_SUB_HEADING_COMMUNITY: "Enter the primary contact details of the Delivery Site Manager, the person responsible for receiving the delivery.  We’ll contact this person if there are any issues with the delivery on the day.",
      FORM_PRIMARY_SUB_HEADING_COMMUNITY_PART_2: "In case this person cannot be contacted, please add an ‘Additional Contact’ in the section below.",
      TESTNUMBER_HELP: "If you need help with the above, call the contact centre: **119** (free from mobiles and landlines).",
      TESTNUMBER_HELP_COMMUNITY_ALTERNATIVE: "**Please Note: Orders do not include PPE.** If PPE is required or if you require any further information, please email [TCTSupply@dhsc.gov.uk](mailto:TCTSupply@dhsc.gov.uk) with your enquiry, including the nature of your enquiry in the email subject.",
      TESTNUMBER_HELP_COMMUNITY: "If you require any further information please email [TCTSupply@dhsc.gov.uk](mailto:TCTSupply@dhsc.gov.uk) with your enquiry, including the nature of your enquiry in the email subject.",
      TESTNUMBER_HELP_COMMUNITY_PART_2: "You will receive a response between 9am and 5pm.",
      TESTNUMBER_HELP_COMMUNITY_PART_3: "Alternatively, please contact your Regional Co-ordinator or Regional Team.",
      FORM_PRIMARY: {
        PRIMARY_FIRST_NAME: "First name",
        PRIMARY_LAST_NAME: "Last name",
        PRIMARY_JOB_TITLE: "Job title",
        PRIMARY_CONTACT_NUMBER: "Phone number",
        PRIMARY_EMAIL: "Email address",
        PRIMARY_EMAIL_CONFIRM: "Confirm email address",
        CONTACT_FORM_HEADING:
          "Please enter contact details so the courier can contact you",
        DELIVERY_NOTES:
          "Add instructions to prevent a failed delivery (optional)",
        DELIVERY_NOTES_HINT:
          "Include useful details, such as preferred delivery times, where to leave your order and any parking or vehicle restrictions. You can enter up to 255 characters including spaces.",
        DELIVERY_NOTES_VALUE: "deliveryNotes",
        CONTACT_FIRST_VALUE: "contactFirstName",

        CONTACT_LAST_VALUE: "contactLastName",
        CONTACT_JOB_VALUE: "contactJobTitle",
        CONTACT_ORGANISATION_VALUE: "contactOrganisation",
        CONTACT_EMAIL_VALUE: "contactEmail",
        CONTACT_EMAIL_CONFIRM_VALUE: "contactEmailConfirm",
        CONTACT_PHONE_VALUE: "contactPhoneNumber",
      },
      FORM_REFERRER_HEADING: "Additional contact",
      FORM_REFERRER_SUB_HEADING: "",
      FORM_REFERRER: {
        REFERRER_SELECT:
          "Do you want to add another contact to this order? They will also receive all delivery details and testing instructions.",
        REFERRER_FIRST_NAME: "Additional contact first name",
        REFERRER_LAST_NAME: "Additional contact last name",
        REFERRER_ORGANISATION: "Additional contact organisation (optional)",
        REFERRER_JOB_TITLE: "Additional contact job title",
        REFERRER_CONTACT_NUMBER: "Additional contact phone number",
        REFERRER_EMAIL: "Additional contact email address",
        REFERRER_EMAIL_CONFIRM: "Confirm additional contact email address",

        CONTACT_SECONDARY_VALUE: "secondaryContactFirstName",
        SECONDARY_LAST_VALUE: "secondaryContactLastName",
        SECONDARY_JOB_VALUE: "secondaryContactJobTitle",
        SECONDARY_ORGANISATION_VALUE: "secondaryContactOrganisation",
        SECONDARY_EMAIL_VALUE: "secondaryContactEmail",
        SECONDARY_EMAIL_CONFIRM_VALUE: "secondaryContactEmailConfirm",
        SECONDARY_PHONE_VALUE: "secondaryContactNumber",
      },
      FORM_SUBMIT_BTN_CONTINUE: "Continue",
      FORM_SUBMIT_BTN: "Save and continue",
    },
    CARE_HOME: {
      FORM_TESTING: {
        TESTING_TOTAL_NUMBER_OF_KITS: "Total number of test kits requested",
        TESTING_TOTAL_NUMBER_OF_KITS_REMAINING:
          "If you have any unused individual swab test kits, enter the number you have left",
        TESTING_TOTAL_NON_STAFF: "Total number of non-staff to be tested",
        TESTING_TOTAL_NON_STAFF_PT: "Total number of non-staff in your setting",
        TESTING_TOTAL_STAFF_PT: "Total number of staff in your setting",
        TESTING_SYMPTOMATIC_NON_STAFF_PT: "Number of symptomatic non-staff in your setting",
        TESTING_SYMPTOMATIC_STAFF_PT: "Number of symptomatic staff in your setting",
        TESTING_TOTAL_STAFF_HINT_PT: "Include all permanent, temporary and agency staff.",
        TESTING_SYMPTOMATIC_STAFF_HINT_PT: "Include only staff who were symptomatic in your service over the past 6 weeks, and who no longer have a supply of symptomatic test kits.",
        TESTING_SYMPTOMATIC_NON_STAFF_HINT_PT: "Include only service users, pupils or residents who were symptomatic in your service over the past 6 weeks, and who no longer have a supply of test kits.",
        TESTING_TOTAL_NON_STAFF_HINT_PT: "This could include school pupils or residents.",
        TESTING_TOTAL_NON_STAFF_NOTELIGIBLE_COHORTPOOLING:
          "Total number of non-staff not suitable for pooled testing",
        TESTING_TOTAL_STAFF_NOTELIGIBLE_COHORTPOOLING:
          "Total number of staff not suitable for pooled testing",
        TOTAL_TESTING_REQ_SUBHEADING:
          "Automatically calculated based on resident and staff numbers provided above.",
        TESTING_TOTAL_STAFF: "Total number of staff to be tested",
        TESTING_TOTAL_STAFF_HELP_TEXT:
          "All staff working at the organisation, including agency workers.",
        TESTING_TOTAL_STAFF_EMTPY_PT: "Enter total number of staff in your setting",
        TESTING_TOTAL_NON_STAFF_EMTPY_PT: "Enter total number of non-staff in your setting",
        TESTING_SYMPTOMATIC_STAFF_NON_STAFF_EMPTY_PT: "Enter a number",
      },
    },
    PRISON: {
      FORM_TESTING: {
        TESTING_TOTAL_NUMBER_OF_KITS: "Total number of test kits requested",
        TESTING_TOTAL_NON_STAFF: "Total number of non-staff to be tested",
        TESTING_TOTAL_NON_STAFF_PT: "Total number of non-staff in your setting",
        TESTING_TOTAL_STAFF_PT: "Total number of staff in your setting",
        TESTING_SYMPTOMATIC_NON_STAFF_PT: "Number of symptomatic non-staff in your setting",
        TESTING_SYMPTOMATIC_STAFF_PT: "Number of symptomatic staff in your setting",
        TESTING_TOTAL_STAFF_HINT_PT: "Include all permanent, temporary and agency staff.",
        TESTING_SYMPTOMATIC_STAFF_HINT_PT: "Include only staff who were symptomatic in your service over the past 6 weeks, and who no longer have a supply of symptomatic test kits.",
        TESTING_SYMPTOMATIC_NON_STAFF_HINT_PT: "Include only service users, pupils or residents who were symptomatic in your service over the past 6 weeks, and who no longer have a supply of test kits.",
        TESTING_TOTAL_NON_STAFF_HINT_PT: "This could include school pupils or residents.",
        TESTING_TOTAL_NON_STAFF_NOTELIGIBLE_COHORTPOOLING:
          "Total number of non-staff not suitable for pooled testing",
        TESTING_TOTAL_STAFF_NOTELIGIBLE_COHORTPOOLING:
          "Total number of staff not suitable for pooled testing",
        TOTAL_TESTING_REQ_SUBHEADING:
          "Automatically calculated based on resident and staff numbers provided above.",
        TESTING_TOTAL_STAFF: "Total number of staff to be tested",
        TESTING_TOTAL_STAFF_HELP_TEXT:
          "All staff working at the organisation, including agency workers.",
        TESTING_TOTAL_STAFF_EMTPY_PT: "Enter total number of staff in your setting",
        TESTING_TOTAL_NON_STAFF_EMTPY_PT: "Enter total number of non-staff in your setting",
        TESTING_SYMPTOMATIC_STAFF_NON_STAFF_EMPTY_PT: "Enter a number",
      },
    },
    DENTIST: {
      FORM_TESTING: {
        TESTING_TOTAL_NUMBER_OF_KITS: "Total number of test kits requested",
        TESTING_TOTAL_NON_STAFF: "Total number of non-staff to be tested",
        TESTING_TOTAL_NON_STAFF_PT: "Total number of non-staff in your setting",
        TESTING_TOTAL_STAFF_PT: "Total number of staff in your setting",
        TESTING_SYMPTOMATIC_NON_STAFF_PT: "Number of symptomatic non-staff in your setting",
        TESTING_SYMPTOMATIC_STAFF_PT: "Number of symptomatic staff in your setting",
        TESTING_TOTAL_STAFF_HINT_PT: "Include all permanent, temporary and agency staff.",
        TESTING_SYMPTOMATIC_STAFF_HINT_PT: "Include only staff who were symptomatic in your service over the past 6 weeks, and who no longer have a supply of symptomatic test kits.",
        TESTING_SYMPTOMATIC_NON_STAFF_HINT_PT: "Include only service users, pupils or residents who were symptomatic in your service over the past 6 weeks, and who no longer have a supply of test kits.",
        TESTING_TOTAL_NON_STAFF_HINT_PT: "This could include school pupils or residents.",
        TESTING_TOTAL_NON_STAFF_NOTELIGIBLE_COHORTPOOLING:
          "Number of patients who are not suitable for pooled testing",
        TESTING_TOTAL_STAFF_NOTELIGBILE_COHORTPOOLING:
          "Total number of staff not suitable for pooled testing",
        TOTAL_TESTING_REQ_SUBHEADING:
          "Automatically calculated based on patient and staff numbers provided above.",
        TESTING_TOTAL_STAFF: "Total number of staff to be tested",
        TESTING_TOTAL_STAFF_HELP_TEXT:
          "All staff working at the organisation, including agency workers.",
        TESTING_TOTAL_STAFF_EMTPY_PT: "Enter total number of staff in your setting",
        TESTING_TOTAL_NON_STAFF_EMTPY_PT: "Enter total number of non-staff in your setting",
        TESTING_SYMPTOMATIC_STAFF_NON_STAFF_EMPTY_PT: "Enter a number",
      },
    },
    SCHOOL: {
      FORM_TESTING: {
        TESTING_TOTAL_NUMBER_OF_KITS: "Total number of test kits requested",
        TESTING_TOTAL_NON_STAFF: "Total number of non-staff to be tested",
        TESTING_TOTAL_NON_STAFF_PT: "Total number of non-staff in your setting",
        TESTING_TOTAL_STAFF_PT: "Total number of staff in your setting",
        TESTING_SYMPTOMATIC_NON_STAFF_PT: "Number of symptomatic non-staff in your setting",
        TESTING_SYMPTOMATIC_STAFF_PT: "Number of symptomatic staff in your setting",
        TESTING_TOTAL_STAFF_HINT_PT: "Include all permanent, temporary and agency staff.",
        TESTING_SYMPTOMATIC_STAFF_HINT_PT: "Include only staff who were symptomatic in your service over the past 6 weeks, and who no longer have a supply of symptomatic test kits.",
        TESTING_SYMPTOMATIC_NON_STAFF_HINT_PT: "Include only service users, pupils or residents who were symptomatic in your service over the past 6 weeks, and who no longer have a supply of test kits.",
        TESTING_TOTAL_NON_STAFF_HINT_PT: "This could include school pupils or residents.",
        TESTING_TOTAL_NON_STAFF_NOTELIGIBLE_COHORTPOOLING:
          "Number of students who are not suitable for pooled testing",
        TESTING_TOTAL_STAFF_NOTELIGBILE_COHORTPOOLING:
          "Total number of staff not suitable for pooled testing",
        TOTAL_TESTING_REQ_SUBHEADING:
          "Automatically calculated based on student and staff numbers provided above.",
        TESTING_TOTAL_STAFF: "Total number of staff to be tested",
        TESTING_TOTAL_STAFF_HELP_TEXT:
          "All staff working at the organisation, including agency workers.",
        TESTING_TOTAL_STAFF_EMTPY_PT: "Enter total number of staff in your setting",
        TESTING_TOTAL_NON_STAFF_EMTPY_PT: "Enter total number of non-staff in your setting",
        TESTING_SYMPTOMATIC_STAFF_NON_STAFF_EMPTY_PT: "Enter a number",
      },
    },
    GP: {
      FORM_TESTING_SUB_HEADING:
        "Please enter the number of test kits required for one week of testing. GP surgeries are able to request up to 40 test kits per week by default, but some sites with higher demand may request permission to order up to 200 tests per week. If you are regularly using more than 40 test kits per week, please call 119 to request more kits.",
      FORM_OPPORTUNISTIC_TESTING_SUB_HEADING:
        "We'll calculate how many tests to send you based on the number(s) below.",
    },
  },
  MULTIPLE_ADDRESS: {
    MULTIPLEADDRESS_HELP: "If you need to add another address, call the contact centre: **119** (free from mobiles and landlines).",
    MULTIPLEADDRESS_HELP_COMMUNITY: "If you require any further information please email [TCTSupply@dhsc.gov.uk](mailto:TCTSupply@dhsc.gov.uk) with your enquiry, including the nature of your enquiry in the email subject.",
    MULTIPLEADDRESS_HELP_COMMUNITY_PART_2: "You will receive a response between 9am and 5pm.",
    MULTIPLEADDRESS_HELP_COMMUNITY_PART_3: "Alternatively, please contact your Regional Co-ordinator or Regional Team.",
  },
};

export default RequestLabels;
