/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useHistory, withRouter } from "react-router-dom";
import withStyles from "react-jss";
import { Button, Caption, GridCol, GridRow, H1, H2, LabelText, Link, LoadingBox, Main, SectionBreak } from "govuk-react";
import { ErrorSummary } from "@cube/global-components";
import PropTypes from "prop-types";
import { ErrorLabels, ConfirmChoicesLabels } from "../../data/constants";
import DataAccessService from "../../service/httpUtility";
import RequestTranslation from "../../service/translation/requestTranslation.service";
import { useAnalytics } from "../../service/adobe-analytics";
import { FormContext } from "../../data/contexts/contexts";
import ReCAPTCHA from "react-google-recaptcha";

const { FAILURE_ERR_MSG, FAILURE_ERR_MSG_TWO } = ErrorLabels;
const { TOTAL_STAFF_LABEL_PT, TOTAL_NON_STAFF_LABEL_PT, SYMPTOMATIC_STAFF_LABEL_PT, SYMPTOMATIC_NON_STAFF_LABEL_PT } = ConfirmChoicesLabels;
 
const styles = {
	headingRow: {
		marginBottom: "30px",
	},
	buttonRow: {
		marginTop: "50px",
	},
	primaryLabel: {
		color: "#000000",
		fontWeight: "bold",
	},
	w75p: {
		width: "75%",
	},
	sectionBreakMargin: {
		margin: "0.5rem 0",
	},
	inlineBlockDivider: {
		display: "inline-block",
	},
	paddingTop025: {
		paddingTop: "0.25rem",
	},
	lineBreak: {
		whiteSpace: "pre-line",
	},
	actionPadding: {
		paddingTop: "8px",
	},
	zeroPadding: {
		padding: 0,
	},
	fakeSize: {
		width: "auto",
	},
	breakWord: {
		wordBreak: "break-all",
	},
	"@media (min-width: 1020px)": {
		fakeSize: {
			width: "960px",
		},
	},
	leftAlign: {
		textAlign: "left",
	},
	rightAlign: {
		paddingLeft: "105px",
	},
};

const RECAPTCHATHRESHOLD = 0.6;
const RECAPTCHAACTION = "orderform_cya_homepage";

const ConfirmChoices = ({ classes }) => {
	const [formContext, setFormContext] = useContext(FormContext);
	const [errMessage, setErrorMsg] = useState("");
	const [loader, setLoader] = useState(false);
	const [token, setToken] = useState([]);
	const [v3Success, setV3Success] = useState(true);
	const [v2Checked, setV2Checked] = useState(false);
	const [showRecaptcha, setShowRecaptcha] = useState(false);
	const [errorArray, setErrorArray] = useState(null);
	const history = useHistory();
	const windowAllowed = localStorage.getItem("answer");
	const { triggerEvent } = useAnalytics();
	const type = formContext?.orderTitle;
	if (windowAllowed !== null) {
		const analyticsData = {
			page: {
				pageInfo: {
					pageName: "nhs:test:organisation-order:confirm-choices",
				},
				category: {
					primaryCategory: "organisation-order",
					subCategory1: "confirm-choices",
				},
			},
		};
		triggerEvent(analyticsData);
	} else {
		window.digitalData = null;
	}
	const url = window.location.href;
	const captchaKeyV2 = process.env.NODE_ENV === "production" ? process.env.REACT_APP_GOOGLE_CAPTCHA_PROD : process.env.REACT_APP_GOOGLE_CAPTCHA_LOCAL;
	const captchaKeyV3 = url.includes("delsit")
		? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_SIT
		: url.includes("delqa")
		? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_QA
		: url.includes("e2e")
		? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_E2E
		: url.includes("preprod")
		? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_PREPROD
		: url.includes("ci-bulk")
		? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_CI
		: url.includes("deldev")
		? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_LOCAL_DEV
		: process.env.NODE_ENV === "production"
		? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_PROD
		: process.env.REACT_APP_GOOGLE_CAPTCHA_V3_LOCAL_DEV;
	const captchaRefV3 = React.createRef();
	const captchaRefV2 = React.createRef();

	const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

	const isNotProduction = (u) => {
		if (window.location.hostname === "localhost" || u.includes("delsit") || u.includes("delqa") || u.includes("deldev") || u.includes("preprod")) {
			return true;
		}
		return false;
	};

	isNotProduction(url) && console.info("Recaptcha V3 sitekey", captchaKeyV3);

	const contactDetails = () => {
		let retVal = "";
		if (formContext.contactName) {
			retVal += `${formContext.contactName}`;
		}
		if (formContext.contactJobTitle) {
			retVal += `\n${formContext.contactJobTitle}`;
		}
		if (formContext.contactOrganisation) {
			retVal += `\n${formContext.contactOrganisation}`;
		}
		if (formContext.contactPhoneNumber) {
			retVal += `\n${formContext.contactPhoneNumber}`;
		}
		if (formContext.contactEmail) {
			retVal += `\n${formContext.contactEmail}`;
		}
		return retVal;
	};
	const additionalContactDetails = () => {
		let retVal = "";
		if (formContext.secondaryContactName) {
			retVal += `${formContext.secondaryContactName}`;
		}
		if (formContext.secondaryContactJobTitle) {
			retVal += `\n${formContext.secondaryContactJobTitle}`;
		}
		if (formContext.secondaryContactOrganisation) {
			retVal += `\n${formContext.secondaryContactOrganisation}`;
		}
		if (formContext.secondaryContactNumber) {
			retVal += `\n${formContext.secondaryContactNumber}`;
		}
		if (formContext.secondaryContactEmail) {
			retVal += `\n${formContext.secondaryContactEmail}`;
		}
		return retVal;
	};
	const realAddress = formContext?.multipleaddress ? formContext?.multipleaddress : formContext?.crqData?.Addresses;
	const address = realAddress?.length < 2 ? realAddress[0] : realAddress?.find((a) => a.addressId === formContext.orderAddressId);
	const deliveryAddress = (address && `${address.addressStreet},\n${address.addressCity},\n${address.addressPostcode},\n${address.addressCountry}`) || "";
	const weekendTesting = formContext?.orgWeekendTestingCommitment;
	const staffTesting = formContext?.totalNumberOfStaff;
	const nonStaffTesting = formContext?.totalNumberOfResidents;
	const multipleaddresslength = formContext?.crqData?.Addresses.length;

	const testingApproach = () => formContext?.testingApproach;

	// allowed order types for redirect to summary page
	const allowedOrderTypesForRedirect = [
		"Rapid lateral flow tests",
		"Rapid lateral flow tests for use on-site",
		"Rapid lateral flow tests for home testing",
		"Rapid lateral flow tests for daily contact testing",
		"Rapid lateral flow tests for staff",
		"Rapid lateral flow tests for non-staff",
	];

	const {
		REQUEST_INPUTS: { FORM_WEEKEND_TESTING_HEADING, FORM_PRIMARY_HEADING, FORM_PRIMARY_HEADING_COMMUNITY },
	} = RequestTranslation();

	// #9880 This is the execution of reCaptcha v3;
	// https://developers.google.com/recaptcha/docs/v3
	useEffect(() => {
		if (!showRecaptcha) return;
		window.grecaptcha.enterprise.ready(async (_) => {
			window.grecaptcha.enterprise
				.execute(captchaKeyV3, {
					action: RECAPTCHAACTION,
				})
				.then(async (t) => {
					// IMPORTANT: The 'token' that results from execute is an encrypted response sent by
					// reCAPTCHA Enterprise to the end user's browser. This token must be validated by
					// creating an assessment;
					// See https://cloud.google.com/recaptcha-enterprise/docs/create-assessment
					setToken(t);

					isNotProduction(url) && console.info("Recaptcha V3 token", t);

					const verifyData = {
						token: t,
						action: RECAPTCHAACTION,
						sitekey: captchaKeyV3,
					};

					// This is the call to the SF backend to verify that recaptcha V3 has been
					// successful or failed. Either way we set the hook so it can be used in
					// the display of the reCaptcha V2 and/or the submit button check;
					DataAccessService.checkGRFactor(verifyData)
						.then((result) => {
							const scoreVal = result.split("score=")[1].split("}")[0];
							if (parseFloat(scoreVal, 10) < RECAPTCHATHRESHOLD || parseFloat(scoreVal, 10) === 1) {
								// if ((res.score && res.score < RECAPTCHATHRESHOLD) || res.score === 1) {
								setV3Success(false);
								isNotProduction(url) && console.info("Recaptcha V3 scoring failed, generating reCaptcha V2");
							} else {
								setV3Success(true);

								isNotProduction(url) && console.info("Recaptcha V3 scoring succeeded:", result);
							}
						})
						.catch((e) => {
							setV3Success(false);
							isNotProduction(url) && console.info("Recaptcha V3 scoring failed, generating reCaptcha V2");
						});
				});
		});
		return () => {
			setShowRecaptcha(false);
		};
	}, [showRecaptcha]);

	useEffect(() => {
		// Add reCaptcha
		const script = document.createElement("script");
		script.src = `https://www.google.com/recaptcha/enterprise.js?render=${captchaKeyV3}`;
		script.addEventListener("load", () => setShowRecaptcha(true));
		document.body.appendChild(script);
	}, []);

	const checked = () => {
		return process.env.REACT_APP_RECAPTCHA_NEEDED === "1" ? (v3Success ? v3Success : v2Checked) : true;
	};

	const confirm = (values) => {
		function trimStringFields(json) {
			for (let key in json) {
				if (typeof json[key] === "string") {
					json[key] = json[key].trim();
				} else if (typeof json[key] === "object") {
					trimStringFields(json[key]); // Recursively call the function for nested objects or arrays
				}
			}

			return json;
		}

		let finalValueToSubmit = { ...values };

		setLoader(true);

		finalValueToSubmit = {
			...formContext,
			orgWeekendTestingCommitment: formContext?.orgWeekendTestingCommitment || false, // Defaulting to false if not set
			cqcRegistrationID: formContext.crqId,
			contactName: `${formContext.contactFirstName.trim()} ${formContext.contactLastName.trim()}`,
			secondaryContactSource: formContext.secondaryContactSource === true ? "yes" : "no",
			secondaryContactName: `${formContext.secondaryContactFirstName && formContext.secondaryContactFirstName.trim()} ${formContext.secondaryContactLastName && formContext.secondaryContactLastName.trim()}`,
		};

		finalValueToSubmit = trimStringFields(finalValueToSubmit);

		if (token) {
			if (checked()) {
				DataAccessService.sendRequestForm({
					...finalValueToSubmit,
					createOrder: true,
				})
					.then(() => {
						setFormContext({ ...formContext, orderSubmitted: true });
						history.push({
							pathname: "/submission",
							state: {
								email: finalValueToSubmit.contactEmail,
								secondaryContactEmail: finalValueToSubmit.secondaryContactEmail,
								secondaryContactSource: finalValueToSubmit.secondaryContactSource,
								oun: finalValueToSubmit.crqId,
							},
						});
					})
					.catch((e) => {
						const errorMsg = e.response ? e.response.data.errMessage || FAILURE_ERR_MSG_TWO : FAILURE_ERR_MSG_TWO;
						setErrorMsg(errorMsg);
						setLoader(false);
					});
			} else {
				setLoader(false);
				setErrorArray([
					{
						targetName: "urn2",
						text: "Please try again later. If you continue to experience difficulties registering please call the helpline on 0300 303 2713.",
					},
				]);

				setErrorMsg("Check recaptcha");

				isNotProduction(url) && console.info("Check your answer onboarding failed because of V3 failure and V2 not checked");
			}
		}
	};

	const backToSummary = () => {
		// if testing approach is Population/Opportunistic Testing and order type is present in our array, send the fromSummary state as true
		return (testingApproach() === "Population Testing" || testingApproach() === "Community Testing" || testingApproach() === "Bulk Testing" || testingApproach() === "Opportunistic Testing") && allowedOrderTypesForRedirect.indexOf(type) !== -1;
	};

	const redirectToSummary = (testingType) => {
		return testingType === "Cohort Pooling" || testingType === "Individual Testing" || testingType === "Lateral Flow Testing";
	};

	const submitV2 = (t) => setV2Checked(!v2Checked);

	const formatDeliveryDate = () => {
		if (formContext.deliveryDate && formContext.deliveryDate?.day && formContext.deliveryDate?.month && formContext.deliveryDate?.year) {
			const day = formContext.deliveryDate.day.length === 1 ? `0${formContext.deliveryDate.day}` : formContext.deliveryDate.day;
			const month = monthNames[formContext.deliveryDate.month - 1];
			return `${day} ${month} ${formContext.deliveryDate.year}`;
		}
		return false;
	};

	const displayDeliveryInstructions = () => {
		return (
			<>
				<GridRow>
					<GridCol>
						<br />
						<br />
						<br />
						<H2>Delivery instructions</H2>
					</GridCol>
				</GridRow>

				{formContext?.orderOpenHour && formContext?.orderOpenMinute && (
					<>
						<GridRow>
							<GridCol setWidth="25%">
								<Caption size="L" className={classes.primaryLabel}>
									Site open
								</Caption>
							</GridCol>
							<GridCol setWidth="one-third">
								<LabelText className={[classes.paddingTop025, classes.lineBreak]} data-automatedtestingid="cya-delivery-instructions-site-open">
									{formContext?.orderOpenHour}:{formContext?.orderOpenMinute}
								</LabelText>
							</GridCol>
							<GridCol setWidth="one-third" className={[classes.actionPadding, classes.rightAlign]}>
								<Link
									as={RouterLink}
									data-automatedtestingid="cya-delivery-instructions-site-open-link"
									to={{
										pathname: "./delivery-instructions",
										state: {
											fromSummary: backToSummary(),
										},
									}}
									aria-label="Link, change site open"
								>
									<span aria-hidden="true">Change</span>
								</Link>
							</GridCol>
						</GridRow>

						<SectionBreak className={[classes.w75p, classes.sectionBreakMargin, classes.leftAlign]} level="SMALL" visible />
					</>
				)}

				{formContext?.orderCloseHour && formContext?.orderCloseMinute && (
					<>
						<GridRow>
							<GridCol setWidth="25%">
								<Caption size="L" className={classes.primaryLabel}>
									Site closed
								</Caption>
							</GridCol>
							<GridCol setWidth="one-third">
								<LabelText className={[classes.paddingTop025, classes.lineBreak]} data-automatedtestingid="cya-delivery-instructions-site-closed">
									{formContext?.orderCloseHour}:{formContext?.orderCloseMinute}
								</LabelText>
							</GridCol>
							<GridCol setWidth="one-third" className={[classes.actionPadding, classes.rightAlign]}>
								<Link
									as={RouterLink}
									data-automatedtestingid="cya-delivery-instructions-site-closed-link"
									to={{
										pathname: "./delivery-instructions",
										state: {
											fromSummary: backToSummary(),
										},
									}}
									aria-label="Link, change site closed"
								>
									<span aria-hidden="true">Change</span>
								</Link>
							</GridCol>
						</GridRow>

						<SectionBreak className={[classes.w75p, classes.sectionBreakMargin, classes.leftAlign]} level="SMALL" visible />
					</>
				)}

				{formContext?.selectedOpenDays && formContext?.selectedOpenDays?.length > 0 && (
					<>
						<GridRow>
							<GridCol setWidth="25%">
								<Caption size="L" className={classes.primaryLabel}>
									Days site is open
								</Caption>
							</GridCol>
							<GridCol setWidth="one-third">
								<LabelText className={[classes.paddingTop025, classes.lineBreak]} data-automatedtestingid="cya-delivery-instructions-days-site-open">
									{formContext?.selectedOpenDays?.join(", ")}
								</LabelText>
							</GridCol>
							<GridCol setWidth="one-third" className={[classes.actionPadding, classes.rightAlign]}>
								<Link
									as={RouterLink}
									data-automatedtestingid="cya-delivery-instructions-days-site-open-link"
									to={{
										pathname: "./delivery-instructions",
										state: {
											fromSummary: backToSummary(),
										},
									}}
									aria-label="Link, change days site is open"
								>
									<span aria-hidden="true">Change</span>
								</Link>
							</GridCol>
						</GridRow>

						<SectionBreak className={[classes.w75p, classes.sectionBreakMargin, classes.leftAlign]} level="SMALL" visible />
					</>
				)}

				{formContext?.selectedOffloadFacility && (
					<>
						<GridRow>
							<GridCol setWidth="25%">
								<Caption size="L" className={classes.primaryLabel}>
									Offload facilities
								</Caption>
							</GridCol>
							<GridCol setWidth="one-third">
								<LabelText className={[classes.paddingTop025, classes.lineBreak]} data-automatedtestingid="cya-delivery-instructions-offload">
									{formContext?.selectedOffloadFacility}
								</LabelText>
							</GridCol>
							<GridCol setWidth="one-third" className={[classes.actionPadding, classes.rightAlign]}>
								<Link
									as={RouterLink}
									data-automatedtestingid="cya-delivery-instructions-offload-link"
									to={{
										pathname: "./delivery-instructions",
										state: {
											fromSummary: backToSummary(),
										},
									}}
									aria-label="Link, change offload facilities"
								>
									<span aria-hidden="true">Change</span>
								</Link>
							</GridCol>
						</GridRow>

						<SectionBreak className={[classes.w75p, classes.sectionBreakMargin, classes.leftAlign]} level="SMALL" visible />
					</>
				)}

				{formContext?.selectedVehicleRestrictions && formContext?.selectedVehicleRestrictions?.length > 0 && (
					<>
						<GridRow>
							<GridCol setWidth="25%">
								<Caption size="L" className={classes.primaryLabel}>
									Vehicle restrictions
								</Caption>
							</GridCol>
							<GridCol setWidth="one-third">
								<LabelText className={[classes.paddingTop025, classes.lineBreak]} data-automatedtestingid="cya-delivery-instructions-vehicle-restrictions">
									{formContext?.selectedVehicleRestrictions?.join(", ")}
								</LabelText>
							</GridCol>
							<GridCol setWidth="one-third" className={[classes.actionPadding, classes.rightAlign]}>
								<Link
									as={RouterLink}
									data-automatedtestingid="cya-delivery-instructions-vehicle-restrictions-link"
									to={{
										pathname: "./delivery-instructions",
										state: {
											fromSummary: backToSummary(),
										},
									}}
									aria-label="Link, change vehicle restrictions"
								>
									<span aria-hidden="true">Change</span>
								</Link>
							</GridCol>
						</GridRow>

						<SectionBreak className={[classes.w75p, classes.sectionBreakMargin, classes.leftAlign]} level="SMALL" visible />
					</>
				)}

				<>
					<GridRow>
						<GridCol setWidth="25%">
							<Caption size="L" className={classes.primaryLabel}>
								Other information
							</Caption>
						</GridCol>
						<GridCol setWidth="one-third">
							<LabelText className={[classes.paddingTop025, classes.lineBreak]} data-automatedtestingid="cya-delivery-instructions-uv-restriction">
								{formContext?.uvRestrictionInformation}
							</LabelText>
						</GridCol>
						<GridCol setWidth="one-third" className={[classes.actionPadding, classes.rightAlign]}>
							<Link
								as={RouterLink}
								data-automatedtestingid="cya-delivery-instructions-uv-restriction-link"
								to={{
									pathname: "./delivery-instructions",
									state: {
										fromSummary: backToSummary(),
									},
								}}
								aria-label="Link, change other information"
							>
								<span aria-hidden="true">Change</span>
							</Link>
						</GridCol>
					</GridRow>

					<SectionBreak className={[classes.w75p, classes.sectionBreakMargin, classes.leftAlign]} level="SMALL" visible />
				</>

				{formatDeliveryDate() && (
					<>
						<GridRow>
							<GridCol setWidth="25%">
								<Caption size="L" className={classes.primaryLabel}>
									Suggested delivery date
								</Caption>
							</GridCol>
							<GridCol setWidth="one-third">
								<LabelText className={[classes.paddingTop025, classes.lineBreak]} data-automatedtestingid="cya-delivery-instructions-date">
									{formatDeliveryDate()}
								</LabelText>
							</GridCol>
							<GridCol setWidth="one-third" className={[classes.actionPadding, classes.rightAlign]}>
								<Link
									as={RouterLink}
									data-automatedtestingid="cya-delivery-instructions-date-link"
									to={{
										pathname: "./delivery-instructions",
										state: {
											fromSummary: backToSummary(),
										},
									}}
									aria-label="Link, change suggested delivery date"
								>
									<span aria-hidden="true">Change</span>
								</Link>
							</GridCol>
						</GridRow>

						<SectionBreak className={[classes.w75p, classes.sectionBreakMargin, classes.leftAlign]} level="SMALL" visible />
					</>
				)}
			</>
		);
	};

	const isSymptomaticRegime = () => {
		return formContext?.testingRegime === "Symptomatic Testing On-Site" || formContext?.testingRegime === "Symptomatic Testing Self-Test";
	};

	const displayStaffLabel = () => (isSymptomaticRegime() ? SYMPTOMATIC_STAFF_LABEL_PT : TOTAL_STAFF_LABEL_PT);

	const displayNonStaffLabel = () => (isSymptomaticRegime() ? SYMPTOMATIC_NON_STAFF_LABEL_PT : TOTAL_NON_STAFF_LABEL_PT);

	return (
		<LoadingBox loading={loader} backgroundColor="#fff" timeIn={100} timeOut={100} backgroundColorOpacity={0.85} spinnerColor="#000">
			{errMessage && (
				<GridRow>
					<GridCol setWidth="two-thirds">
						<ErrorSummary automationId="orderform-error-box" heading="There is a problem" description={errMessage} errors={[]} errorMessage={errMessage} />
					</GridCol>
				</GridRow>
			)}
			<Main className={classes.fakeSize}>
				<GridRow className={classes.headingRow}>
					<GridCol setWidth="two-thirds">
						<header>
							<H1>Check your answers</H1>
						</header>
					</GridCol>
				</GridRow>

				<GridRow>
					<GridCol className={classes.headingRow} setWidth="25%">
						<Caption size="L" className={classes.primaryLabel}>
							Order type
						</Caption>
					</GridCol>
					<GridCol className={classes.headingRow} setWidth="one-third">
						<LabelText className={[classes.paddingTop025, classes.lineBreak]} data-automatedtestingid="orderform-order-type">
							{formContext?.orderTitle}
						</LabelText>
					</GridCol>
					<GridCol setWidth="one-third" className={[classes.actionPadding, classes.rightAlign]}>
						<Link aria-label="Link, change ordertype" as={RouterLink} data-automatedtestingid="orderform-order-type-link" to="./overview">
							Change
						</Link>
					</GridCol>
				</GridRow>

				<SectionBreak className={[classes.w75p, classes.sectionBreakMargin, classes.inlineBlockDivider]} level="SMALL" visible />

				{/* Delivery address */}
				<GridRow>
					<GridCol setWidth="25%">
						<Caption size="L" className={classes.primaryLabel}>
							Delivery address
						</Caption>
					</GridCol>
					<GridCol setWidth="one-third">
						<LabelText className={[classes.paddingTop025, classes.lineBreak]} data-automatedtestingid="orderform-delivery-address">
							{deliveryAddress}
						</LabelText>
					</GridCol>
					<GridCol setWidth="one-third" className={[classes.actionPadding, classes.rightAlign]}>
						{multipleaddresslength > 1 && (
							<Link
								aria-label="Link, change delivery address"
								as={RouterLink}
								data-automatedtestingid="orderform-delivery-address-link"
								to={{
									pathname: "./address",
									state: {
										fromSummary: type === "Standard swab tests" || type === "Swab tests (PCR tests) to return by priority post box" || type === "Outer return boxes for swab tests" || backToSummary(),
									},
								}}
							>
								Change
							</Link>
						)}
					</GridCol>
				</GridRow>

				<SectionBreak className={[classes.w75p, classes.sectionBreakMargin, classes.inlineBlockDivider]} level="SMALL" visible />

				{/* Number needed */}
				{(testingApproach() === "Opportunistic Testing" || testingApproach() === "Community Testing" || testingApproach() === "Bulk Testing" || testingApproach() === "Return Boxes") && (
					<>
						<GridRow>
							<GridCol className={classes.headingRow} setWidth="25%">
								<Caption size="L" className={classes.primaryLabel}>
									{testingApproach() === "Bulk Testing" ? <>Number of cartons needed</> : testingApproach() === "Community Testing" ? <>Number of pallets needed</> : <>Number needed</>}
								</Caption>
							</GridCol>
							<GridCol className={classes.headingRow} setWidth="one-third">
								<LabelText className={classes.paddingTop025} data-automatedtestingid="orderform-test-numbers">
									{formContext?.totalStaff}
								</LabelText>
							</GridCol>
							<GridCol setWidth="one-third" className={[classes.actionPadding, classes.rightAlign]}>
								<Link
									aria-label="Link, change number needed"
									as={RouterLink}
									data-automatedtestingid="orderform-test-numbers-link"
									to={{
										pathname: "./test-numbers",
										state: {
											fromSummary: redirectToSummary(formContext?.testType),
										},
									}}
								>
									Change
								</Link>
							</GridCol>
						</GridRow>

						<SectionBreak className={[classes.w75p, classes.sectionBreakMargin, classes.inlineBlockDivider]} level="SMALL" visible />
					</>
				)}

				{/* Total number of staff in your setting */}
				{testingApproach() === "Population Testing" && formContext?.staffMultiplier === true && (
					<>
						<GridRow>
							<GridCol setWidth="25%">
								<Caption size="L" className={classes.primaryLabel}>
									{displayStaffLabel()}
								</Caption>
							</GridCol>
							<GridCol setWidth="one-third">
								<LabelText className={classes.paddingTop025} data-automatedtestingid="orderform-total-staff">
									{staffTesting}
								</LabelText>
							</GridCol>
							<GridCol setWidth="one-third" className={[classes.actionPadding, classes.rightAlign]}>
								<Link
									aria-label={`Link, change ${displayStaffLabel()}`}
									as={RouterLink}
									data-automatedtestingid="orderform-total-staff-link"
									to={{
										pathname: "./test-numbers",
										state: {
											fromSummary: redirectToSummary(formContext?.testType),
										},
									}}
								>
									Change
								</Link>
							</GridCol>
						</GridRow>

						<SectionBreak className={[classes.w75p, classes.sectionBreakMargin, classes.inlineBlockDivider]} level="SMALL" visible />
					</>
				)}

				{/* Total number of non-staff in your setting */}
				{testingApproach() === "Population Testing" && formContext?.nonStaffMultiplier === true && (
					<>
						<GridRow>
							<GridCol setWidth="25%">
								<Caption size="L" className={classes.primaryLabel}>
									{displayNonStaffLabel()}
								</Caption>
							</GridCol>
							<GridCol setWidth="one-third">
								<LabelText className={classes.paddingTop025} data-automatedtestingid="orderform-total-non-staff">
									{nonStaffTesting}
								</LabelText>
							</GridCol>
							<GridCol setWidth="one-third" className={[classes.actionPadding, classes.rightAlign]}>
								<Link
									aria-label={`Link, change ${displayNonStaffLabel()}`}
									as={RouterLink}
									data-automatedtestingid="orderform-total-non-staff-link"
									to={{
										pathname: "./test-numbers",
										state: {
											fromSummary: redirectToSummary(formContext?.testType),
										},
									}}
								>
									Change
								</Link>
							</GridCol>
						</GridRow>

						<SectionBreak className={[classes.w75p, classes.sectionBreakMargin, classes.inlineBlockDivider]} level="SMALL" visible />
					</>
				)}

				{/* Weekend testing */}
				{formContext?.showOrderFormForTestingType === "Lateral Flow Testing" ||
					((testingApproach() === "Opportunistic Testing" || testingApproach() === "Population Testing") && formContext?.showOrderFormForTestingType !== "Return Boxes" && (
						<>
							<GridRow>
								<GridCol setWidth="25%">
									<Caption size="L" className={classes.primaryLabel}>
										{FORM_WEEKEND_TESTING_HEADING}
									</Caption>
								</GridCol>
								<GridCol setWidth="one-third">
									<LabelText className={[classes.paddingTop025, classes.lineBreak]} data-automatedtestingid="orderform-weekend-testing">
										{(weekendTesting === true && "Yes") || "No"}
									</LabelText>
								</GridCol>
								<GridCol setWidth="one-third" className={[classes.actionPadding, classes.rightAlign]}>
									<Link
										aria-label="Link, change weekend testing commitment"
										as={RouterLink}
										data-automatedtestingid="orderform-weekend-testing-link"
										to={{
											pathname: "./weekendtesting",
											state: {
												fromSummary: type === "Standard swab tests" || type === "Swab tests (PCR tests) to return by priority post box" || backToSummary(),
											},
										}}
									>
										Change
									</Link>
								</GridCol>
							</GridRow>
							<SectionBreak className={[classes.w75p, classes.sectionBreakMargin, classes.inlineBlockDivider]} level="SMALL" visible />
						</>
					))}

				{/* Manager contact information */}
				<GridRow>
					<GridCol setWidth="25%">
						<Caption size="L" className={classes.primaryLabel}>
							{formContext.testingApproach !== "Community Testing" ? FORM_PRIMARY_HEADING : FORM_PRIMARY_HEADING_COMMUNITY}
						</Caption>
					</GridCol>
					<GridCol setWidth="one-third">
						<LabelText className={[classes.paddingTop025, classes.breakWord, classes.lineBreak]} data-automatedtestingid="orderform-manager-contact-information">
							{contactDetails()}
						</LabelText>
					</GridCol>
					<GridCol setWidth="one-third" className={[classes.actionPadding, classes.rightAlign]}>
						<Link
							aria-label="Link, change manager contact information"
							as={RouterLink}
							data-automatedtestingid="orderform-manager-contact-information-link"
							to={{
								pathname: "./contact",
								state: {
									fromSummary: type === "Standard swab tests" || type === "Swab tests (PCR tests) to return by priority post box" || type === "Outer return boxes for swab tests" || backToSummary(),
								},
							}}
						>
							Change
						</Link>
					</GridCol>
				</GridRow>

				<SectionBreak className={[classes.w75p, classes.sectionBreakMargin, classes.inlineBlockDivider]} level="SMALL" visible />

				{/* Additional contact information */}
				{formContext?.secondaryContactSource && additionalContactDetails && (
					<>
						<GridRow>
							<GridCol setWidth="25%">
								<Caption size="L" className={classes.primaryLabel}>
									Additional contact information
								</Caption>
							</GridCol>
							<GridCol setWidth="one-third">
								<LabelText className={[classes.paddingTop025, classes.breakWord, classes.lineBreak]} data-automatedtestingid="orderform-manager-contact-information">
									{additionalContactDetails()}
								</LabelText>
							</GridCol>
							<GridCol setWidth="one-third" className={[classes.actionPadding, classes.rightAlign]}>
								<Link
									aria-label="Link, change additional contact information"
									as={RouterLink}
									data-automatedtestingid="orderform-manager-contact-information-link"
									to={{
										pathname: "./contact",
										state: {
											fromSummary: type === "Standard swab tests" || type === "Swab tests (PCR tests) to return by priority post box" || type === "Outer return boxes for swab tests" || backToSummary(),
										},
									}}
								>
									Change
								</Link>
							</GridCol>
						</GridRow>

						<SectionBreak className={[classes.w75p, classes.sectionBreakMargin, classes.inlineBlockDivider]} level="SMALL" visible />
					</>
				)}

				{/* Delivery notes */}
				{formContext?.deliveryNotes && !formContext?.isDeliveryInstructionsEnabled && (
					<>
						<GridRow>
							<GridCol setWidth="25%">
								<Caption size="L" className={classes.primaryLabel}>
									Additional information for the delivery driver
								</Caption>
							</GridCol>
							<GridCol setWidth="one-third">
								<LabelText className={[classes.paddingTop025, classes.breakWord]} data-automatedtestingid="orderform-manager-contact-information">
									{formContext?.deliveryNotes}
								</LabelText>
							</GridCol>
							<GridCol setWidth="one-third" className={[classes.actionPadding, classes.rightAlign]}>
								<Link aria-label="Link, change additional information for the delivery driver" as={RouterLink} data-automatedtestingid="orderform-manager-contact-information-link" to="./contact">
									Change
								</Link>
							</GridCol>
						</GridRow>

						<SectionBreak className={[classes.w75p, classes.sectionBreakMargin, classes.inlineBlockDivider]} level="SMALL" visible />
					</>
				)}

				{/* Delivery instructions */}
				{formContext?.isDeliveryInstructionsEnabled && displayDeliveryInstructions()}

				{v3Success === false && showRecaptcha === true && <ReCAPTCHA onChange={(t) => submitV2(t)} name="urn2" ref={captchaRefV2} size="normal" sitekey={captchaKeyV2} />}

				{v3Success === true && <div className="g-recaptcha" data-sitekey={captchaKeyV3} data-size="invisible" ref={captchaRefV3}></div>}

				<GridRow className={classes.buttonRow}>
					<GridCol>
						<Button
							className={classes.urnSubmit}
							data-automatedtestingid="orderform-contactinfoform-button-send"
							onClick={() => {
								confirm();
							}}
						>
							Confirm and send
						</Button>
					</GridCol>
				</GridRow>
			</Main>
		</LoadingBox>
	);
};

export default withRouter(withStyles(styles)(ConfirmChoices));

ConfirmChoices.propTypes = {
	classes: PropTypes.shape({
		actionPadding: PropTypes.string,
		breakWord: PropTypes.string,
		buttonRow: PropTypes.string,
		fakeSize: PropTypes.string,
		headingRow: PropTypes.string,
		inlineBlockDivider: PropTypes.string,
		leftAlign: PropTypes.string,
		lineBreak: PropTypes.string,
		paddingTop025: PropTypes.string,
		primaryLabel: PropTypes.string,
		rightAlign: PropTypes.string,
		sectionBreakMargin: PropTypes.string,
		urnSubmit: PropTypes.string,
		w75p: PropTypes.string,
		zeroPadding: PropTypes.string,
	}).isRequired,
};
