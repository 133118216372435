/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { Button, GridCol, GridRow, H1, H2, H3, Paragraph } from "govuk-react";
import {
  InputComponent,
  SelectComponent,
  TextAreaComponent,
  useAnalytics,
  ErrorSummary,
} from "@cube/global-components";
import { ContactFormTranslation } from "../../../service/translation";
import { FormContext } from "../../../data/contexts/contexts";
import { contactFormValidation } from "../../../service/validation";
import { validatePhone } from "../../../service/validation/validationHelpers.service";
import HelperService from "../../../service/helpers";
import CharRestrictionSets from "../../../data/constants/charRestrictionSets.constants";
import ReCAPTCHA from "react-google-recaptcha";

const styles = {
  gridMargin: {
    marginTop: "30px",
  },
  gridRow: {
    marginTop: "40px",
  },
};

const options = [
  { value: "no", label: "No" },
  { value: "yes", label: "Yes" },
];

const ContactInfoForm = ({
  classes,
  location,
  handleSubError,
  handleLoader,
}) => {
  const { triggerEvent } = useAnalytics();
  const [formContext, setFormContext] = useContext(FormContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorArray, setErrorArray] = useState(null);
  const [showReferralFields, setShowReferralFields] = useState(
    formContext?.secondaryContactSource
  );
  const [secondaryContactSourceValue, setSecondaryContactSourceValue] =
    useState(false);

  const history = useHistory();
  const automationIdPrefix = "orderform-contactfields";

  const RequestLabels = ContactFormTranslation();
  const {
    ERROR_MESSAGES: {
      PRIMARY_CONTACT_FIRST_NAME_ERROR_MSG,
      PRIMARY_CONTACT_LAST_NAME_ERROR_MSG,
      PRIMARY_JOB_TITLE_ERROR_MSG,
      PRIMARY_CONTACT_BLANK_ERR_MSG,
      EMAIL_ERROR_MESSAGE,
      EMAIL_ERROR_MESSAGE_CONFIRM,
      EMAIL_DUPLICATE_ERROR_MESSAGE,
      CONTACT_ERR_HEADING,
      REFERRER_FIRST_NAME_ERROR_MSG,
      REFERRER_LAST_NAME_ERROR_MSG,
      REFERRER_JOB_TITLE_ERROR_MSG,
      REFERRER_ERROR_MESSAGE_CONFIRM,
      REFERRER_ERROR_MESSAGE_EMAIL,
      REFERRER_CONTACT_NUMBER_BLANK,
      REFERRER_ORGANISATION_BLANK,
      DELEVERY_NOTES_ERROR,
      PHONE_NUMBER_INVALID_ERR_MSG,
    },
    REQUEST_INPUTS: {
      REQ_FORM_EMAIL_HELP,
      FORM_PRIMARY_HEADING,
      FORM_PRIMARY_HEADING_COMMUNITY,
      FORM_PRIMARY_SUB_HEADING,
      FORM_PRIMARY_SUB_HEADING_PART_2,
      FORM_PRIMARY_SUB_HEADING_COMMUNITY,
      FORM_PRIMARY_SUB_HEADING_COMMUNITY_PART_2,
      FORM_REFERRER_HEADING,
      DEFAULT: {
        FORM_PRIMARY: {
          PRIMARY_FIRST_NAME,
          PRIMARY_LAST_NAME,
          PRIMARY_JOB_TITLE,
          PRIMARY_CONTACT_NUMBER,
          PRIMARY_EMAIL,
          PRIMARY_EMAIL_CONFIRM,
          DELIVERY_NOTES,
          DELIVERY_NOTES_HINT,
          DELIVERY_NOTES_VALUE,
          CONTACT_FIRST_VALUE,
          CONTACT_LAST_VALUE,
          CONTACT_JOB_VALUE,
          CONTACT_EMAIL_VALUE,
          CONTACT_EMAIL_CONFIRM_VALUE,
          CONTACT_PHONE_VALUE,
          CONTACT_ORGANISATION_VALUE,
        },
        FORM_REFERRER: {
          REFERRER_SELECT,
          REFERRER_FIRST_NAME,
          REFERRER_LAST_NAME,
          REFERRER_ORGANISATION,
          REFERRER_JOB_TITLE,
          REFERRER_CONTACT_NUMBER,
          REFERRER_EMAIL,
          REFERRER_EMAIL_CONFIRM,
          CONTACT_SECONDARY_VALUE,
          SECONDARY_LAST_VALUE,
          SECONDARY_JOB_VALUE,
          SECONDARY_PHONE_VALUE,
          SECONDARY_EMAIL_VALUE,
          SECONDARY_EMAIL_CONFIRM_VALUE,
          SECONDARY_ORGANISATION_VALUE,
        },
      },
    },
  } = RequestLabels;

  const validate = (values) => contactFormValidation(values, RequestLabels);

  const windowAllowed = localStorage.getItem("answer");
  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: "nhs:test:organisation-order:contact",
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "contact",
        },
      },
    };
    triggerEvent(analyticsData);
  } else {
    window.digitalData = null;
  }
  const orgType = formContext?.orgType || null;
  const testTypeTitle = formContext?.testTypeTitle;
  const excludedTypes =
    testTypeTitle !== "Outer return boxes for swab tests" &&
    testTypeTitle !== "Swab tests (PCR tests) to return by priority post box";
  const deliveryInstructionsOrgs = ["Community Support", "Dentist", "Directors of Public Health", "Government Department", "GP", "Health and Care Inspectors", "Health Trust or Authority", "Home Office", "Independent Health Providers", "Local Authority", "Ministry of Defense", "Ministry of Justice", "NHS Trust", "Optometrist", "Other", "Pharmacy", "Private Industry", "Public Industry", "Satellite Test Centre", "School", "Social Worker", "University", "Visiting Professional", "Wholesaler"];
  const alwaysDeliveryInstructionsOrg = deliveryInstructionsOrgs.find(org => org === formContext?.orgType) ? true : false;
  const isCommunityTestingApproach =
    formContext?.testingApproach === "Community Testing";
  const isDeliveryInstructionsEnabled = (isCommunityTestingApproach || alwaysDeliveryInstructionsOrg);
  const path = isDeliveryInstructionsEnabled
    ? history.location?.state?.fromSummary
      ? "confirm-choices"
      : "delivery-instructions"
    : "confirm-choices";

  const handleSelectChange = (event) => {
    setShowReferralFields(event.target.value === "yes" ? true : false);
  };
  const url = window.location.href;
  const captchaKeyA = url.includes("delsit")
    ? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_SIT
    : url.includes("delqa")
    ? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_QA
    : url.includes("e2e")
    ? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_E2E
    : url.includes("preprod")
    ? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_PREPROD
    : url.includes("ci-bulk")
    ? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_CI
    : url.includes("deldev")
    ? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_LOCAL_DEV
    : process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_PROD
    : process.env.REACT_APP_GOOGLE_CAPTCHA_V3_LOCAL_DEV;
  const reRef = React.createRef();

  const onSubmit = (values) => {
    setFormContext({
      ...values,
      orgWeekendTestingCommitment:
        formContext?.orgWeekendTestingCommitment || false, // Defaulting to false if not set
      cqcRegistrationID: formContext.crqId,
      contactName: `${values.contactFirstName.trim()} ${values.contactLastName.trim()}`,
      secondaryContactSource: showReferralFields,
      secondaryContactName: `${values.secondaryContactFirstName && values.secondaryContactFirstName.trim()} ${values.secondaryContactLastName && values.secondaryContactLastName.trim()}`,
      isDeliveryInstructionsEnabled,
    });

    handleLoader(true);

    history.push({
      pathname: path,
    });
  };

  // This is here to set the local storage when the values change in
  // the form because this is the last stage. This will probably change
  // when we get a final 'view your answers' page
  const viewFormValues = ({ values }) => {
    localStorage.setItem("contactForm", JSON.stringify(values));
  };

  const validateFields = () => {
    const orgForm = window.contactForm;
    const { elements } = orgForm;
    let errorArr = [];

    if (
      !elements[CONTACT_FIRST_VALUE].value ||
      elements[CONTACT_FIRST_VALUE].value.trim().length === 0
    ) {
      errorArr.push({
        targetName: CONTACT_FIRST_VALUE,
        text: PRIMARY_CONTACT_FIRST_NAME_ERROR_MSG,
      });
    }
    if (
      HelperService.checkRestricted(
        elements[CONTACT_FIRST_VALUE].value,
        CharRestrictionSets.ContactForm.contactFirstName
      )
    ) {
      errorArr.push({
        targetName: CONTACT_FIRST_VALUE,
        text: `First name must not include ${CharRestrictionSets.ContactForm.contactFirstName}`,
      });
    }

    if (
      !elements[CONTACT_LAST_VALUE].value ||
      elements[CONTACT_LAST_VALUE].value.trim().length === 0
    ) {
      errorArr.push({
        targetName: CONTACT_LAST_VALUE,
        text: PRIMARY_CONTACT_LAST_NAME_ERROR_MSG,
      });
    }
    if (
      HelperService.checkRestricted(
        elements[CONTACT_LAST_VALUE].value,
        CharRestrictionSets.ContactForm.contactLastName
      )
    ) {
      errorArr.push({
        targetName: CONTACT_LAST_VALUE,
        text: `Last name must not include ${CharRestrictionSets.ContactForm.contactLastName}`,
      });
    }
    if (
      !elements[CONTACT_JOB_VALUE].value ||
      elements[CONTACT_JOB_VALUE].value.trim().length === 0
    ) {
      errorArr.push({
        targetName: CONTACT_JOB_VALUE,
        text: PRIMARY_JOB_TITLE_ERROR_MSG,
      });
    }
    if (
      HelperService.checkRestricted(
        elements[CONTACT_JOB_VALUE].value,
        CharRestrictionSets.ContactForm.contactJobTitle
      )
    ) {
      errorArr.push({
        targetName: CONTACT_JOB_VALUE,
        text: `Job title must not include ${CharRestrictionSets.ContactForm.contactJobTitle}`,
      });
    }

    if (
      HelperService.checkRestricted(
        elements[CONTACT_ORGANISATION_VALUE].value,
        CharRestrictionSets.ContactForm.contactOrgName
      )
    ) {
      errorArr.push({
        targetName: CONTACT_ORGANISATION_VALUE,
        text: `Organisation must not include ${CharRestrictionSets.ContactForm.contactOrgName}`,
      });
    }

    if (!elements[CONTACT_PHONE_VALUE].value) {
      errorArr.push({
        targetName: CONTACT_PHONE_VALUE,
        text: PRIMARY_CONTACT_BLANK_ERR_MSG,
      });
    }

    if (elements[CONTACT_PHONE_VALUE] && elements[CONTACT_PHONE_VALUE]?.value && validatePhone(elements[CONTACT_PHONE_VALUE]?.value)) {
      errorArr.push({
        targetName: CONTACT_PHONE_VALUE,
        text: PHONE_NUMBER_INVALID_ERR_MSG,
      });
    }

    if (
      !elements[CONTACT_EMAIL_VALUE].value ||
      elements[CONTACT_EMAIL_VALUE].value.trim().length === 0
    ) {
      errorArr.push({
        targetName: CONTACT_EMAIL_VALUE,
        text: EMAIL_ERROR_MESSAGE,
      });
    }

    const regexEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      elements[CONTACT_EMAIL_VALUE] &&
      elements[CONTACT_EMAIL_VALUE].value &&
      elements[CONTACT_EMAIL_VALUE].value.trim().length !== 0 &&
      !regexEmail.test(elements[CONTACT_EMAIL_VALUE].value)
    ) {
      errorArr.push({
        targetName: CONTACT_EMAIL_VALUE,
        text: EMAIL_ERROR_MESSAGE,
      });
    }

    if (
      HelperService.checkRestricted(
        elements[CONTACT_EMAIL_VALUE].value,
        CharRestrictionSets.ContactForm.contactEmail
      )
    ) {
      errorArr.push({
        targetName: CONTACT_EMAIL_VALUE,
        text: `Email address must not include ${CharRestrictionSets.ContactForm.contactEmail}`,
      });
    }

    if (
      !elements[CONTACT_EMAIL_CONFIRM_VALUE].value ||
      elements[CONTACT_EMAIL_CONFIRM_VALUE].value.trim().length === 0
    ) {
      errorArr.push({
        targetName: CONTACT_EMAIL_CONFIRM_VALUE,
        text: EMAIL_ERROR_MESSAGE_CONFIRM,
      });
    }

    if (
      elements[CONTACT_EMAIL_CONFIRM_VALUE] &&
      elements[CONTACT_EMAIL_CONFIRM_VALUE].value &&
      elements[CONTACT_EMAIL_CONFIRM_VALUE].value.trim().length !== 0 &&
      !regexEmail.test(elements[CONTACT_EMAIL_CONFIRM_VALUE].value)
    ) {
      errorArr.push({
        targetName: CONTACT_EMAIL_CONFIRM_VALUE,
        text: EMAIL_ERROR_MESSAGE_CONFIRM,
      });
    }

    if (
      HelperService.checkRestricted(
        elements[CONTACT_EMAIL_CONFIRM_VALUE].value,
        CharRestrictionSets.ContactForm.contactConfirmEmail
      )
    ) {
      errorArr.push({
        targetName: CONTACT_EMAIL_CONFIRM_VALUE,
        text: `Confirm email address must not include ${CharRestrictionSets.ContactForm.contactConfirmEmail}`,
      });
    }

    if (
      elements[CONTACT_EMAIL_VALUE].value !==
      elements[CONTACT_EMAIL_CONFIRM_VALUE].value
    ) {
      errorArr.push({
        targetName: CONTACT_EMAIL_CONFIRM_VALUE,
        text: "Email addresses do not match",
      });
    }

    if (
      HelperService.checkRestricted(
        elements[DELIVERY_NOTES_VALUE]?.value,
        CharRestrictionSets.ContactForm.contactDriver
      )
    ) {
      errorArr.push({
        targetName: DELIVERY_NOTES_VALUE,
        text:
          DELEVERY_NOTES_ERROR + CharRestrictionSets.ContactForm.contactDriver,
      });
    }

    if (
      showReferralFields &&
      (!elements[CONTACT_SECONDARY_VALUE].value ||
        elements[CONTACT_SECONDARY_VALUE].value.trim().length === 0)
    ) {
      errorArr.push({
        targetName: CONTACT_SECONDARY_VALUE,
        text: REFERRER_FIRST_NAME_ERROR_MSG,
      });
    }

    if (
      showReferralFields &&
      HelperService.checkRestricted(
        elements[CONTACT_SECONDARY_VALUE].value,
        CharRestrictionSets.ContactForm.contactFirstName
      )
    ) {
      errorArr.push({
        targetName: CONTACT_SECONDARY_VALUE,
        text: `Additional contact first name must not include ${CharRestrictionSets.ContactForm.contactFirstName}`,
      });
    }

    if (
      showReferralFields &&
      (!elements[SECONDARY_LAST_VALUE].value ||
        elements[SECONDARY_LAST_VALUE].value.trim().length === 0)
    ) {
      errorArr.push({
        targetName: SECONDARY_LAST_VALUE,
        text: REFERRER_LAST_NAME_ERROR_MSG,
      });
    }

    if (
      showReferralFields &&
      HelperService.checkRestricted(
        elements[SECONDARY_LAST_VALUE].value,
        CharRestrictionSets.ContactForm.contactLastName
      )
    ) {
      errorArr.push({
        targetName: SECONDARY_LAST_VALUE,
        text: `Additional contact last name must not include ${CharRestrictionSets.ContactForm.contactLastName}`,
      });
    }

    if (
      showReferralFields &&
      (!elements[SECONDARY_JOB_VALUE].value ||
        elements[SECONDARY_JOB_VALUE].value.trim().length === 0)
    ) {
      errorArr.push({
        targetName: SECONDARY_JOB_VALUE,
        text: REFERRER_JOB_TITLE_ERROR_MSG,
      });
    }

    if (
      showReferralFields &&
      HelperService.checkRestricted(
        elements[SECONDARY_JOB_VALUE].value,
        CharRestrictionSets.ContactForm.contactJobTitle
      )
    ) {
      errorArr.push({
        targetName: SECONDARY_JOB_VALUE,
        text: `Additional contact job title must not include ${CharRestrictionSets.ContactForm.contactJobTitle}`,
      });
    }

    if (
      showReferralFields &&
      HelperService.checkRestricted(
        elements[SECONDARY_ORGANISATION_VALUE].value,
        CharRestrictionSets.ContactForm.contactOrgName
      )
    ) {
      errorArr.push({
        targetName: SECONDARY_ORGANISATION_VALUE,
        text: `Additional contact organisation must not include ${CharRestrictionSets.ContactForm.contactOrgName}`,
      });
    }

    if (showReferralFields && !elements[SECONDARY_PHONE_VALUE].value) {
      errorArr.push({
        targetName: SECONDARY_PHONE_VALUE,
        text: REFERRER_CONTACT_NUMBER_BLANK,
      });
    }

    if (showReferralFields && elements[SECONDARY_PHONE_VALUE]?.value && validatePhone(elements[SECONDARY_PHONE_VALUE]?.value)) {
      errorArr.push({
        targetName: SECONDARY_PHONE_VALUE,
        text: PHONE_NUMBER_INVALID_ERR_MSG,
      });
    }

    if (
      showReferralFields &&
      (!elements[SECONDARY_EMAIL_VALUE].value ||
        elements[SECONDARY_EMAIL_VALUE].value.trim().length === 0)
    ) {
      errorArr.push({
        targetName: SECONDARY_EMAIL_VALUE,
        text: REFERRER_ERROR_MESSAGE_EMAIL,
      });
    }

    if (
      showReferralFields &&
      elements[SECONDARY_EMAIL_VALUE] &&
      elements[SECONDARY_EMAIL_VALUE].value &&
      elements[SECONDARY_EMAIL_VALUE].length !== 0 &&
      !regexEmail.test(elements[SECONDARY_EMAIL_VALUE].value)
    ) {
      errorArr.push({
        targetName: SECONDARY_EMAIL_VALUE,
        text: REFERRER_ERROR_MESSAGE_EMAIL,
      });
    }

    if (
      showReferralFields &&
      HelperService.checkRestricted(
        elements[SECONDARY_EMAIL_VALUE].value,
        CharRestrictionSets.ContactForm.contactEmail
      )
    ) {
      errorArr.push({
        targetName: SECONDARY_EMAIL_VALUE,
        text: `Additional contact email address must not include ${CharRestrictionSets.ContactForm.contactEmail}`,
      });
    }

    if (
      showReferralFields &&
      (!elements[SECONDARY_EMAIL_CONFIRM_VALUE].value ||
        elements[SECONDARY_EMAIL_CONFIRM_VALUE].value.trim().length === 0)
    ) {
      errorArr.push({
        targetName: SECONDARY_EMAIL_CONFIRM_VALUE,
        text: REFERRER_ERROR_MESSAGE_CONFIRM,
      });
    }

    if (
      showReferralFields &&
      elements[SECONDARY_EMAIL_CONFIRM_VALUE] &&
      elements[SECONDARY_EMAIL_CONFIRM_VALUE].value &&
      elements[SECONDARY_EMAIL_CONFIRM_VALUE].value.trim().length !== 0 &&
      !regexEmail.test(elements[SECONDARY_EMAIL_CONFIRM_VALUE].value)
    ) {
      errorArr.push({
        targetName: SECONDARY_EMAIL_CONFIRM_VALUE,
        text: REFERRER_ERROR_MESSAGE_CONFIRM,
      });
    }

    if (
      showReferralFields &&
      HelperService.checkRestricted(
        elements[SECONDARY_EMAIL_CONFIRM_VALUE].value,
        CharRestrictionSets.ContactForm.contactEmail
      )
    ) {
      errorArr.push({
        targetName: SECONDARY_EMAIL_CONFIRM_VALUE,
        text: `Confirm additional contact email address must not include ${CharRestrictionSets.ContactForm.contactEmail}`,
      });
    }

    if (
      showReferralFields &&
      elements[SECONDARY_EMAIL_VALUE].value !==
        elements[SECONDARY_EMAIL_CONFIRM_VALUE].value
    ) {
      errorArr.push({
        targetName: SECONDARY_EMAIL_CONFIRM_VALUE,
        text: "Email addresses do not match",
      });
    }

    const emailsCompletedCheck = elements.contactEmail.value && (elements[SECONDARY_EMAIL_VALUE] && elements[SECONDARY_EMAIL_VALUE].value);
    if (
      showReferralFields &&
      emailsCompletedCheck &&
      elements.contactEmail.value ===
        elements[CONTACT_EMAIL_CONFIRM_VALUE].value &&
      elements[SECONDARY_EMAIL_VALUE].value ===
        elements[SECONDARY_EMAIL_CONFIRM_VALUE].value &&
      elements[CONTACT_EMAIL_VALUE].value ===
        elements[SECONDARY_EMAIL_VALUE].value
    ) {
      errorArr.push({
        targetName: SECONDARY_EMAIL_VALUE,
        text: EMAIL_DUPLICATE_ERROR_MESSAGE,
      });
    }

    if (errorArr.length > 0) {
      setErrorArray(errorArr);
      setErrorMessage("There is a problem");
      return false;
    }

    setErrorArray(null);
    setErrorMessage("");
    return true;
  };

  const handleFieldsOnBlur = (event) => {
    event.preventDefault();
  };

  const isCommunityTesting = (communityText, defaultText) => {
    if (formContext?.testingApproach === "Community Testing") {
      return communityText;
    }
    return defaultText;
  };

  return (
    <>
      {errorMessage && (
        <div data-automatedtestingid="orderform-contactinfoform-errorsummary">
          <ErrorSummary
            automationId="order-form-error"
            heading={CONTACT_ERR_HEADING}
            errors={errorArray}
            onHandleErrorClick={HelperService.onHandleErrorClick}
            errorMessage={errorMessage}
          />
        </div>
      )}
      <Form
        initialValues={formContext}
        onSubmit={onSubmit}
        validate={(e) => validate(e)}
        render={({ handleSubmit }) => {
          return (
            <form id="contactForm" onSubmit={handleSubmit}>
              <>
                <ReCAPTCHA ref={reRef} size="invisible" sitekey={captchaKeyA} />
                <GridRow className={classes.gridMargin}>
                  <GridCol setWidth="two-thirds">
                    <header>
                      <H1>
                        {isCommunityTesting(
                          FORM_PRIMARY_HEADING_COMMUNITY,
                          FORM_PRIMARY_HEADING
                        )}
                      </H1>
                    </header>
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol setWidth="two-thirds">
                    <Paragraph>
                      {isCommunityTesting(
                        FORM_PRIMARY_SUB_HEADING_COMMUNITY,
                        FORM_PRIMARY_SUB_HEADING
                      )}
                    </Paragraph>
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol setWidth="two-thirds">
                    <Paragraph>
                      {isCommunityTesting(
                        FORM_PRIMARY_SUB_HEADING_COMMUNITY_PART_2,
                        FORM_PRIMARY_SUB_HEADING_PART_2
                      )}
                    </Paragraph>
                  </GridCol>
                </GridRow>
                <GridRow className={classes.gridMargin}>
                  <GridCol setWidth="two-thirds">
                    <InputComponent
                      initialValue={formContext?.contactFirstName}
                      label={PRIMARY_FIRST_NAME}
                      name={CONTACT_FIRST_VALUE}
                      width="input-width-20"
                      errMessage={PRIMARY_CONTACT_FIRST_NAME_ERROR_MSG}
                      restrictedCharacters={
                        CharRestrictionSets.ContactForm.contactFirstName
                      }
                      type="text"
                      automatedtestingId={`${automationIdPrefix}-inputcomponent-primaryFirstName`}
                      disallowNumbers={true}
                    />
                  </GridCol>
                </GridRow>
                <GridRow className={classes.gridMargin}>
                  <GridCol setWidth="two-thirds">
                    <InputComponent
                      initialValue={formContext?.contactLastName}
                      label={PRIMARY_LAST_NAME}
                      name={CONTACT_LAST_VALUE}
                      width="input-width-20"
                      errMessage={PRIMARY_CONTACT_LAST_NAME_ERROR_MSG}
                      restrictedCharacters={
                        CharRestrictionSets.ContactForm.contactLastName
                      }
                      type="text"
                      automatedtestingId={`${automationIdPrefix}-inputcomponent-primaryLastName`}
                      disallowNumbers={true}
                    />
                  </GridCol>
                </GridRow>
                <GridRow className={classes.gridMargin}>
                  <GridCol setWidth="two-thirds">
                    <InputComponent
                      initialValue={formContext?.contactJobTitle}
                      label={PRIMARY_JOB_TITLE}
                      name={CONTACT_JOB_VALUE}
                      width="input-width-20"
                      errMessage={PRIMARY_JOB_TITLE_ERROR_MSG}
                      restrictedCharacters={
                        CharRestrictionSets.ContactForm.contactJobTitle
                      }
                      type="text"
                      automatedtestingId={`${automationIdPrefix}-inputcomponent-primaryJobTitle`}
                      disallowNumbers={true}
                    />
                  </GridCol>
                </GridRow>
                <GridRow className={classes.gridMargin}>
                  <GridCol setWidth="two-thirds">
                    <InputComponent
                      initialValue={formContext?.contactOrganisation}
                      label="Organisation (optional)"
                      name={CONTACT_ORGANISATION_VALUE}
                      width="input-width-20"
                      hint="If you work for a different organisation tell us here."
                      type="text"
                      restrictedCharacters={
                        CharRestrictionSets.ContactForm.contactOrgName
                      }
                      notrequired={true}
                      automatedtestingId={`${automationIdPrefix}-inputcomponent-primaryOrgName`}
                    />
                  </GridCol>
                </GridRow>
                <GridRow className={classes.gridMargin}>
                  <GridCol setWidth="two-thirds">
                    <InputComponent
                      initialValue={formContext?.contactPhoneNumber}
                      label={PRIMARY_CONTACT_NUMBER}
                      name={CONTACT_PHONE_VALUE}
                      width="input-width-20"
                      errMessage={PRIMARY_CONTACT_BLANK_ERR_MSG}
                      type="number"
                      automatedtestingId={`${automationIdPrefix}-inputcomponent-primaryPhoneNumber`}
                    />
                  </GridCol>
                </GridRow>
                <GridRow className={classes.gridMargin}>
                  <GridCol setWidth="two-thirds">
                    <InputComponent
                      initialValue={formContext?.contactEmail}
                      label={PRIMARY_EMAIL}
                      name={CONTACT_EMAIL_VALUE}
                      width="input-width-20"
                      errMessage={EMAIL_ERROR_MESSAGE}
                      restrictedCharacters={
                        CharRestrictionSets.ContactForm.contactEmail
                      }
                      hint={REQ_FORM_EMAIL_HELP}
                      type="email"
                      automatedtestingId={`${automationIdPrefix}-inputcomponent-primaryEmail`}
                    />
                  </GridCol>
                </GridRow>
                <GridRow className={classes.gridMargin}>
                  <GridCol setWidth="two-thirds">
                    <InputComponent
                      initialValue={formContext?.contactEmailConfirm}
                      label={PRIMARY_EMAIL_CONFIRM}
                      name={CONTACT_EMAIL_CONFIRM_VALUE}
                      width="input-width-20"
                      errMessage={EMAIL_ERROR_MESSAGE_CONFIRM}
                      restrictedCharacters={
                        CharRestrictionSets.ContactForm.contactConfirmEmail
                      }
                      type="email"
                      automatedtestingId={`${automationIdPrefix}-inputcomponent-primaryEmailConfirm`}
                    />
                  </GridCol>
                </GridRow>
                {(!isCommunityTestingApproach && path !== "delivery-instructions") && (
                  <GridRow className={classes.gridMargin}>
                    <GridCol setWidth="two-thirds">
                      <TextAreaComponent
                        errorMsgAlternative={DELEVERY_NOTES_ERROR}
                        dataMaxlength="255"
                        maxLength={255}
                        label={DELIVERY_NOTES}
                        name={DELIVERY_NOTES_VALUE}
                        hint={DELIVERY_NOTES_HINT}
                        width="input-width-20"
                        restrictedCharacters={
                          CharRestrictionSets.ContactForm.contactDriver
                        }
                        notrequired={true}
                        automatedtestingId={`${automationIdPrefix}-inputcomponent-primaryContactDeliveryNotes`}
                      />
                    </GridCol>
                  </GridRow>
                )}
                <GridRow className={classes.gridRow}>
                  <GridCol setWidth="two-thirds">
                    <br />
                    <H2>{FORM_REFERRER_HEADING}</H2>
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol setWidth="two-thirds">
                    <SelectComponent
                      label={REFERRER_SELECT}
                      aria-label={REFERRER_SELECT}
                      automatedtestingId={`${automationIdPrefix}-select-referral`}
                      defaultValue="no"
                      value={secondaryContactSourceValue}
                      initialValue={
                        formContext?.secondaryContactSource ? "yes" : "no"
                      }
                      name="secondaryContactSource"
                      onChange={(e) => handleSelectChange(e)}
                      options={options}
                      customWidth={true}
                    />
                  </GridCol>
                </GridRow>
                {showReferralFields && (
                  <>
                    <GridRow className={classes.gridMargin}>
                      <GridCol setWidth="two-thirds">
                        <InputComponent
                          initialValue={formContext?.secondaryContactFirstName}
                          label={REFERRER_FIRST_NAME}
                          name={CONTACT_SECONDARY_VALUE}
                          width="input-width-20"
                          errMessage={REFERRER_FIRST_NAME_ERROR_MSG}
                          restrictedCharacters={
                            CharRestrictionSets.ContactForm.contactFirstName
                          }
                          type="text"
                          automatedtestingId={`${automationIdPrefix}-inputcomponent-secondaryFirstName`}
                          disallowNumbers={true}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow className={classes.gridMargin}>
                      <GridCol setWidth="two-thirds">
                        <InputComponent
                          initialValue={formContext?.secondaryContactLastName}
                          label={REFERRER_LAST_NAME}
                          name={SECONDARY_LAST_VALUE}
                          width="input-width-20"
                          errMessage={REFERRER_LAST_NAME_ERROR_MSG}
                          restrictedCharacters={
                            CharRestrictionSets.ContactForm.contactLastName
                          }
                          type="text"
                          automatedtestingId={`${automationIdPrefix}-inputcomponent-secondaryLastName`}
                          disallowNumbers={true}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow className={classes.gridMargin}>
                      <GridCol setWidth="two-thirds">
                        <InputComponent
                          initialValue={formContext?.secondaryContactJobTitle}
                          label={REFERRER_JOB_TITLE}
                          name={SECONDARY_JOB_VALUE}
                          width="input-width-20"
                          errMessage={REFERRER_JOB_TITLE_ERROR_MSG}
                          restrictedCharacters={
                            CharRestrictionSets.ContactForm.contactJobTitle
                          }
                          type="text"
                          automatedtestingId={`${automationIdPrefix}-inputcomponent-secondaryJobTitle`}
                          disallowNumbers={true}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow className={classes.gridMargin}>
                      <GridCol setWidth="two-thirds">
                        <InputComponent
                          initialValue={
                            formContext?.secondaryContactOrganisation
                          }
                          label={REFERRER_ORGANISATION}
                          name={SECONDARY_ORGANISATION_VALUE}
                          width="input-width-20"
                          errMessage={REFERRER_ORGANISATION_BLANK}
                          restrictedCharacters={
                            CharRestrictionSets.ContactForm.contactOrgName
                          }
                          type="text"
                          notrequired={true}
                          automatedtestingId={`${automationIdPrefix}-inputcomponent-secondaryOrgName`}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow className={classes.gridMargin}>
                      <GridCol setWidth="two-thirds">
                        <InputComponent
                          initialValue={formContext?.secondaryContactNumber}
                          label={REFERRER_CONTACT_NUMBER}
                          name={SECONDARY_PHONE_VALUE}
                          width="input-width-20"
                          errMessage={REFERRER_CONTACT_NUMBER_BLANK}
                          type="number"
                          automatedtestingId={`${automationIdPrefix}-inputcomponent-secondaryPhoneNumber`}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow className={classes.gridMargin}>
                      <GridCol setWidth="two-thirds">
                        <InputComponent
                          initialValue={formContext?.secondaryContactEmail}
                          label={REFERRER_EMAIL}
                          name={SECONDARY_EMAIL_VALUE}
                          width="input-width-20"
                          errMessage={EMAIL_ERROR_MESSAGE}
                          restrictedCharacters={
                            CharRestrictionSets.ContactForm.contactEmail
                          }
                          type="email"
                          automatedtestingId={`${automationIdPrefix}-inputcomponent-secondaryEmail`}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow className={classes.gridMargin}>
                      <GridCol setWidth="two-thirds">
                        <InputComponent
                          initialValue={
                            formContext?.secondaryContactEmailConfirm
                          }
                          label={REFERRER_EMAIL_CONFIRM}
                          name={SECONDARY_EMAIL_CONFIRM_VALUE}
                          width="input-width-20"
                          errMessage={EMAIL_ERROR_MESSAGE_CONFIRM}
                          restrictedCharacters={
                            CharRestrictionSets.ContactForm.contactConfirmEmail
                          }
                          type="email"
                          automatedtestingId={`${automationIdPrefix}-inputcomponent-secondaryEmailConfirm`}
                        />
                      </GridCol>
                    </GridRow>
                  </>
                )}
              </>

              <GridRow className={classes.gridMargin}>
                <GridCol>
                  <Button
                    onClick={(event) => {
                      reRef.current.execute();
                      validateFields(event);
                    }}
                    onMouseDown={(event) => handleFieldsOnBlur(event)}
                    className={classes.urnSubmit}
                    data-automatedtestingid="orderform-continue-button"
                  >
                    Continue
                  </Button>
                </GridCol>
              </GridRow>
            </form>
          );
        }}
      />
    </>
  );
};

export default withRouter(withStyles(styles)(ContactInfoForm));

ContactInfoForm.defaultProps = {};

ContactInfoForm.propTypes = {
  classes: PropTypes.shape({
    gridMargin: PropTypes.string,
    gridRow: PropTypes.string,
    urnSubmit: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      crqData: PropTypes.shape({
        City: PropTypes.string,
        OrganisationName: PropTypes.string,
        Postcode: PropTypes.string,
        Street: PropTypes.string,
        TestingApproach: PropTypes.string,
        cqcRegistrationID: PropTypes.string,
      }).isRequired,
      crqId: PropTypes.string.isRequired,
      orgType: PropTypes.string.isRequired,
    }),
  }).isRequired,
  handleSubError: PropTypes.func.isRequired,
  handleLoader: PropTypes.func.isRequired,
};
