/* eslint-disable prefer-const */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { Button, GridCol, GridRow, InputField, LoadingBox } from "govuk-react";
import {
  InputComponent,
  useAnalytics,
  ErrorSummary,
} from "@cube/global-components";
import ReCAPTCHA from "react-google-recaptcha";
import { Form } from "react-final-form";
import { ErrorLabels } from "../../data/constants";
import DataAccessService from "../../service/httpUtility";
import CRQHeading from "./UONHeading.component";
import { UONTranslation } from "../../service/translation";
import { validationHelpers } from "../../service/validation";
import HelperService from "../../service/helpers";
import { FormContext } from "../../data/contexts/contexts";
import UONField from './UONField.component';

const styles = {
  buttonRow: {
    marginTop: "20px",
    marginBottom: "10px"
  },
  formError: {
    paddingLeft: "15px",
    borderLeft: "4px solid #d4351c",
  },
  formErrMsg: {
    color: "#d4351c",
    fontSize: "1.1875rem",
    lineHeight: "1.31579",
    marginBottom: "15px !important",
    fontWeight: "700",
  },
  selectBox: {
    width: "100%",
  },
  gridRow: {
    marginBottom: "10px",
  },
};

// retrieve constants used in the component
const { FAILURE_ERR_MSG } = ErrorLabels;
const RECAPTCHATHRESHOLD = 0.6;
const RECAPTCHAACTION = "orderform_homepage";

const UONForm = ({ classes }) => {
  const answered = localStorage.getItem("answer");
  const answeredNo = localStorage.getItem("answerNo");
  const selectedCookie = answered === true;
  const { triggerEvent } = useAnalytics();
  const [errMsgBackendPresent, setErrMsgBackendPresent] = useState([]);

  const windowAllowed = localStorage.getItem("answer");

  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: "nhs:test:organisation-order:uon",
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "uon",
        },
      },
    };
    triggerEvent(analyticsData);
  } else {
    window.digitalData = null;
  }
  const [formContext, setFormContext] = useContext(FormContext);

  const captchaKeyV2 =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_GOOGLE_CAPTCHA_PROD
      : process.env.REACT_APP_GOOGLE_CAPTCHA_LOCAL;
  const OrganisationLabels = UONTranslation();
  const {
    ERROR_MESSAGES: {
      UON_ERROR_HEADING,
      UON_ERROR_EMPTY_FIELDS,
      UON_ERROR_ORG_DESC,
      UON_ERROR_DES_4,
    },
    INFO: {
      UNIQUE_ID_HEADING,
      UNIQUE_ID_SUB_HEADING,
      UNIQUE_ID_LABEL,
      UNIQUE_ID_NAME,
      HELP_TEXT,
      LINK_TEXT,
      LINK_TITLE,
      LINK_URL,
      LINK_ARIA_LABEL,
    },
  } = OrganisationLabels;

  const [errMessage, setErrorMsg] = useState(null);
  const [errorArray, setErrorArray] = useState([]);
  const [errorDesc, setErrorDesc] = useState(null);
  const [errMessageSelect, setErrorMsgSelect] = useState(null);
  const [loader, setLoader] = useState(false);
  const [token, setToken] = useState([]);
  const [v3Success, setV3Success] = useState(true);
  const [v2Checked, setV2Checked] = useState(false);
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const history = useHistory();
  const errors = {};
  let isAvailableTestTypes = false;

  // eslint-disable-next-line no-unused-vars
  const [hideBanner, setHideBanner] = useState("");
  const hideBannerFunction = () => {
    setHideBanner("hidden");
  };
  const url = window.location.href;
  const captchaKeyV3 = url.includes("delsit")
    ? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_SIT
    : url.includes("delqa")
    ? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_QA
    : url.includes("e2e")
    ? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_E2E
    : url.includes("preprod")
    ? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_PREPROD
    : url.includes("ci-bulk")
    ? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_CI
    : url.includes("deldev")
    ? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_LOCAL_DEV
    : process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_GOOGLE_CAPTCHA_V3_PROD
    : process.env.REACT_APP_GOOGLE_CAPTCHA_V3_LOCAL_DEV;
  const captchaRefV3 = React.createRef();
  const captchaRefV2 = React.createRef();

  const isNotProduction = (u) => {
    if (window.location.hostname === "localhost" || u.includes("delsit") || u.includes("delqa") || u.includes("deldev") || u.includes("preprod")) {
      return true;
    }
    return false;
  };

  isNotProduction(url) && console.info("Recaptcha V3 sitekey", captchaKeyV3);

  const checked = () => {
    return process.env.REACT_APP_RECAPTCHA_NEEDED === "1" ? (v3Success ? v3Success : v2Checked) : true;
  };


  const validate = (values) => {
    const uon = validationHelpers.validateOrgNumber(values.organisationNumber);
    if (uon === false) {
      errors[UNIQUE_ID_NAME] =
        "Enter a correct 8-digit unique organisation number";
      return errors;
    }
  };

  const validateFields = (values) => {
    const { elements } = window.UONForm;
    const errorArr = [];

    setErrorMsg(null)
    setErrorArray(null);
    if (
      elements[UNIQUE_ID_NAME].value &&
      validationHelpers.validateOrgNumber(elements[UNIQUE_ID_NAME].value) ===
        false
    ) {
      errorArr.push({
        targetName: UNIQUE_ID_NAME,
        text: "Enter a correct 8-digit unique organisation number",
      });
    }

    if (!elements[UNIQUE_ID_NAME].value) {
      errorArr.push({
        targetName: UNIQUE_ID_NAME,
        text: "Enter a UON number",
      });
    }

    if (!checked()) {
      errorArr.push({ targetName: "urn2", text: "Check reCAPTCHA" });
      setErrorMsg("Check reCAPTCHA");
      setErrorMsgSelect("Check reCAPTCHA");
    }

    if (errorArr.length > 0) {
      setErrorArray(errorArr);
      setErrorMsg("There is a problem");
      return false;
    }

    setErrorArray([]);
    setErrMsgBackendPresent([]);
    return true;
  };

  // #9880 This is the execution of reCaptcha v3;
  // https://developers.google.com/recaptcha/docs/v3
  useEffect(() => {
    if (!showRecaptcha) return;
    window.grecaptcha.enterprise.ready((_) => {
      window.grecaptcha.enterprise
        .execute(captchaKeyV3, {
          action: RECAPTCHAACTION,
        })
        .then((t) => {
          // IMPORTANT: The 'token' that results from execute is an encrypted response sent by
          // reCAPTCHA Enterprise to the end user's browser. This token must be validated by
          // creating an assessment;
          // See https://cloud.google.com/recaptcha-enterprise/docs/create-assessment
          setToken(t);

          isNotProduction(url) && console.info("Recaptcha V3 token", t);

          const verifyData = {
            token: t,
            action: RECAPTCHAACTION,
            sitekey: captchaKeyV3,
          };

          // This is the call to the SF backend to verify that recaptcha V3 has been
          // successful or failed. Either way we set the hook so it can be used in
          // the display of the reCaptcha V2 and/or the submit button check;
          DataAccessService.checkGRFactor(verifyData)
            .then(result => {
              const scoreVal = result.split("score=")[1].split("}")[0];
              if (parseFloat(scoreVal, 10) < RECAPTCHATHRESHOLD || parseFloat(scoreVal, 10) === 1) {
              // if ((result.score && result.score < RECAPTCHATHRESHOLD) || result.score === 1) {
                isNotProduction(url) &&
                  console.info(
                    "Recaptcha V3 scoring failed by score, generating reCaptcha V2"
                  );
                setV3Success(false);
              } else {
                setV3Success(true);

                isNotProduction(url) &&
                  console.info("Recaptcha V3 scoring succeeded:", result);
              }
            })
            .catch((e) => {
              setV3Success(false);
              isNotProduction(url) &&
                console.info(
                  "Recaptcha V3 scoring failed by error, generating reCaptcha V2"
                );
            });
        });
    });
    return () => {
      setShowRecaptcha(false);
    };
  }, [showRecaptcha]);

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${captchaKeyV3}`;
    script.addEventListener("load", () => setShowRecaptcha(true));
    document.body.appendChild(script);
  }, []);

  const onSubmit = (values) => {
    if (validateFields()) {
      const uon = HelperService.trimString(values.organisationNumber);

      if (validationHelpers.validateOrgNumber(uon)) {
        setLoader(true);

        if (token) {
          // This journey is to ensure that recaptcha has succeeded;

          if (checked()) {
            DataAccessService.sendUON(uon)
              .then((result) => {
                setFormContext({
                  crqId: result.cqcRegistrationID,
                  crqData: result,
                  orgType: result.OrganisationType,
                  multipleaddress: result.Addresses,
                  pageLocation: "/overview",
                  uonNumber: uon,
                });

                let mapTestingTypes = result.TestingTypes?.map(
                  (TestingType) => {
                    /* RETURN TRUE IF ORDERFORM TRUE OR LASTPROCESSED <> EMPTY OR RESTRICTION <> "BLOCKED" */
                    if (
                      TestingType.orderFormSuccess ||
                      TestingType.lastProcessedOrder !== "" ||
                      TestingType.orderRestriction !== "Blocked" ||
                      TestingType?.orderStatus !== ""
                    ) {
                      isAvailableTestTypes = true;
                    }
                  }
                );

                if (!isAvailableTestTypes) {
                  history.push({
                    pathname: "/not-eligible-to-order",
                  });
                } else {
                  history.push({
                    pathname: "/overview",
                  });
                }
              })
              .catch((e) => {
                setLoader(false);
                const errorMsg =
                  e?.response?.data?.errMessage || FAILURE_ERR_MSG;
                  const thisArray = [];
                if (
                  errorMsg.includes(
                    "This could be because you have entered an invalid organisation number."
                )) {
                  thisArray.push({
                    targetName: UNIQUE_ID_NAME,
                    text: "Enter a correct 8-digit unique organisation number",
                  });

                  errors[UNIQUE_ID_NAME] =
                    "Enter a correct 8-digit unique organisation number";

                  setErrMsgBackendPresent((error) => [
                    ...error,
                    UNIQUE_ID_NAME,
                  ]);
                  setErrorArray(thisArray);
                  setErrorMsg("There is a problem");
                }
                else if (
                  errorMsg &&
                  errorMsg.includes(
                    "Your organisation is not eligible to place an order online"
                  )
                ) {
                  history.push({
                    pathname: "/not-eligible-to-order",
                  });
                } else {
                  setErrorMsg(errorMsg);
                }
              });
          }

          if (!checked()) {
            setLoader(false);
            setErrorArray({
              name: "urn2",
              text: "Please try again later. If you continue to experience difficulties registering please call the helpline on 0300 303 2713.",
            });

            setErrorMsg("Check reCAPTCHA");

            isNotProduction(url) &&
              console.info(
                "Onboarding failed because of V3 failure and V2 not checked"
              );
          }
        }
      }
    }
  };

  const submitV2 = (t) => setV2Checked(!v2Checked);
  const handleFieldsOnBlur = (event) => {
    event.preventDefault();
  };

  return (
    <LoadingBox
      loading={loader}
      backgroundColor="#fff"
      timeIn={100}
      timeOut={100}
      backgroundColorOpacity={0.85}
      spinnerColor="#000"
    >
      {errMessage &&
      errMessage.includes(
        "Your organisation is not eligible to place an order online"
      ) ? (
        <GridRow>
          <GridCol setWidth="two-thirds">
            <ErrorSummary
              heading="Your organisation is not eligible to place an order online."
              description={UON_ERROR_DES_4}
              errors={[]}
              errorMessage={errMessage}
            />
          </GridCol>
        </GridRow>
      ) : (
        errMessage && (
          <div data-automatedtestingid="orderform-uonform-errormessage">
            <GridRow>
              <GridCol setWidth="two-thirds">
                {errorArray && errorArray.length > 0 ? (
                  <ErrorSummary
                    heading={errMessage}
                    errors={errorArray}
                    description={errorDesc}
                    onHandleErrorClick={HelperService.onHandleErrorClick}
                    errorMessage={errMessage}
                  />
                ) : (
                  <ErrorSummary
                    heading={errMessage}
                    errors={errorArray}
                    description={errorDesc}
                    onHandleErrorClick={HelperService.onHandleErrorClick}
                    errorMessage={errMessage}
                  />
                )}
              </GridCol>
            </GridRow>
          </div>
        )
      )}

      <CRQHeading
        header={UNIQUE_ID_HEADING}
        subHeader={UNIQUE_ID_SUB_HEADING}
        helpText={HELP_TEXT}
        linkText={LINK_TEXT}
        linkTitle={LINK_TITLE}
        linkURL={LINK_URL}
        ariaLabel={LINK_ARIA_LABEL}
      />
      <Form onSubmit={onSubmit} validate={validate}>
        {({ handleSubmit }) => (
          <form id="UONForm" onSubmit={handleSubmit}>
            <GridRow>
              <GridCol setWidth="two-thirds">
                <UONField errMsgBackendPresent={errMsgBackendPresent} />
              </GridCol>
            </GridRow>
            <GridRow className={classes.buttonRow}>
              <GridCol>
                {(v3Success === false && showRecaptcha === true) && (
                  <div className={errMessageSelect ? classes.formError : ""}>
                    {errMessageSelect && (
                    <div
                      className={classes.formErrMsg}
                      data-automatedtestingid="onboarding-form-org-type-error"
                    >
                      {errMessageSelect}
                    </div>
                  )}
                    <ReCAPTCHA
                      onChange={(t) => submitV2(t)}
                      name="urn2"
                      ref={captchaRefV2}
                      size="normal"
                      sitekey={captchaKeyV2}
                    />
                  </div>
                )}
                {v3Success === true && (
                  <div
                    className="g-recaptcha"
                    data-sitekey={captchaKeyV3}
                    data-size="invisible"
                    ref={captchaRefV3}
                  ></div>
                )}
              </GridCol>
            </GridRow>
            <GridRow className={classes.buttonRow}>
              <GridCol>
                <Button
                  className={`${classes.urnSubmit} ${classes.submitButtonFontSize}`}
                  data-automatedtestingid="orderform-continue-button"
                  onClick={(event) => {
                    validateFields(event);
                  }}
                  onMouseDown={(event) => handleFieldsOnBlur(event)}
                >
                  Continue
                </Button>
              </GridCol>
            </GridRow>
          </form>
        )}
      </Form>
    </LoadingBox>
  );
};

UONForm.propTypes = {
  classes: PropTypes.shape({
    buttonRow: PropTypes.string,
    formError: PropTypes.string,
    formErrMsg: PropTypes.string,
    urnSubmit: PropTypes.string,
    gridRow: PropTypes.string,
    submitButtonFontSize: PropTypes.string
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      orgType: PropTypes.string,
    }),
    pathname: PropTypes.string,
  }).isRequired,
};

export default withRouter(withStyles(styles)(UONForm));
