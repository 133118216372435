import { validateName, validatePhone } from "./validationHelpers.service";

const contactFormValidation = (values, requestLabels) => {
  const {
    ERROR_MESSAGES: {
      REFERRER_FIRST_NAME_ALPHA_ERROR,
      REFERRER_LAST_NAME_ALPHA_ERROR,
      REFERRER_JOB_TITLE_ALPHA_ERROR,
      EMAIL_DUPLICATE_ERROR_MESSAGE,
      PRIMARY_CONTACT_FIRST_NAME_ALPHA_ERROR,
      PRIMARY_CONTACT_LAST_NAME_ALPHA_ERROR,
      PRIMARY_CONTACT_JOB_ALPHA_ERROR,
      PHONE_NUMBER_INVALID_ERR_MSG,
    },
    REQUEST_INPUTS: {
      DEFAULT: {
        FORM_PRIMARY: {
          CONTACT_FIRST_VALUE,
          CONTACT_LAST_VALUE,
          CONTACT_JOB_VALUE,
          CONTACT_EMAIL_VALUE,
          CONTACT_EMAIL_CONFIRM_VALUE,
          CONTACT_PHONE_VALUE,
        },
        FORM_REFERRER: {
          SECONDARY_EMAIL_VALUE,
          SECONDARY_LAST_VALUE,
          SECONDARY_JOB_VALUE,
          CONTACT_SECONDARY_VALUE,
          SECONDARY_EMAIL_CONFIRM_VALUE,
          SECONDARY_PHONE_VALUE,
        },
      },
    },
  } = requestLabels;

  const errors = {};

  if (!validateName(values[CONTACT_FIRST_VALUE])) {
    errors[CONTACT_FIRST_VALUE] = PRIMARY_CONTACT_FIRST_NAME_ALPHA_ERROR;
  }
  if (!validateName(values[CONTACT_LAST_VALUE])) {
    errors[CONTACT_LAST_VALUE] = PRIMARY_CONTACT_LAST_NAME_ALPHA_ERROR;
  }
  if (!validateName(values[CONTACT_JOB_VALUE])) {
    errors[CONTACT_JOB_VALUE] = PRIMARY_CONTACT_JOB_ALPHA_ERROR;
  }

  if (values.secondaryContactSource === "yes") {
    if (!validateName(values[CONTACT_SECONDARY_VALUE])) {
      errors[CONTACT_SECONDARY_VALUE] = REFERRER_FIRST_NAME_ALPHA_ERROR;
    }
    if (!validateName(values[SECONDARY_LAST_VALUE])) {
      errors[SECONDARY_LAST_VALUE] = REFERRER_LAST_NAME_ALPHA_ERROR;
    }
    if (!validateName(values[SECONDARY_JOB_VALUE])) {
      errors[SECONDARY_JOB_VALUE] = REFERRER_JOB_TITLE_ALPHA_ERROR;
    }

    const pEmail = values.contactEmail;
    const pEmailConfirm = values.contactEmailConfirm;
    if (pEmail !== "" && pEmailConfirm !== "" && pEmail === pEmailConfirm) {
      // At this point we've checked primary email = primary email confirm
      // now we need to ask if secondary emails match and then that they
      // match the primary
      const sEmail = values.secondaryContactEmail;
      const sEmailConfirm = values.secondaryContactEmailConfirm;
      if (sEmail === sEmailConfirm && sEmail !== "" && sEmailConfirm !== "") {
        // Now we know the secondary email = secondary email confirm, and
        // finally we can ask if the secondary = primary
        if (sEmail === pEmail) {
          errors[SECONDARY_EMAIL_VALUE] = EMAIL_DUPLICATE_ERROR_MESSAGE;
        }
      }
    }
  }

  if (values[CONTACT_EMAIL_VALUE] !== values[CONTACT_EMAIL_CONFIRM_VALUE]) {
    errors[CONTACT_EMAIL_CONFIRM_VALUE] = "Email addresses do not match";
  }

  if (values[SECONDARY_EMAIL_VALUE] !== values[SECONDARY_EMAIL_CONFIRM_VALUE]) {
    errors[SECONDARY_EMAIL_CONFIRM_VALUE] = "Email addresses do not match";
  }

  if (values[CONTACT_PHONE_VALUE] && validatePhone(values[CONTACT_PHONE_VALUE])) {
    errors[CONTACT_PHONE_VALUE] = PHONE_NUMBER_INVALID_ERR_MSG;
  }

  if (values[SECONDARY_PHONE_VALUE] && validatePhone(values[SECONDARY_PHONE_VALUE])) {
    errors[SECONDARY_PHONE_VALUE] = PHONE_NUMBER_INVALID_ERR_MSG;
  }

  return errors;
};

export default contactFormValidation;
