import React from "react";
import PropTypes from "prop-types";
import { EmailInput, NumberInput, TextInput } from "./InputField";

const InputComponent = ({
  label,
  name,
  hint,
  errMessage,
  errMessageNumber,
  type,
  inputWidth,
}) => {
  switch (type) {
    case "text":
      return (
        <TextInput
          label={label}
          errMessage={errMessage}
          hint={hint}
          name={name}
          inputWidth={inputWidth}
        />
      );
    case "number":
      return (
        <NumberInput
          label={label}
          errMessage={errMessage}
          errMessageNumber={errMessageNumber}
          hint={hint}
          name={name}
          inputWidth={inputWidth}
        />
      );
    case "email":
      return (
        <EmailInput
          label={label}
          errMessage={errMessage}
          hint={hint}
          name={name}
          inputWidth={inputWidth}
        />
      );
    default:
      return null;
  }
};

export default InputComponent;

InputComponent.defaultProps = {
  hint: "",
  errMessage: "",
  errMessageNumber: "",
};

InputComponent.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "email", "number"]).isRequired,
  hint: PropTypes.string,
  label: PropTypes.string.isRequired,
  errMessage: PropTypes.string,
  errMessageNumber: PropTypes.string,
  inputWidth: PropTypes.oneOf([
    "input-width-20",
    "input-width-10",
    "input-width-5",
    "input-width-4",
    "input-width-3",
    "input-width-2",
    "width-full",
    "three-quarters",
    "two-thirds",
    "one-half",
    "one-third",
    "one-quarter",
  ]),
};
