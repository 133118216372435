/* eslint-disable no-unused-vars */
import { validateName } from "./validationHelpers.service";

const opportunisticFormValidation = (
  values,
  requestLabels,
  allowZeroValFOrVP = false
) => {
  const {
    ERROR_MESSAGES: {
      PRIMARY_CONTACT_FIRST_NAME_ALPHA_ERROR,
      PRIMARY_CONTACT_LAST_NAME_ALPHA_ERROR,
      PRIMARY_CONTACT_JOB_ALPHA_ERROR,
      REFERRER_JOB_ALPHA_ERROR,
      REFERRER_CONTACT_FIRST_NAME_ALPHA_ERROR,
      REFERRER_CONTACT_LAST_NAME_ALPHA_ERROR,
      REFERRER_ORG_ERROR,
    },
  } = requestLabels;
  const errors = {};

	if (parseInt(values.totalNumberOfResidents, 10) < 1) {
		errors.totalNumberOfResidents = "The total number of tests you order must be more than 0";
	}

	if (!validateName(values.contactFirstName)) {
		errors.contactFirstName = PRIMARY_CONTACT_FIRST_NAME_ALPHA_ERROR;
	}
	if (!validateName(values.contactLastName)) {
		errors.contactLastName = PRIMARY_CONTACT_LAST_NAME_ALPHA_ERROR;
	}
	if (!validateName(values.contactJobTitle)) {
		errors.contactJobTitle = PRIMARY_CONTACT_JOB_ALPHA_ERROR;
	}
	if (!validateName(values.secondaryContactFirstName)) {
		errors.secondaryContactFirstName = REFERRER_CONTACT_FIRST_NAME_ALPHA_ERROR;
	}
	if (!validateName(values.secondaryContactLastName)) {
		errors.secondaryContactLastName = REFERRER_CONTACT_LAST_NAME_ALPHA_ERROR;
	}
	if (!validateName(values.secondaryContactOrganisation)) {
		errors.secondaryContactOrganisation = REFERRER_ORG_ERROR;
	}
	if (!validateName(values.secondaryContactJobTitle)) {
		errors.secondaryContactJobTitle = REFERRER_JOB_ALPHA_ERROR;
	}
	return errors;
};

export default opportunisticFormValidation;
