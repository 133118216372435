/* eslint-disable no-unused-vars */

const communityFormValidation = (
  values
) => {
  const errors = {};

	if (parseInt(values.totalNumberOfResidents, 10) < 1) {
		errors.totalNumberOfResidents = "Number of pallets must be more than 0";
	}
	if (parseInt(values.totalNumberOfResidents, 10) > 4 && values.testingRegime === "Asymptomatic Testing on Site" ) {
		errors.totalNumberOfResidents = "Currently you can order maximum 4 pallets of test kits. Please change the number of pallets needed.";
	}
	if (parseInt(values.totalNumberOfResidents, 10) > 35 && values.testingRegime === "Asymptomatic Self-Test Collect" ) {
		errors.totalNumberOfResidents = "Currently you can order maximum 35 pallets of test kits. Please change the number of pallets needed.";
	}
	return errors;
};

export default communityFormValidation;
