/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import withStyles from "react-jss";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import "govuk-frontend/govuk/components/cookie-banner/_cookie-banner.scss";
import "govuk-frontend/govuk/all.scss";
import { useAnalytics } from "../../../service/adobe-analytics";
import { AdobeAnalyticsURL } from "../../../data/constants";

const styles = {
  hidden: {
    display: "none",
  },
};

const CookieBannerActive = ({ classes }) => {
  const path = window.location.pathname.replace("/details", "");
  const lastUrl = path.substring(path.lastIndexOf("/") + 1);
  const firstPartOfPath = path.substring(path.indexOf("/") + 1);
  const adobeFirstPartOfPath =
    firstPartOfPath === ""
      ? "uon"
      : firstPartOfPath !== lastUrl
      ? firstPartOfPath.replace(lastUrl, "").replace("/", "")
      : firstPartOfPath;
  const extraUrl =
    adobeFirstPartOfPath === "uon" || adobeFirstPartOfPath === lastUrl
      ? ""
      : ":" + lastUrl;
  const subCategoryUrl =
    adobeFirstPartOfPath === "uon" || adobeFirstPartOfPath === lastUrl
      ? ""
      : lastUrl;
  const correctFirst = adobeFirstPartOfPath
    .replace(lastUrl, "")
    .replace("/", "");
  const found = localStorage.getItem("src");
  const location = useLocation();
  // --------------------------------------- Dynamic Text and Headings----------------------------------------
  const history = useHistory();

  const messages = [
    {
      actions: [
        {
          children: "Accept analytics cookies",
          name: "cookies",
          type: "submit",
          value: "true",
        },
        {
          children: "Reject analytics cookies",
          name: "cookies",
          type: "submit",
          value: "false",
        },
        {
          children: "View cookies",
          href: "/choose-cookies",
        },
      ],
      children:
        "We use analytics cookies so we can understand how you use the service and make improvements.",
      headingChildren: "Cookies on the GOV.UK website",
    },
  ];

  const [loaded, setLoaded] = useState(false);
  const [cookieFlag, setCookieFlag] = useState(null);
  const [hideBanner, setHideBanner] = useState("");
  const { triggerEvent } = useAnalytics();
  const hideBannerFunction = () => {
    setHideBanner("hidden");
  };
  const adobeAnalyticsURL = AdobeAnalyticsURL;

  const onSubmit = (e, value) => {
    e.preventDefault();
    const pathname = window.location.pathname;

    const analyticsData = {
      page: {
        pageInfo: {
          pageName: `nhs:test:organisation-order:${adobeFirstPartOfPath}${extraUrl}`,
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: `${adobeFirstPartOfPath}`,
          subCategory2: `${subCategoryUrl}`,
        },
      },
    };

    const boolean =
      e.target.value === "true"
        ? true
        : e.target.value === "false"
        ? false
        : null;
    setCookieFlag(boolean);

    if (e.target.value) {
      if (boolean === true) {
        const loadScript = (callback) => {
          const existingScript = document.getElementById("adobeAnalytics");

          if (!existingScript) {
            const script = document.createElement("script");
            script.src = adobeAnalyticsURL;
            script.id = "adobeAnalytics";
            script.setAttribute("type", "text/javascript");
            document.body.appendChild(script);
            const storage = localStorage.setItem("src", adobeAnalyticsURL);

            localStorage.setItem("answer", true);
            localStorage.removeItem("answerNo");
            triggerEvent(analyticsData);
            script.onload = () => {
              if (callback) callback();
            };
          }
          if (existingScript && callback) callback();
        };

        loadScript(() => {
          setLoaded(true);
        });
      } else {
        localStorage.removeItem("answer");
        localStorage.setItem("answerNo", true);

        const removeScript = () => {
          const existingScript = document.getElementById("adobeAnalytics");
          const scriptListTwo = document.querySelectorAll("script");
          const convertedNodeList = Array.from(scriptListTwo);
          const testScript = convertedNodeList.find(
            (script) => script.id === "adobeAnalytics"
          );
          (function () {
            const cookies = document.cookie.split("; ");
            for (let c = 0; c < cookies.length; c++) {
              const d = window.location.hostname.split(".");
              while (d.length > 0) {
                const cookieBase =
                  encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
                  "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
                  d.join(".") +
                  " ;path=";
                const p = location.pathname.split("/");
                document.cookie = cookieBase + "/";
                while (p.length > 0) {
                  document.cookie = cookieBase + p.join("/");
                  p.pop();
                }
                d.shift();
              }
            }
          })();
          if (existingScript) {
            testScript.parentNode.removeChild(testScript);
          }
        };

        removeScript(() => {
          setLoaded(false);
        });
      }
    }
  };

  return (
    <div className={hideBanner === "hidden" ? classes.hidden : ""}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, value }) => {
          return (
            <form onSubmit={handleSubmit}>
              {messages.map((messages, index) => (
                <div key={index} className={classes} onClick={onSubmit}>
                  {cookieFlag === null && (
                    <div
                      className="govuk-cookie-banner"
                      role="region"
                      aria-label="Cookies on [name of service]"
                    >
                      <div className="govuk-cookie-banner__message govuk-width-container">
                        <div className="govuk-grid-row">
                          <div className="govuk-grid-column-two-thirds">
                            <h2 className="govuk-cookie-banner__heading govuk-heading-m">
                              Cookies on the GOV.UK website
                            </h2>

                            <div className="govuk-cookie-banner__content"></div>
                          </div>
                        </div>
                        <p>
                          {" "}
                          We use analytics cookies so we can understand how you
                          use the service and make improvements.
                        </p>

                        <div className="govuk-button-group">
                          <div data-automatedtestingid="orderform-cookiebanner-button-acceptCookies">
                            <button
                              type="button"
                              className="govuk-button"
                              name="yes"
                              data-module="govuk-button"
                              value="true"
                            >
                              Accept analytics cookies
                            </button>
                          </div>
                          <div data-automatedtestingid="orderform-cookiebanner-button-rejectCookies">
                            <button
                              type="button"
                              className="govuk-button"
                              data-module="govuk-button"
                              value="false"
                            >
                              Reject analytics cookies
                            </button>
                          </div>
                          <div data-automatedtestingid="orderform-cookiebanner-a-viewCookies">
                            <a
                              className="govuk-link"
                              href="#"
                              onClick={() => {
                                setCookieFlag(null);
                                history.push({
                                  pathname: "/choose-cookies",
                                });
                              }}
                            >
                              View cookies
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {cookieFlag === true && (
                    <div
                      className="govuk-cookie-banner"
                      role="region"
                      aria-label="Cookies on [name of service]"
                    >
                      <div className="govuk-cookie-banner__message govuk-width-container">
                        <div className="govuk-grid-row">
                          <div className="govuk-grid-column-two-thirds">
                            <div className="govuk-cookie-banner__content">
                              <p>
                                You have accepted analytics cookies. You can{" "}
                                <a
                                  className="govuk-link"
                                  href="#"
                                  data-automatedtestingid="orderform-cookiebanner-accepted-a-changeCookies"
                                  onClick={() =>
                                    history.push({
                                      pathname: "/choose-cookies",
                                    })
                                  }
                                >
                                  change your cookie settings
                                </a>{" "}
                                at any time.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="govuk-button-group">
                          <div data-automatedtestingid="orderform-cookiebanner-accepted-a-hideMessage">
                            <button
                              onClick={hideBannerFunction}
                              className="govuk-button"
                              data-module="govuk-button"
                            >
                              Hide this message
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {cookieFlag === false && (
                    <div
                      className="govuk-cookie-banner"
                      role="region"
                      aria-label="Cookies on [name of service]"
                    >
                      <div className="govuk-cookie-banner__message govuk-width-container">
                        <div className="govuk-grid-row">
                          <div className="govuk-grid-column-two-thirds">
                            <div className="govuk-cookie-banner__content">
                              <p>
                                You have rejected analytics cookies. You can{" "}
                                <a
                                  className="govuk-link"
                                  href="#"
                                  data-automatedtestingid="orderform-cookiebanner-rejected-a-changeCookies"
                                  onClick={() =>
                                    history.push({
                                      pathname: "/choose-cookies",
                                    })
                                  }
                                >
                                  change your cookie settings
                                </a>{" "}
                                at any time.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="govuk-button-group">
                          <div data-automatedtestingid="orderform-cookiebanner-rejected-a-hideMessage">
                            <button
                              onClick={hideBannerFunction}
                              className="govuk-button"
                              data-module="govuk-button"
                              type="button"
                            >
                              Hide this message
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </form>
          );
        }}
      />
    </div>
  );
};
export default withRouter(withStyles(styles)(CookieBannerActive));

CookieBannerActive.propTypes = {
  classes: PropTypes.shape({
    hidden: PropTypes.string,
  }).isRequired,
};
