/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from "react";
import withStyles from "react-jss";
import { Button, GridCol, GridRow, H1, H4, Paragraph } from "govuk-react";
import PropTypes from "prop-types";
import { useHistory, withRouter } from "react-router-dom";
import { Form } from "react-final-form";
import { useAnalytics, ErrorSummary } from "@cube/global-components";
import { RadioComponent } from "../Inputs";
import HelperService from "../../service/helpers";
import { FormContext } from "../../data/contexts/contexts";
import { RequestTranslation } from "../../service/translation";

const styles = {
  gridMargin: {
    marginBottom: "30px",
  },
  marginBottom70: {
    marginBottom: "70px",
  },
  marginBottom10: {
    marginBottom: "10px",
  },
  fakeSize: {
    width: "auto",
  },
  formError: {
    paddingLeft: "15px",
    borderLeft: "5px solid #d4351c",
  },
  formErrMsg: {
    color: "#d4351c",
    fontSize: "1.1875rem",
    lineHeight: "1.31579",
    marginBottom: "15px",
    fontWeight: "700",
  },
  "@media (min-width: 1020px)": {
    fakeSize: {
      width: "960px",
    },
  },
  hidden: {
    display: "none",
  },
  fieldset: {
    padding: 0,
    border: 0,
    margin: 0,
  },
  heading1: {
    marginBottom: "20px",
  },
  heading4: {
    fontWeight: 200,
  }
};
const RequestLabels = RequestTranslation();

const {
  MULTIPLE_ADDRESS: {
    MULTIPLEADDRESS_HELP,
    MULTIPLEADDRESS_HELP_COMMUNITY,
    MULTIPLEADDRESS_HELP_COMMUNITY_PART_2,
    MULTIPLEADDRESS_HELP_COMMUNITY_PART_3,
  },
} = RequestLabels;
const MultipleAddress = ({ classes }) => {
  const { triggerEvent } = useAnalytics();
  const history = useHistory();
  const [formContext, setFormContext] = useContext(FormContext);
  const [errMessage, setErrorMsg] = useState(null);
  const [errorArray, setErrorArray] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(
    formContext?.orderAddressId
  );
  const addresses = formContext?.crqData?.Addresses;
  const crqData = formContext?.crqData || null;
  const pageLocation = history.location?.state?.fromSummary
    ? "./confirm-choices"
    : "./test-numbers";

  const windowAllowed = localStorage.getItem("answer");
  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: "nhs:test:organisation-order:address",
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "address",
        },
      },
    };
    triggerEvent(analyticsData);
  } else {
    window.digitalData = null;
  }

  let addressChoose = {};
  const optionsSix = [];
  let county = {};
  let streets = {};
  let town = {};
  let postcodeSpaced = {};
  let country = {};
  addresses.forEach((address) => {
    streets = address.addressStreet
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    town = address.addressCity
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    addressChoose = `${streets}, ${town}`;
    postcodeSpaced = address.addressPostcode
      .split(/(?=.{3}$)/)
      .join(" ")
      .toUpperCase();
    country = address.addressCountry
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    if (address.addressCounty === "") {
      addressChoose = `${addressChoose}, ${postcodeSpaced}, ${country}`;
    } else {
      county = address.addressCounty
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
      addressChoose = `${addressChoose}, ${county}, ${postcodeSpaced}, ${country}`;
    }

    optionsSix.push({ value: address.addressId, label: addressChoose });
  });

  const onChangeValue = (e) => setSelectedAddress(e.target.value);

  const onSubmit = (value) => {
    let orderAddressId;
    if (!value.orderAddressId) {
      if (selectedAddress) {
        orderAddressId = selectedAddress;
      }
    }
    if (value.orderAddressId || orderAddressId) {
      orderAddressId =
        value.orderAddressId !== selectedAddress
          ? selectedAddress
          : value.orderAddressId;
      setFormContext({
        ...formContext,
        orderAddressId,
        pageLocation,
      });

      history.push({ pathname: pageLocation });
    } else {
      setErrorMsg("Select an address");
      setErrorArray([
        { targetName: "orderAddressId", text: "Select an address" },
      ]);
    }
  };
  const emailLinkRender = ({ href, children }) => <a href={href}>{children}</a>;

  const getContactInfotext = () => {
    if (formContext?.testingApproach === "Community Testing") {
      return (
        <div>
          <Paragraph linkRenderer={emailLinkRender}>
            {MULTIPLEADDRESS_HELP_COMMUNITY}
          </Paragraph>
          <Paragraph>{MULTIPLEADDRESS_HELP_COMMUNITY_PART_2}</Paragraph>
          <Paragraph>{MULTIPLEADDRESS_HELP_COMMUNITY_PART_3}</Paragraph>
        </div>
      );
    }
    return (
      <div>
        <Paragraph>{MULTIPLEADDRESS_HELP}</Paragraph>
      </div>
    );
  };

  return (
    <>
      {errMessage && (
        <GridRow>
          <GridCol setWidth="two-thirds">
            <ErrorSummary
              automationId="order-form-error"
              errors={errorArray}
              heading="There is a problem"
              onHandleErrorClick={HelperService.onHandleErrorClick}
              errorMessage={errMessage}
            />
          </GridCol>
        </GridRow>
      )}
      <GridRow>
        <GridCol setWidth="two-thirds">
          <header>
            <H1 className={classes.heading1}>Select a delivery address</H1>
          </header>
        </GridCol>
      </GridRow>
      {crqData.OrganisationName &&
      <GridRow>
        <GridCol setWidth="two-thirds">
          <H4 className={classes.heading4} data-automatedtestingId="orderform-multipleaddress-accountname">{crqData.OrganisationName}</H4>
        </GridCol>
      </GridRow>
      }
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => {
          return (
            <div data-automatedtestingid="orderform-multipleaddress-form">
              <form onSubmit={handleSubmit}>
                <div className={errMessage ? classes.formError : ""}>
                  {errMessage && (
                    <div className={classes.formErrMsg}>{errMessage}</div>
                  )}
                  <GridRow className={classes.gridMargin}>
                    <GridCol setWidth="width-full">
                      <fieldset className={classes.fieldset}>
                        <legend className={classes.hidden}>Select a delivery address</legend>
                        {optionsSix.map((option) => (
                          <div
                            className={classes}
                            data-automatedtestingid={`orderform-address-${option.value}`}
                            key={option.label}
                            onClick={onChangeValue}
                          >
                            <RadioComponent
                              componentChecked={
                                (option.value === selectedAddress && true) ||
                                false
                              }
                              name="orderAddressId"
                              label={option.label}
                              value={option.value}
                              automatedtestingId="orderform-multipleaddress-orderAddressId"
                            />
                          </div>
                        ))}
                      </fieldset>
                    </GridCol>
                  </GridRow>
                </div>
                <Button
                  onClick={onSubmit}
                  data-automatedtestingid="orderform-continue-button"
                >
                  Continue
                </Button>
              </form>
            </div>
          );
        }}
      />
      <GridRow>
        <GridCol setWidth="two-thirds">{getContactInfotext()}</GridCol>
      </GridRow>
    </>
  );
};

export default withRouter(withStyles(styles)(MultipleAddress));

MultipleAddress.propTypes = {
  classes: PropTypes.shape({
    fieldset: PropTypes.string,
    hidden: PropTypes.string,
    gridMargin: PropTypes.string,
    marginBottom10: PropTypes.string,
    marginBottom50: PropTypes.string,
    marginBottom70: PropTypes.string,
    fakeSize: PropTypes.string,
    formError: PropTypes.string,
    formErrMsg: PropTypes.string,
  }).isRequired,
};
