/* eslint-disable no-else-return */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { Button, GridCol, GridRow } from "govuk-react";
import { Form } from "react-final-form";
import { useAnalytics, ErrorSummary } from "@cube/global-components";
import { RequestTranslation } from "../../service/translation";
import TrainingConfirmationFields from "./TrainingConfirmationFields/TrainingConfirmationFields";
import { FormContext } from "../../data/contexts/contexts";
import trainingConfirmationValidation from "../../service/validation/trainingConfirmationValidation.service";
import HelperService from "../../service/helpers/index";

const styles = {
  title: {
    marginBottom: "40px !important",
  },
  gridMargin: {
    marginTop: "20px !important",
  },
  postCode: {
    width: "40%",
  },
  postCodeInput: {
    flexBasis: "50%",
  },
  buttonRight: {
    paddingLeft: "5px",
  },
  h2Margin: {
    "& h2": {
      margin: "30px 0px 0px 0px !important",
    },
  },
  autoCompleteError: {
    borderLeft: "4px solid #d4351c",
    paddingLeft: "10px",
    "& input": {
      border: "4px solid #d4351c",
    },
  },
  secondaryButton: {
    flex: 1,
    backgroundColor: "#f3f2f1 !important",
    color: "#000 !important",
    boxShadow: "0 2px 0 #929191 !important",
    height: "40px",
    fontSize: "16px",
    top: "40px",
    left: "5px",
    width: "50%",
    flexBasis: "50%",
    marginBottom: "0px",
    borderLeft: "50px",
    padding: "5px",
    "&:hover, &:active, &:focus": {
      top: "40px !important",
      color: "#000",
      backgroundColor: "#dbdad9 !important",
    }
  },
  formError: {
    paddingLeft: "15px",
    borderLeft: "4px solid #d4351c",
  },
  changeButton: {
    color: "#1d70b8",
    paddingLeft: "8px",
    fontSize: "1.1875rem",
  },
  changeButtonTwo: {
    color: "#1d70b8",
    fontSize: "1.1875rem",
  },
  hidden: {
    display: "none",
  },
  row: {
    flex: 1,
    borderRadius: 2,
    marginHorizontal: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexShrink: 0,
  },
  ul: {
    list_style_type: "none",
  },
  marginBottom0: {
    marginBottom: "0",
  },
  marginBottom20: {
    marginBottom: "20px !important",
  },
  formErrMsg: {
    color: "#d4351c",
    fontSize: "1.1875rem",
    lineHeight: "1.31579",
    marginBottom: "15px !important",
    fontWeight: "700",
  },
};

const TrainingConfirmationForm = ({
  classes,
  handleSubError,
  handleLoader,
}) => {
  const { triggerEvent } = useAnalytics();
  const [errMessage, setErrorMsg] = useState(null);
  const [errorArray, setErrorArray] = useState([]);
  const [formContext, setFormContext] = useContext(FormContext);
  const orgData = formContext?.crqData || null;
  const orgType = formContext?.orgType || null;
  const history = useHistory();
  const pageLocation = (history.location?.state?.fromSummary) ? './confirm-choices' : './contact';
  const [userAggErrorMessage, setUserAggErrorMessage] = useState(null);
  useEffect(() => {
    return () => {
      if (history.action === "POP" && history.location.pathname === '/weekendtesting') {
        history.goBack();
      }
    };
  }, []);

  const RequestLabels = RequestTranslation(orgType);
  const {
    REQUEST_INPUTS: { FORM_SUBMIT_BTN_CONTINUE },
  } = RequestLabels;

  const windowAllowed = localStorage.getItem("answer");
 
  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: `nhs:test:organisation-order:training-confirmation`,
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "training-confirmation",
        },
      },
    };
    triggerEvent(analyticsData);
  } else {
    window.digitalData = null;
  }

  const validateFields = () => {
    const {
      trainingForm: { elements },
    } = window;
    let errorArr = [];
    
    const chkbox = elements.trainingConfirmation.checked;
  
    if (chkbox === false) {
      errorArr.push({
        targetName: "trainingConfirmation",
        text: "Select to continue",
      });
      setUserAggErrorMessage(true);
    } else {
      errorArr = [];
      setUserAggErrorMessage(false);
    }

    // #10136 set the error array outside of the check
    setErrorArray(errorArr);

    if (errorArr.length > 0) {
      setErrorMsg("There is a problem");
      return false;
    } else {
      // #10136 set the err message to blank if there are no errors
      setErrorMsg(null);

      return true;
    }
  };

  const onSubmit = (value) => {
    // const finalValueToPersist = { ...value };
    const {
      trainingForm: { elements },
    } = window;

    if (validateFields()) {
      let tickState = false;
      if (value.trainingConfirmation !== undefined) {
        tickState = Boolean(value.trainingConfirmation);
      } else {
        tickState = formContext.trainingConfirmation || false;
      }

      setFormContext({
        ...formContext,
        // ...finalValueToPersist,
        trainingConfirmation: tickState,
        pageLocation,
      });

      handleLoader(true);

      history.push({
        pathname: "confirm-choices"
      });

      handleLoader(false);
      handleSubError(null);
    }
  };

  return (
    <div id="training-form">
      {errMessage && (
        <GridRow>
          <GridCol setWidth="two-thirds">
            <ErrorSummary
              heading="There is a problem"
              errors={errorArray}
              onHandleErrorClick={HelperService.onHandleErrorClick}
              errorMessage={errMessage}
            />
          </GridCol>
        </GridRow>
      )}
      <Form
        onSubmit={onSubmit}
        initialValues={formContext}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} id="trainingForm">
              <TrainingConfirmationFields
                orgType={orgType}
                testingApproach={orgData.TestingApproach}
                testingType={orgData.TestType}
                submitUserAggErrorMessage={userAggErrorMessage}
              />

              <Button
                onClick={(event) => onSubmit(event)}
                data-automatedtestingid="continue-button"
                className={`${classes.gridMargin} ${classes.submitButtonFontSize}`}
              >
                Continue
              </Button>
            </form>
          );
        }}
      />
    </div>
  );
};

export default withRouter(withStyles(styles)(TrainingConfirmationForm));

TrainingConfirmationForm.defaultProps = {};

TrainingConfirmationForm.propTypes = {
  classes: PropTypes.shape({
    gridMargin: PropTypes.string,
    submitButtonFontSize: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      crqData: PropTypes.shape({
        City: PropTypes.string,
        OrganisationName: PropTypes.string,
        Postcode: PropTypes.string,
        Street: PropTypes.string,
        TestingApproach: PropTypes.string,
        cqcRegistrationID: PropTypes.string,
      }).isRequired,
      crqId: PropTypes.string.isRequired,
      orgType: PropTypes.string.isRequired,
    }),
  }).isRequired,
  handleSubError: PropTypes.func.isRequired,
  handleLoader: PropTypes.func.isRequired,
};
