import React, { useContext, useState } from "react";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { Button, GridCol, GridRow, H1, ListItem, LoadingBox, Main, Paragraph, UnorderedList } from "govuk-react";

import { useAnalytics } from "../../../service/adobe-analytics";
import { FormContext } from "../../../data/contexts/contexts";


const styles = {
  gridMargin: {
    marginTop: "5px",
  },
  SubmitButtonFontSize: {
    fontSize: "0.95em",
  },
  formError: {
    paddingLeft: "15px",
    borderLeft: "5px solid #d4351c",
  },
  formErrMsg: {
    color: "#d4351c",
    fontSize: "1.1875rem",
    lineHeight: "1.31579",
    marginBottom: "15px",
    fontWeight: "700",
  },
};

const CookieConfirm = ({ classes }) => {
  const [formContext, setFormContext] = useContext(FormContext);
  const windowAllowed = localStorage.getItem("answer")
  const windowNotAllowed = localStorage.getItem("answerNo");

  
  const { triggerEvent } = useAnalytics();
  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: "nhs:test:organisation-order:cookies-saved",
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "cookies-saved",
        },
      },
    };
    triggerEvent(analyticsData);
  } 
  else {
    window.digitalData = null;
  }

  const finalLocation = localStorage.getItem("endOfForm")
  const restartForm = finalLocation  === "true" ? true : null ;
  
  const previousLocation = formContext.pageLocation === undefined || restartForm === true ?  "/" : formContext.pageLocation ;
  const lastCookiePage = window.location.pathname;

  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const ReactRouterLinkRenderer = ({ href, children }) =>
    href.match(/^\//) ? (
      <Link to={href}>{children}</Link>
    ) : (
      <a href={href}>{children}</a>
    );

  const onConfirm = () => {
    localStorage.removeItem("endOfForm")
    setFormContext({
      ...formContext,
      confirmLocation: lastCookiePage

    });
    history.push({
      pathname: `${previousLocation}`
      
    });
  };

  return (
    <LoadingBox
      loading={loader}
      backgroundColor="#fff"
      timeIn={100}
      timeOut={100}
      backgroundColorOpacity={0.85}
      spinnerColor="#000"
    >
      <Main className={[classes.w75p, classes.zeroPadding, classes.w100p]}>
        <GridRow className={classes.headingRow}>
          <GridCol setWidth="two-thirds">
            <header>
              <H1>Your cookie settings have been saved</H1>
            </header>
            <Paragraph>We&apos;ll save your settings for a year.</Paragraph>
            <Paragraph>
              We&apos;ll ask you if you&apos;re still OK with us using cookies when either:
            </Paragraph>
            <UnorderedList>
              <ListItem>
                it&apos;s been a year since you last saved your settings
              </ListItem>
              <ListItem>
                we add any new cookies or change the cookies we use
              </ListItem>
            </UnorderedList>
            <Paragraph linkRenderer={ReactRouterLinkRenderer}>
              You can also change your settings at any time using our [cookies
              page.](/cookies)
            </Paragraph>
          </GridCol>
        </GridRow>
        <GridRow className={classes.buttonRow}>
          <GridCol>
            <Button onClick={onConfirm} data-automatedtestingid="orderform-continue-button" className={classes.urnSubmit}>
              Continue
            </Button>
          </GridCol>
        </GridRow>
      </Main>
    </LoadingBox>
  );
};
export default withRouter(withStyles(styles)(CookieConfirm));

CookieConfirm.propTypes = {
  classes: PropTypes.shape({
    buttonRow: PropTypes.string,
    headingRow: PropTypes.string,
    w75p: PropTypes.string,
    sectionBreakMargin: PropTypes.string,
    paddingTop025: PropTypes.string,
    urnSubmit: PropTypes.string,
    zeroPadding: PropTypes.string,
    w100p: PropTypes.string,
    w62p: PropTypes.string,
  }).isRequired,
};
