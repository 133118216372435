/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-script-url */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import withStyles from "react-jss";
import {
  GridCol,
  GridRow,
  H3,
  HintText,
  DateField,
  MultiChoice,
} from "govuk-react";
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextAreaComponent, TimeField } from "@cube/global-components";
import { RadioComponent, CheckboxComponent } from "../Inputs";
import { FormContext } from "../../data/contexts/contexts";
import CharRestrictionSets from "../../data/constants/charRestrictionSets.constants";

const styles = {
  gridRow: {
    marginTop: "40px",
  },
  h3Margin: {
    marginBottom: 0,
  },
  formError: {
    paddingLeft: "15px",
    borderLeft: "4px solid #d4351c",
  },
  formErrMsg: {
    color: "#d4351c",
    fontSize: "1.1875rem",
    lineHeight: "1.31579",
    marginBottom: "15px",
    fontWeight: "700",
  },
  hidden: {
    display: "none",
  },
  fieldset: {
    padding: 0,
    border: 0,
    margin: 0,
  },
};

const DeliveryInstructionsFields = ({
  classes,
  form,
  pageErrors,
  fieldsValues,
  helperMethods,
}) => {
  const [formContext, setFormContext] = useContext(FormContext);
  const [selectedOpenDays, setOpenDays] = useState(
    formContext?.selectedOpenDays || []
  );
  const [selectedOffloadFacility, setOffloadFacility] = useState(
    formContext?.selectedOffloadFacility || null
  );
  const [selectedVehicleRestrictions, setVehicleRestrictions] = useState(
    formContext?.selectedVehicleRestrictions || []
  );
  const [deliveryDate, setDeliveryDate] = useState(
    formContext?.deliveryDate || {}
  );

  const daysSiteIsOpen = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const vehicleRestrictions = [
    "No articulated lorry access",
    "Parking restrictions in force",
    "Other",
  ];
  const offloadFacilities = [
    {
      label: "Forklift available on site",
      value: "Forklift available on site",
    },
    {
      label: "Vehicle with tail-lift required",
      value: "Vehicle with tail-lift required",
    },
    { label: "Other", value: "Other" },
  ];
  const isOtherValue = helperMethods.isOther(
    selectedOffloadFacility,
    selectedVehicleRestrictions
  );

  useEffect(() => {
    const values = {
      selectedOffloadFacility,
      selectedVehicleRestrictions,
      selectedOpenDays,
      deliveryDate,
    };
    fieldsValues(values);
  }, [
    selectedOffloadFacility,
    selectedVehicleRestrictions,
    selectedOpenDays,
    deliveryDate,
  ]);

  const handleOffloadChange = (offloadFacility) => {
    setOffloadFacility(offloadFacility);
  };

  const handleDateChange = (field) => {
    setDeliveryDate({
      ...deliveryDate,
      ...field,
    });
  };

  return (
    <>
      <GridRow className={classes.gridRow}>
        <GridCol setWidth="two-thirds">
          <div
            className={
              helperMethods.getFieldError("orderOpenHour", pageErrors)
                ? `${classes.formError} ${classes.h3Margin}`
                : ""
            }
          >
            <H3
              className={classes.h3Margin}
              data-errorscrolltarget="orderOpenHour"
            >
              Site open
            </H3>
            <HintText>
              For example, 14:30. Midday is 12:00 and midnight is 00:00.
            </HintText>
            <GridRow>
              <GridCol setWidth="two-thirds">
                <TimeField
                  hourLabel="Hour"
                  minuteLabel="Minute"
                  hourName="orderOpenHour"
                  minuteName="orderOpenMinute"
                  hourAutomationTestingID="site-open-hour"
                  minuteAutomationTestingID="site-open-minute"
                  error={
                    helperMethods.getFieldError("orderOpenHour", pageErrors) ||
                    ""
                  }
                  initialValues={{
                    hour: formContext?.orderOpenHour,
                    minute: formContext?.orderOpenMinute,
                  }}
                />
              </GridCol>
            </GridRow>
          </div>
        </GridCol>
      </GridRow>

      <GridRow className={classes.gridRow}>
        <GridCol setWidth="two-thirds">
          <div
            className={
              helperMethods.getFieldError("orderCloseHour", pageErrors)
                ? `${classes.formError} ${classes.h3Margin}`
                : ""
            }
          >
            <H3
              className={classes.h3Margin}
              data-errorscrolltarget="orderCloseHour"
            >
              Site closed
            </H3>
            <HintText>
              For example, 14:30. Midday is 12:00 and midnight is 00:00.
            </HintText>
            <GridRow>
              <GridCol setWidth="two-thirds">
                <TimeField
                  hourLabel="Hour"
                  minuteLabel="Minute"
                  hourName="orderCloseHour"
                  minuteName="orderCloseMinute"
                  hourAutomationTestingID="site-closed-hour"
                  minuteAutomationTestingID="site-closed-minute"
                  error={
                    helperMethods.getFieldError("orderCloseHour", pageErrors) ||
                    ""
                  }
                  initialValues={{
                    hour: formContext?.orderCloseHour,
                    minute: formContext?.orderCloseMinute,
                  }}
                />
              </GridCol>
            </GridRow>
          </div>
        </GridCol>
      </GridRow>

      <GridRow className={classes.gridRow}>
        <GridCol setWidth="two-thirds">
          <div
            className={
              helperMethods.getFieldError("daysOpen", pageErrors)
                ? `${classes.formError} ${classes.h3Margin}`
                : ""
            }
          >
            <H3 className={classes.h3Margin} data-errorscrolltarget="daysOpen">
              Days site is open
            </H3>
            <HintText>
              Select the days the delivery would be possible for the site.
            </HintText>
            {helperMethods.getFieldError("daysOpen", pageErrors) && (
              <div
                className={classes.formErrMsg}
                data-automatedtestingid="orderform-deliveryInstructions-daysSiteOpen-error"
              >
                {helperMethods.getFieldError("daysOpen", pageErrors)}
              </div>
            )}
            <MultiChoice>
              {daysSiteIsOpen &&
                daysSiteIsOpen.map((day, index) => {
                  return (
                    <CheckboxComponent
                      key={index}
                      componentChecked={selectedOpenDays.includes(day)}
                      name="daysOpen"
                      text={day}
                      label=""
                      className=""
                      automatedtestingId={`orderform-deliveryInstructions-daysSiteOpen-${index}`}
                      onChange={() =>
                        helperMethods.handleOpenDays(
                          selectedOpenDays,
                          day,
                          daysSiteIsOpen,
                          setOpenDays
                        )}
                    />
                  );
                })}
            </MultiChoice>
          </div>
        </GridCol>
      </GridRow>

      <GridRow className={classes.gridRow}>
        <GridCol setWidth="two-thirds">
          <div
            className={
              helperMethods.getFieldError("orderOffloadFacilities", pageErrors)
                ? `${classes.formError} ${classes.h3Margin}`
                : ""
            }
          >
            <H3
              className={classes.h3Margin}
              data-errorscrolltarget="orderOffloadFacilities"
            >
              Offload facilities
            </H3>
            <HintText>
              Provide details of your facilities for unloading a delivery.
              Select one of the following.
            </HintText>
            {helperMethods.getFieldError(
              "orderOffloadFacilities",
              pageErrors
            ) && (
              <div
                className={classes.formErrMsg}
                data-automatedtestingid="orderform-deliveryInstructions-orderOffloadFacilities-error"
              >
                {helperMethods.getFieldError(
                  "orderOffloadFacilities",
                  pageErrors
                )}
              </div>
            )}
            {offloadFacilities &&
              offloadFacilities.map((option, index) => {
                return (
                  <fieldset className={classes.fieldset}>
                    <legend className={classes.hidden}>
                      Offload facilities
                    </legend>
                    <RadioComponent
                      key={index}
                      componentChecked={
                        selectedOffloadFacility === option.value ? true : false
                      }
                      name="orderOffloadFacilities"
                      label={option.label}
                      value={option.value}
                      automatedtestingId={`orderform-deliveryInstructions-orderOffloadFacilities-${index}`}
                      onChange={() => handleOffloadChange(option.value)}
                    />
                  </fieldset>
                );
              })}
          </div>
        </GridCol>
      </GridRow>

      <GridRow className={classes.gridRow}>
        <GridCol setWidth="two-thirds">
          <H3 className={classes.h3Margin}>Vehicle restrictions</H3>
          <HintText>Select any of the following that apply.</HintText>
          <MultiChoice>
            {vehicleRestrictions &&
              vehicleRestrictions.map((restriction, index) => {
                return (
                  <CheckboxComponent
                    key={index}
                    componentChecked={selectedVehicleRestrictions.includes(
                      restriction
                    )}
                    name="vehicleRestrictions"
                    text={restriction}
                    label=""
                    className=""
                    automatedtestingId={`orderform-deliveryInstructions-vehicleRestrictions-${index}`}
                    onChange={() =>
                      helperMethods.handleVehicleChange(
                        selectedVehicleRestrictions,
                        restriction,
                        vehicleRestrictions,
                        setVehicleRestrictions
                      )}
                  />
                );
              })}
          </MultiChoice>
        </GridCol>
      </GridRow>

      <GridRow className={classes.gridRow}>
        <GridCol setWidth="two-thirds">
          <TextAreaComponent
            className={classes.h3Margin}
            errorMsgAlternative={`Other information must not include `}
            dataMaxlength="255"
            maxLength={255}
            label={(
              <H3
                className={classes.h3Margin}
                data-errorscrolltarget="uvRestrictionInformation"
              >
                Other information
              </H3>
            )}
            name="uvRestrictionInformation"
            hint="Include any further instructions useful to preventing a failed delivery. (optional)"
            width="input-width-20"
            restrictedCharacters={
              CharRestrictionSets.DeliveryInstructionsForm
                .uvRestrictionInformation
            }
            automatedtestingId="orderform-deliveryInstructions-uvRestrictionInformation"
            showCharactersText
          />
        </GridCol>
      </GridRow>

      <GridRow className={classes.gridRow}>
        <GridCol setWidth="two-thirds">
          <DateField
            hintText="Your preferred date will be taken in consideration but is not guaranteed."
            name="deliveryDate"
            errorText={
              helperMethods.getFieldError("deliveryDay", pageErrors) || ""
            }
            inputNames={{
              day: "deliveryDay",
              month: "deliveryMonth",
              year: "deliveryYear",
            }}
            data-automatedtestingid="orderform-deliveryInstructions-deliveryDate"
            defaultValues={deliveryDate}
            onInput={(e) => helperMethods.handleDateInput(e)}
            input={{
              onChange: (field) => handleDateChange(field),
            }}
          >
            <H3
              className={classes.h3Margin}
              data-errorscrolltarget="deliveryDay"
            >
              Suggested delivery date
            </H3>
          </DateField>
        </GridCol>
      </GridRow>
    </>
  );
};

export default withStyles(styles)(DeliveryInstructionsFields);

DeliveryInstructionsFields.defaultProps = {};

DeliveryInstructionsFields.propTypes = {
  classes: PropTypes.shape({
    gridMargin: PropTypes.string,
    gridRow: PropTypes.string,
    gridRow: PropTypes.string,
    h3Margin: PropTypes.string,
    formError: PropTypes.string,
    formErrMsg: PropTypes.string,
  }).isRequired,
  pageErrors: PropTypes.array,
  fieldsValues: PropTypes.func.isRequired,
};
