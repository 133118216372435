import Axios from "./api.service";

/**
 * HTTP service capable of performing GET and POST requests
 * This service will be injected into domain services (e.g. PatientService, MedicationService)
 * Agnostic of prototype/production
 */
export const _request = (
  url,
  data,
  options,
  method = "GET",
  responseType = "json"
) => {
  const defaultOptions = {
    method,
    url,
    responseType,
  };

  if (data) {
    defaultOptions.data = JSON.stringify(data);
    if (url !== "checkGRFactor") {
      defaultOptions.headers = {
        "Content-Type": "application/json",
      };
    }
  }

  let requestOptions = defaultOptions;
  if (options) {
    requestOptions = Object.assign(defaultOptions, options);
  }

  // Resolve the original request, and wrap the response in another promise.
  // This allows allows us to peer into the response before giving it back
  // to the caller, which is helpful when handling situations where a response
  // is technically successful from an AJAX perspective (200 OK), but failed
  // server-side due an arbitrary error (i.e. validation error).
  return new Promise((resolve, reject) => {
    Axios.request(requestOptions)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (!error) {
          reject(new Error("An unknown error occurred"));
        }
        reject(error);
      });
  });
};

export const DataAccessService = {
  sendUON(uon) {
    return _request(`checkCQCRegistration?registrationNo=${uon}`);
  },

  sendRequestForm(data) {
    return _request("checkCQCRegistration", data, {}, "POST");
  },

  sendOrderCancellation(data) {
    return _request("orderCancellation", data, {}, "POST", "json");
  },

  checkGRFactor(data) {
    const username =
      window.location.href.match(/dhsccovid19/i) !== null || window.location.href.match(/test-and-trace/i)
        ? "/"
        : "";
    return _request("checkGRFactor", data, {}, "POST", "json", username);
  },
};

export default DataAccessService;
