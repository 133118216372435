import React, { useContext, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { Button, GridCol, GridRow, H1, Paragraph, LoadingBox } from "govuk-react";
import { ErrorSummary, useAnalytics } from "@cube/global-components";
import { v5 as uuidv5 } from "uuid";
import Moment from "moment";
import { RequestTranslation } from "../../../service/translation";
import { FormContext } from "../../../data/contexts/contexts";
import HelperService from "../../../service/helpers";
import OpenOrders from "./OpenOrders.component";

const UUID_NAMESPACE = "1b671a64-40d5-491e-99b0-da01ff1f3341";
const styles = {
  gridRowPositionRel: {
    position: "relative",
    paddingBottom: "10px",
  },
  overviewSeparator: {
    width: "calc(64% - 20px)",
    display: "block",
    borderBottom: "2px solid #e3e3e3",
    margin: "-10px 0 20px 0",
  },
  gridMargin: {},
  bottomMargin: {
    marginBottom: "60px" /* TO ACCOMMODATE FOR THE HEIGHT OF SECONDARYBUTTON */,
  },
  ineligibleOrder: {
    color: "#555F63",
  },
  testDescription: {
    color: "#555F63",
    margin: "0 0 20px 0",
  },
  testDescriptionError: {
    color: "#555F63",
    margin: "0 0 0 0",
  },
  testSpacer: {
    margin: "0 0 64px 0",
  },
  secondaryButton: {
    backgroundColor: "#f3f2f1",
    color: "#000",
    boxShadow: "0 2px 0 #929191",
    marginBottom: "2px !important",
    "&:hover, &:focus": {
      color: "#000",
      backgroundColor: "#dbdad9",
    },
  },
  formError: {
    paddingLeft: "15px",
    borderLeft: "4px solid #d4351c",
  },
  formErrMsg: {
    color: "#d4351c",
    fontSize: "1.1875rem",
    lineHeight: "1.31579",
    marginBottom: "15px !important",
    fontWeight: "700",
  },
  formErrLeftPadding: {
    paddingLeft: "0px !important",
  }
};

const OrgOverview = ({ classes, location, handleLoader }) => {
  const { triggerEvent } = useAnalytics();

  const [formContext, setFormContext] = useContext(FormContext);
  const [errorDesc, setErrorDesc] = useState(null);
  const [errMessage, setErrorMsg] = useState(null);
  const [errIndex, setErrorIndex] = useState(null);
  const [errorArray, setErrorArray] = useState([]);
  const [testTypeClick, setTestTypeClick] = useState(null);
  const crqData = formContext?.crqData || null;
  const orgType = formContext?.orgType || null;
  const currentPath = window.location.pathname;
  const windowAllowed = localStorage.getItem("answer");
  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: "nhs:test:organisation-order:overview",
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "overview",
        },
      },
    };
    triggerEvent(analyticsData);
  } else {
    window.digitalData = null;
  }

  const RequestLabels = RequestTranslation(orgType, crqData.TestingApproach);
  const {
    ORDER_OVERVIEW: { HEADING, SUB_HEADING, SUB_HEADING_FIRST },
  } = RequestLabels;
  const history = useHistory();
  let noOfAddresses = null;
  let testTypeLastProcessedAutomationId = null;
  let testTypeEligibilityAutomationId = null;
  let testTypeButtonAutomationId = null;

  if (formContext?.crqData?.Addresses === undefined) {
    noOfAddresses = 0;
  } else {
    noOfAddresses = (formContext?.crqData?.Addresses).length;
  }

  const pageLocation = noOfAddresses > 1 ? "/address" : "/details";

  const OrderEligibility = (eligibilityDate, orderRestriction, orderStatus) => {
    if (orderStatus === "New" || orderStatus === "To be Processed") {
      orderRestriction = "New";
    }

    const switchRestriction = (restriction) => {
      switch (restriction) {
        case "Blocked":
          return <Paragraph>You cannot place this order</Paragraph>;
        case "One Valid Order Only":
          return <Paragraph>You cannot place this order</Paragraph>;
        default:
          return (
            <Paragraph>
              You cannot order this again until we process your previous order
            </Paragraph>
          );
      }
    };

    return (
      <>
        {eligibilityDate === "" ||
        Moment(new Date()) > Moment(eligibilityDate) ? (
          switchRestriction(orderRestriction)
        ) : (
          <GridRow>
            <GridCol setWidth="one-third">
              <Paragraph>**You can order again from**</Paragraph>
            </GridCol>
            <GridCol setWidth="one-third">
              <Paragraph>
                {eligibilityDate &&
                  Moment(eligibilityDate).format("D MMMM YYYY [at] HH:mm")}
              </Paragraph>
            </GridCol>
          </GridRow>
        )}
        <span className={classes.overviewSeparator} />
      </>
    );
  };

  // SORTING USING BELOW ORDER.
  // NOTE: THESE VALUES MUST CORRESPOND EXACTLY TO THE TITLES RECEIVED FROM SF.
  // ALTERNATIVE IS TO HARDCODE THE PRIORITY IN SALESFORCE
  const sortOrder = [
    "Standard swab tests",
    "Pooled testing",
    "Rapid lateral flow tests",
    "Rapid lateral flow tests for use on-site",
    "Rapid lateral flow tests for home testing",
    "Rapid lateral flow tests for daily contact testing",
    "Outer return boxes for swab tests",
    "Swab tests (PCR tests) to return by priority post box",
  ];

  // Order the TestingTypes based on Testing Title
  const orderedTestingTypes = crqData?.TestingTypes.sort(
    (a, b) => sortOrder.indexOf(a.title) - sortOrder.indexOf(b.title)
  );

  const selectOrderType = (testingType, index) => {
    const currentTestType = formContext?.testType;
    const currentTestApproach = formContext?.testingApproach;
    const { policyProductPresent } = testingType;

    if (testingType.policyId !== null && policyProductPresent !== false) {
      let reselectType = false;
      let reselectOptions = {};
      if (
        (formContext?.testType &&
          currentTestType !== testingType.testingTypeName) ||
        (formContext?.testingApproach &&
          currentTestApproach !== testingType.testingApproach)
      ) {
        reselectType = true;
      }

      reselectOptions = {
        totalNumberOfResidents: null,
        totalNumberOfStaff: null,
        totalStaff: null,
      };

      setFormContext({
        ...formContext,
        reselectType,
        testingApproach: testingType.testingApproach,
        testingTypeID: testingType.testTypeId,
        testType: testingType.testingTypeName,
        testTypeTitle: testingType.testTypeTitle,
        testingRegime: testingType.testingRegime,
        nonStaffMultiplier: testingType.numberOfNonStaffMultiplier,
        staffMultiplier: testingType.numberOfStaffMultiplier,
        showOrderFormForTestingType: testingType.testingTypeName,
        orderTitle: testingType.testTypeTitle,
        pageLocation: `${pageLocation}`,
        ...reselectOptions,
      });

      if (noOfAddresses > 1) {
        history.push({
          pathname: "/address",
        });
      } else {
        history.push({
          pathname: "/details",
        });
      }
    } else {
      const errorArr = [];
      if (policyProductPresent === false) {
        errorArr.push({
          targetName: `order-${index}`,
          text:
            "We are having some technical difficulties with your order at the moment. Please try again later. In case your order is for an emergency, please contact 119.",
        });

        if (errorArr.length > 0) {
          setErrorDesc(
            "We are having some technical difficulties with your order at the moment. Please try again later. In case your order is for an emergency, please contact 119."
          );
          setErrorArray(errorArr);
          setErrorMsg("There is a problem");
          setTestTypeClick(`${testingType.testTypeTitle}-${index}`);
          setErrorIndex(index);

          return false;
        }
      } else {
        errorArr.push({
          targetName: `order-${index}`,
          text:
            "There appears to be a problem with the test type you have selected. Please call 119 to continue with the order.",
        });

        if (errorArr.length > 0) {
          setErrorDesc(
            "There appears to be a problem with the test type you have selected. Please call 119 to continue with the order."
          );
          setErrorArray(errorArr);
          setErrorMsg("There is a problem");
          setTestTypeClick(`${testingType.testTypeTitle}-${index}`);
          setErrorIndex(index);

          return false;
        }
      }
    }
  };

  const ShowOrderOptions = () =>
    orderedTestingTypes?.map((TestingType, index) => {
      /* RETURN NOTHING IF ORDERFORM FALSE, LASTPROCESSED EMPTY AND RESTRICTION "BLOCKED" */
      if (
        TestingType.orderFormSuccess === false &&
        TestingType.lastProcessedOrder === "" &&
        TestingType.orderRestriction === "Blocked" &&
        TestingType.orderStatus === ""
      ) {
        return false;
      }

      TestingType.testTypeTitle = TestingType?.title
        ? TestingType?.title
        : "Title"; // TODO: WHAT IF TITLE IS NULL?
      TestingType.testTypeDescription = TestingType?.description
        ? TestingType?.description
        : "Description"; // TODO: WHAT IF DESCRIPTION IS NULL?
      // Create unique, but constant, ID's for automation purposes
      testTypeLastProcessedAutomationId = uuidv5(
        TestingType?.title + "lastprocessed",
        UUID_NAMESPACE
      );
      testTypeEligibilityAutomationId = uuidv5(
        TestingType?.title + "eligibility",
        UUID_NAMESPACE
      );
      testTypeButtonAutomationId = uuidv5(
        TestingType?.title + "button",
        UUID_NAMESPACE
      );
      const { policyId } = TestingType;

      return (
        <>
          <div>
            <div key={TestingType.testTypeId}>
              <div className={errMessage && index === errIndex ? classes.formError : ""}>
                <h2 data-errorscrolltarget={`order-${index}`} data-automatedtestingid={`${TestingType.testTypeTitle}`} id={`${TestingType.testTypeTitle}-${index}`}>{TestingType.testTypeTitle}</h2>
                <GridRow>
                  <GridCol setWidth="two-thirds" className={errMessage && index === errIndex ? classes.formErrMsg : ""}>
                    <Paragraph className={errMessage && index === errIndex ? classes.testDescriptionError : classes.testDescription}>
                      {TestingType.testTypeDescription.replace("119", "**119**")}
                    </Paragraph>
                  </GridCol>
                </GridRow>
                {TestingType?.lastProcessedOrder && (
                  <div data-automatedtestingid={testTypeLastProcessedAutomationId}>
                    <GridRow>
                      <GridCol setWidth="one-third">
                        <Paragraph>**Last completed order**</Paragraph>
                      </GridCol>
                      <GridCol setWidth="one-third">
                        <Paragraph>
                          {TestingType?.lastProcessedOrder &&
                            Moment(TestingType.lastProcessedOrder).format(
                              "D MMMM YYYY [at] HH:mm"
                            )}
                        </Paragraph>
                      </GridCol>
                    </GridRow>
                    <div className={classes.overviewSeparator}></div>
                  </div>
                )}
                {(policyId === null || index === errIndex ) && `${TestingType.testTypeTitle}-${index}` === testTypeClick && (
                  <GridCol setWidth="two-thirds" className={errMessage && index === errIndex ? classes.formErrLeftPadding : ""}>
                    {errorDesc && (
                      <div data-automatedtestingid="order-form-place-order-error" className={classes.formErrMsg}>{errorDesc}</div>
                    )}
                  </GridCol>
                )}

                {(Moment(new Date()) > Moment(TestingType.eligibleForNextOrder) ||
                  TestingType.eligibleForNextOrder === "") &&
                TestingType.orderFormSuccess ? (
                  <Button
                    onClick={() => selectOrderType(TestingType, index)}
                    className={classes.secondaryButton}
                    data-automatedtestingid={testTypeButtonAutomationId}
                  >
                    Place order
                  </Button>
                ) : (
                  <div data-automatedtestingid={testTypeEligibilityAutomationId}>
                    {OrderEligibility(
                      TestingType.eligibleForNextOrder,
                      TestingType.orderRestriction,
                      TestingType.orderStatus
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classes.testSpacer}></div>
        </>
      );
    });

  return (
    <>
        {errMessage && (
          <GridRow>
            <GridCol setWidth="two-thirds" className={classes.marginAlignCenter}>
              <ErrorSummary
                heading={errMessage}
                errors={errorArray}
                onHandleErrorClick={HelperService.onHandleErrorClickAlternative}
                errorMessage={errMessage}
              />
            </GridCol>
          </GridRow>
        )}

        <GridRow>
          <GridCol setWidth="two-thirds">
            <header><H1>{HEADING}</H1></header>
          </GridCol>
        </GridRow>

        {SUB_HEADING && (
          <GridRow>
            <GridCol setWidth="two-thirds">
              <Paragraph>{SUB_HEADING}</Paragraph>
            </GridCol>
          </GridRow>
        )}
        <GridRow>
          <GridCol setWidth="two-thirds">
            <Paragraph>{SUB_HEADING_FIRST}</Paragraph>
          </GridCol>
        </GridRow>

        <div className={classes.gridMargin}></div>

        {crqData?.TestingTypes?.length > 0 && <ShowOrderOptions />}
        <OpenOrders/>
    </>
  );
};

export default withRouter(withStyles(styles)(OrgOverview));

OrgOverview.defaultProps = {};

OrgOverview.propTypes = {
  classes: PropTypes.shape({
    formErrMsg: PropTypes.string,
    formError: PropTypes.string,
    formErrLeftPadding: PropTypes.string,
    gridMargin: PropTypes.string,
    marginAlignCenter: PropTypes.string,
    overviewSeparator: PropTypes.string,
    secondaryButton: PropTypes.string,
    testDescription: PropTypes.string,
    testDescriptionError: PropTypes.string,
    testSpacer: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      crqData: PropTypes.shape({
        City: PropTypes.string,
        Postcode: PropTypes.string,
        Street: PropTypes.string,
        TestType: PropTypes.string,
        TestingApproach: PropTypes.string,
        cqcRegistrationID: PropTypes.string,
      }).isRequired,
      orgType: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
