/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import {
  Button,
  GridCol,
  GridRow,
  H2,
  H4,
  Paragraph,
  ErrorSummary,
  LoadingBox
} from "govuk-react";
import { useAnalytics } from "@cube/global-components";
import { CancellationRequestTranslation } from "../../service/translation";
import { FormContext } from "../../data/contexts/contexts";
import { RadioInlineComponent } from "../Inputs";
import { Form } from "react-final-form";
import { IconImportant } from "@govuk-react/icons";
import HelperService from "../../service/helpers/index";
import DataAccessService from "../../service/httpUtility";

const styles = {
  buttonStyling: {
    margin: "10px 15px",
    float: "right",
  },
  iconSize: {
    height: "0%",
    width: "20%",
    margin: "3px 2px",
  },
  gridMargin: {
    marginLeft: "0px",
    marginBottom: "10px",
  },
  warningText: {
    fontWeight: "bold",
  },
  formErrMsg: {
    color: "#d4351c",
    fontSize: "1.1875rem",
    lineHeight: "1.31579",
    marginBottom: "15px",
    fontWeight: "700",
  },
  formError: {
    paddingLeft: "15px",
    borderLeft: "5px solid #d4351c",
  },
};

const OrderCancellationRequest = ({ classes }) => {
  const [serviceErrorFormSub, setServiceErrorFormSub] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loader, serviceErrorFormSub]);

  const { triggerEvent } = useAnalytics();
  const [formContext, setFormContext] = useContext(FormContext);
  const [errMessage, setErrorMsg] = useState(null);
  const [errorArray, setErrorArray] = useState([]);
  const [errorArrayApi, setErrorArrayApi] = useState([]);
  const [errMessageApi, setErrorMsgApi] = useState("");
  const [errorDesc, setErrorDesc] = useState(null);
  const [radiosErrorMessage, setRadiosErrorMessage] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const CancellationRequestLabels = CancellationRequestTranslation();
  const {
    INFO: {
      HEADING,
      SUB_HEADING,
      INFO_TEXT_1,
      INFO_TEXT_2,
      YES,
      NO,
    },
    ERROR_MESSAGES: {
      SELECT_AN_OPTION,
      FAILURE_ERR_MSG,
    },
  } = CancellationRequestLabels;
  const history = useHistory();
  const currentPath = window.location.pathname;
  const windowAllowed = formContext?.orderId;
  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: "nhs:test:organisation-order:cancellation-request",
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "cancellation-request",
        },
      },
    };
    triggerEvent(analyticsData);
  } else {
    window.digitalData = null;
  }

  const handleError = (msg) => {
    setServiceErrorFormSub(msg);
  };

  const handleLoader = (hideShowloader) => {
    setLoader(hideShowloader);
  };

  const validate = () => {
    const errorArr = [];

    if (!selectedOption) {
      setRadiosErrorMessage(SELECT_AN_OPTION);
      errorArr.push({ targetName: "choice", text: SELECT_AN_OPTION });
      setErrorMsg(SELECT_AN_OPTION);
    }

    if (errorArr.length > 0) {
      setErrorDesc(null);
      setErrorArray(errorArr);
      return false;
    }
    return true;
  };

  const options = [
    {
      label: YES,
      value: YES,
      path: "/cancellation-received",
    },
    {
      label: NO,
      value: NO,
      path: "/overview",
    },
  ];

  const onSubmit = (event) => {
    if (validate()) {
      const optionURL = `${options.find((option) => option.value === selectedOption).path}`;
      handleLoader(true);
      if (selectedOption === YES) {
        handleError(null);
        DataAccessService.sendOrderCancellation({
          orders: [formContext?.orderId],
        })
        .then(() => {
          setFormContext({
            ...formContext,
            pageLocation: optionURL,
            prevLocation: currentPath,
          });
          history.push({ pathname: optionURL });
          handleLoader(false);
        })
        .catch((e) => {
          const errorMsg = e.response
            ? e.response.data.errMessage || FAILURE_ERR_MSG
            : FAILURE_ERR_MSG;
          handleLoader(false);
          handleError(errorMsg);
        });
      } else {
        setFormContext({
          ...formContext,
          pageLocation: optionURL,
          prevLocation: currentPath,
        });
        history.push({ pathname: optionURL });
        handleLoader(false);
      }
    }
  };

  const onChangeValue = (e) => {
    setSelectedOption(e.target.value);
  }

  return (
    <>
      <LoadingBox
        loading={loader}
        backgroundColor="#fff"
        timeIn={100}
        timeOut={100}
        backgroundColorOpacity={0.85}
        spinnerColor="#000"
      >

        {errMessage && (
          <GridRow>
            <GridCol setWidth="two-thirds">
              <ErrorSummary
                heading="There is a problem"
                errors={errorArray}
                onHandleErrorClick={HelperService.onHandleErrorClick}
              />
            </GridCol>
          </GridRow>
        )}

        {errMessageApi && (
          <GridRow>
            <GridCol setWidth="two-thirds">
              <ErrorSummary
                heading="There is a problem"
                errors={errorArrayApi}
                description={errorDesc}
                onHandleErrorClick={HelperService.onHandleErrorClick}
              />
            </GridCol>
          </GridRow>
        )}

        <GridCol setWidth="two-thirds">
          <GridRow>
            <GridCol>
              <header><H2>{HEADING}</H2></header>
            </GridCol>
          </GridRow>
          <Form
            onSubmit={onSubmit}
            initialValues={FormContext}
            render={({ handleSubmit, form }) => {
              return (
                <form onSubmit={handleSubmit} id="cancelForm">
                  <GridRow>
                    <GridCol className={classes.iconSize}>
                      <IconImportant />
                    </GridCol>
                    <GridCol id>
                      <GridRow>
                        <GridCol>
                          <H4>
                            {SUB_HEADING}
                            {formContext?.orderCancellationNumber}
                            ?
                          </H4>
                        </GridCol>
                      </GridRow>
                      <GridCol className={radiosErrorMessage ? classes.formError : ""}>
                        <GridRow className={radiosErrorMessage ? classes.gridMargin : ""}>
                          {radiosErrorMessage && (
                            <div
                              className={classes.formErrMsg}
                              data-automatedtestingid="order-form-select-an-option-error"
                            >
                              {radiosErrorMessage}
                            </div>
                          )}
                        </GridRow>
                        <GridRow className={radiosErrorMessage ? classes.gridMargin : ""}>
                          {options.map((option) => (
                            <div
                              className={classes.gridMargin}
                              data-automatedtestingid={`order-form-cancellation-option-${option.label}`}
                              key={option.label}
                              onClick={onChangeValue}
                            >
                              <RadioInlineComponent
                                componentChecked={
                                  (option.value === selectedOption && true) || false
                                }
                                label={option.label}
                                name="choice"
                                path={option.path}
                                value={option.value}
                                hint={option.hint}
                                errMessage={SELECT_AN_OPTION}
                              />
                            </div>
                          ))}
                        </GridRow>
                      </GridCol>
                      <GridRow>
                        <GridCol>
                          <Paragraph className={classes.warningText}>
                            {INFO_TEXT_1}
                          </Paragraph>
                          <br />
                          <Paragraph className={classes.warningText}>
                            {INFO_TEXT_2}
                          </Paragraph>
                        </GridCol>
                      </GridRow>
                      <GridRow>
                        <GridCol>
                          <Button
                            className={classes.buttonStyling}
                            data-automatedtestingid="order-form-cancellation-request-continue"
                            type="button"
                            onClick={onSubmit}
                          >
                            Continue
                          </Button>
                        </GridCol>
                      </GridRow>
                    </GridCol>
                  </GridRow>
                </form>
              );
            }}
          />
        </GridCol>
      </LoadingBox>
    </>
  );
};
export default withRouter(withStyles(styles)(OrderCancellationRequest));

OrderCancellationRequest.defaultProps = {
  classes: {
    buttonStyling: "",
    iconSize: "",
    gridMargin: "",
    warningText: "",
    formErrMsg: "",
    formError: "",
  }
};

OrderCancellationRequest.propTypes = {
  classes: PropTypes.shape({
    buttonStyling: PropTypes.string,
    iconSize: PropTypes.string,
    gridMargin: PropTypes.string,
    warningText: PropTypes.string,
    formErrMsg: PropTypes.string,
    formError: PropTypes.string,
  }),
};
