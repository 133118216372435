import React, { useState, useEffect } from "react";
import { LoadingBox } from "govuk-react";
import { withRouter } from "react-router-dom";
import withStyles from "react-jss";
import ErrorComponent from "../Error";
import DeliveryInstructionsForm from "./DeliveryInstructionsForm";
import HelperMethods from "./helpers";

const styles = {};

const DeliveryInstructionsFormPage = () => {
  const [serviceErrorFormSub, setServiceErrorFormSub] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [serviceErrorFormSub]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loader]);

  const handleError = (msg) => {
    setServiceErrorFormSub(msg);
  };

  const handleLoader = (hideShowloader) => {
    setLoader(hideShowloader);
  };

  return (
    <>
      {serviceErrorFormSub &&
      <ErrorComponent message={serviceErrorFormSub} />}
      <LoadingBox
        loading={loader}
        backgroundColor="#fff"
        timeIn={100}
        timeOut={100}
        backgroundColorOpacity={0.85}
        spinnerColor="#000"
      >
        <DeliveryInstructionsForm
          handleSubError={handleError}
          handleLoader={handleLoader}
          helperMethods={HelperMethods}
        />
      </LoadingBox>
    </>
  );
};

export default withRouter(withStyles(styles)(DeliveryInstructionsFormPage));