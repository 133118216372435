import React from "react";
import PropTypes from "prop-types";
import {Select} from "govuk-react";
import {Field} from "react-final-form";

const SelectComponent = ({
  name,
  defaultValue,
  label,
  hint,
  onChange,
  options,
}) => (
  <Field
    value={defaultValue}
    name={name}
    render={({ input }) => (
      <Select
        {...input}
        input={input}
        name={name}
        hint={[hint]}
        label={label}
        onChange={() => onChange(input.value === "" || input.value === "no")}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    )}
  />
);

export default SelectComponent;

SelectComponent.defaultProps = {
  hint: "",
  label: "",
  defaultValue: "",
};

SelectComponent.propTypes = {
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
};
