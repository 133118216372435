const ConfirmationLabels = {
  FORM_SUCCESS_PANEL: "Order placed",
  FORM_SUCCESS_MSG_HEADING: "Thank you for your order",
  FORM_SUCCESS_SUB_HEADING_FIRST: "",
  FORM_SUCCESS_SUB_HEADING_SECOND: "test kits.",
  FORM_SUCCESS_MAIN_MSG_FIRST: "We've sent an email to ",
  FORM_SUCCESS_MAIN_MSG_REFERRER: "and to the secondary contact email",
  FORM_SUCCESS_MAIN_MSG_SECOND:
    "from **organisation.coronavirus.testing@notifications.service.gov.uk**.",
  FORM_SUCCESS_MAIN_MSG_THIRD:
    "If you do not receive the email within a few hours, check your spam folder.",
  FORM_SUCCESS_MAIN_MSG_FOURTH:
    "For questions about your order or if you do not receive the confirmation email, call the contact centre: **119** (free from mobiles and landlines)",
  FORM_SUCCESS_ENG_NL_WALES:
    "on <strong>119</strong> (free from mobiles and landlines)",
  FORM_SUCCESS_BTN_TITLE: "Place another order",
  DEFAULT: {
    FORM_SUCCESS_PANEL: "Order placed",
    FORM_SUCCESS_MSG_HEADING:
      "Thank you for requesting for coronavirus testing.",
    FORM_SUCCESS_SUB_HEADING_FIRST: "You have requested",
    FORM_SUCCESS_SUB_HEADING_SECOND: "test kits.",
    FORM_SUCCESS_MAIN_MSG_FIRST:
      "A confirmation email has been sent to the contact email provided",
    FORM_SUCCESS_MAIN_MSG_SECOND:
      "from **organisation.coronavirus.testing@notifications.service.gov.uk**. If this confirmation email has not been received within a few hours, please check the spam folder.",
    FORM_SUCCESS_MAIN_MSG_THIRD:
      "If you still have problems, contact the helpline:",
    FORM_SUCCESS_ENG_NL_WALES:
      "on 119 (free from mobiles and landlines)",
    FORM_SUCCESS_BTN_TITLE: "Request test kits for another organisation",
  },
};
export default ConfirmationLabels;
