import { RequestLabels } from "../../data/constants";

const RequestTranslation = (organisation) => {
  const labels = RequestLabels;
  labels.ORDER_OVERVIEW = {
    ...labels.ORDER_OVERVIEW,
    ...labels.ORDER_OVERVIEW.DEFAULT,
  };
  labels.ORG_DETAILS = {
    ...labels.ORG_DETAILS,
    ...labels.ORG_DETAILS.DEFAULT,
  };
  labels.REQUESTOR_INFO = {
    ...labels.REQUESTOR_INFO,
    ...labels.REQUESTOR_INFO.DEFAULT,
  };
  labels.REQUEST_INPUTS = {
    ...labels.REQUEST_INPUTS,
    ...labels.REQUEST_INPUTS.DEFAULT,
  };
  labels.ERROR_MESSAGES = {
    ...labels.ERROR_MESSAGES,
    ...labels.ERROR_MESSAGES.DEFAULT,
  };
  switch (organisation) {
    case "Care Home":
      labels.REQUEST_INPUTS = {
        ...labels.REQUEST_INPUTS,
        ...labels.REQUEST_INPUTS.CARE_HOME,
      };
      labels.ERROR_MESSAGES = {
        ...labels.ERROR_MESSAGES,
        ...labels.ERROR_MESSAGES.CARE_HOME,
      };
      break;
    case "Prison":
      labels.REQUEST_INPUTS = {
        ...labels.REQUEST_INPUTS,
        ...labels.REQUEST_INPUTS.PRISON,
      };
      labels.ERROR_MESSAGES = {
        ...labels.ERROR_MESSAGES,
        ...labels.ERROR_MESSAGES.PRISON,
      };
      break;
    case "Dentist":
      labels.REQUEST_INPUTS = {
        ...labels.REQUEST_INPUTS,
        ...labels.REQUEST_INPUTS.DENTIST,
      };
      labels.ERROR_MESSAGES = {
        ...labels.ERROR_MESSAGES,
        ...labels.ERROR_MESSAGES.DENTIST,
      };
      break;
    case "School":
      labels.REQUEST_INPUTS = {
        ...labels.REQUEST_INPUTS,
        ...labels.REQUEST_INPUTS.SCHOOL,
      };
      labels.ERROR_MESSAGES = {
        ...labels.ERROR_MESSAGES,
        ...labels.ERROR_MESSAGES.SCHOOL,
      };
      break;
    case "GP":
      labels.REQUEST_INPUTS = {
        ...labels.REQUEST_INPUTS,
        ...labels.REQUEST_INPUTS.GP,
      };
      labels.ERROR_MESSAGES = {
        ...labels.ERROR_MESSAGES,
        ...labels.ERROR_MESSAGES.GP,
      };
      break;
    default:
      break;
  }

  labels.REQUESTOR_INFO.REQ_SUB_HEADING = [
    labels.REQUESTOR_INFO.REQ_SUB_HEADING_FIRST,
    labels.REQUESTOR_INFO.REQ_SUB_HEADING_SECOND,
  ].join(" ");

  return {
    ...labels,
  };
};

export default RequestTranslation;
