import React from "react";
import PropTypes from "prop-types";
import { Radio } from "govuk-react";
import { Field } from "react-final-form";

const RadioInlineComponent = ({ componentChecked, value, label, name, index }) => (
  <Field
    value={value}
    type="radio"
    id={name}
    name={name}
    render={({ input }) => (
      componentChecked ? (
        <Radio id={`radio-button-${name}-${index}`} name={name} {...input} checked inline>
          {label}
        </Radio>
      ) : (
        <Radio id={`radio-button-${name}-${index}`} name={name} {...input} inline>
          {label}
        </Radio>
      )
    )}
  />
);

export default RadioInlineComponent;

RadioInlineComponent.propTypes = {
  componentChecked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};
