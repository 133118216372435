import React from "react";
import {Field} from "react-final-form";
import {InputField} from "govuk-react";
import PropTypes from "prop-types";
import {inputWidthStyles} from "../../../data/jss/Input.component.jss";
import inputWidthTranslator from "../../../service/jss";
import { numberValidator } from './lib';
import withStyles from "react-jss";

const styles = inputWidthStyles;

const NumberInput = ({label, name, hint, errMessage, errMessageNumber, inputWidth, classes}) => {
  return (
    <Field
      validate={numberValidator(errMessage, errMessageNumber)}
      label={label}
      name={name}
      render={({input, meta}) => (
        <InputField
          {...input}
          meta={meta}
          value={input.value}
          input={{...input, type: "number"}}
          name={name}
          hint={[hint]}
          className={inputWidthTranslator(inputWidth, classes)}
        >
          {label}
        </InputField>
      )}
    />
  );
};

export default withStyles(styles)(NumberInput);

NumberInput.defaultProps = {
  name: "",
  hint: "",
  label: "",
  errMessage: "",
  errMessageNumber: "",
};

NumberInput.propTypes = {
  name: PropTypes.string,
  errMessage: PropTypes.string,
  errMessageNumber: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string
};
