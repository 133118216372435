import React, {useState} from "react";
import {useHistory, withRouter} from "react-router-dom";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import {
	Button,
	Details,
	GridCol,
	GridRow,
	H1,
  H2,
	H3,
	ListItem,
	LoadingBox,
	Main,
	Paragraph,
	Table,
	UnorderedList,
} from "govuk-react";
import {useAnalytics} from "../../../service/adobe-analytics";

const styles = {
  gridMargin: {
    marginTop: "5px",
  },
  SubmitButtonFontSize: {
    fontSize: "0.95em",
  },
  formError: {
    paddingLeft: "15px",
    borderLeft: "5px solid #d4351c",
  },
  formErrMsg: {
    color: "#d4351c",
    fontSize: "1.1875rem",
    lineHeight: "1.31579",
    marginBottom: "15px",
    fontWeight: "700",
  },
};

const CookieInfo = ({ classes }) => {
  const windowAllowed = localStorage.getItem("answer");
  const windowNotAllowed = localStorage.getItem("answerNo");
  
  const { triggerEvent } = useAnalytics();
  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: "nhs:test:organisation-order:cookies",
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "cookies",
        },
      },
    };
    triggerEvent(analyticsData);
  } else {
    window.digitalData = null;
  }

  const pageLocation = window.location.pathname;
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  const onConfirm = () => {
    history.push({
      pathname: "/choose-cookies",
    });
  };

  return (
    <LoadingBox
      loading={loader}
      backgroundColor="#fff"
      timeIn={100}
      timeOut={100}
      backgroundColorOpacity={0.85}
      spinnerColor="#000"
    >
      <Main className={[classes.w75p, classes.zeroPadding, classes.w100p]}>
        <GridRow className={classes.headingRow}>
          <GridCol setWidth="two-thirds">
            <header>
              <H1>Cookies on the GOV.UK website</H1>
            </header>
            <H2>What are cookies?</H2>
            <Paragraph>
              Cookies are files saved on your phone, tablet or computer when you visit a website.
            </Paragraph>
            <Paragraph>
              They store information about how you use the website, such as the pages you visit.
            </Paragraph>
            <Paragraph>
              Cookies are not viruses or computer programs. They are very small so do not take up much space.
            </Paragraph>
          </GridCol>
        </GridRow>
        <GridRow>
          <GridCol setWidth="two-thirds">
            <H2>How we use cookies</H2>
            <Paragraph>We use cookies to:</Paragraph>
            <UnorderedList>
              <ListItem>
                measure how you use our website, such as which links you click on (analytics cookies)
              </ListItem>
            </UnorderedList>
            <Details summary="List of cookies that measure website use">
              <Table>
                <Table.Row>
                  <Table.CellHeader>Name</Table.CellHeader>
                  <Table.Cell
                    setWidth="one-sixth"
                    style={{ fontWeight: "bold" }}
                  >
                    Purpose
                  </Table.Cell>
                  <Table.Cell
                    setWidth="one-sixth"
                    style={{ fontWeight: "bold" }}
                  >
                    Expires
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>cookie_policy</Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Remembers your cookies settings on our website
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth" setHeight="5px">
                    1 year
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>AMCV_#</Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Used by Adobe Analytics. Tell us if you&apos;ve used our site
                    before
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">2 years</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>AMCVS_#AdobeOrg</Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Used by Adobe Analytics. Tell us how you use our site
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    When you close the browser
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    com.adobe.reactor.dataElementCookiesMigrated
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Used by Adobe Analytics. Includes data elements set to
                    capture usage of our website
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">No expiry</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>demdex</Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Used by Adobe Analytics. Allows cross-domain analytics of
                    NHS properties
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">180 days</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>s_cc</Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Used by Adobe Analytics. Checks if cookies work in your web
                    browser
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    When you close the browser
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>s_getNewRepeat</Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Used by Adobe Analytics. Tells us if you&apos;ve used our website
                    before
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">30 days</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>s_ppn</Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Used by Adobe Analytics. Tells us how you use our website by
                    reading the previous page you visited
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">1 day</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>s_sq</Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    Used by Adobe Analytics. Remembers the last link you clicked
                    on
                  </Table.Cell>
                  <Table.Cell setWidth="one-sixth">
                    When you close the browser
                  </Table.Cell>
                </Table.Row>
              </Table>
            </Details>
            <H3>Change your cookie settings</H3>
          </GridCol>
        </GridRow>

        <GridRow className={classes.buttonRow}>
          <GridCol>
            <Button data-automatedtestingid="orderform-continue-button" onClick={onConfirm} className={classes.urnSubmit}>
              Choose which cookies we use
            </Button>
          </GridCol>
        </GridRow>
      </Main>
    </LoadingBox>
  );
};
export default withRouter(withStyles(styles)(CookieInfo));

CookieInfo.propTypes = {
  classes: PropTypes.shape({
    buttonRow: PropTypes.string,
    headingRow: PropTypes.string,
    w75p: PropTypes.string,
    sectionBreakMargin: PropTypes.string,
    paddingTop025: PropTypes.string,
    urnSubmit: PropTypes.string,
    zeroPadding: PropTypes.string,
    w100p: PropTypes.string,
    w62p: PropTypes.string,
  }).isRequired,
};
