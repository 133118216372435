export const validateName = (name) => {
  const regex = /^[' a-zA-ZÀ-ÿ\u00f1\u00d1-]*$/im;
  return regex.test(name);
};

export const luhnChecksum = (code) => {
  const len = code.length;
  const parity = len % 2;
  let sum = 0;
  for (let i = len - 1; i >= 0; i -= 1) {
    let d = parseInt(code.charAt(i), 10);
    if (i % 2 === parity) {
      d *= 2;
    }
    if (d > 9) {
      d -= 9;
    }
    sum += d;
  }
  return sum % 10;
};

export const validateOrgNumber = (value) => {
  if (!value || value.length !== 8) {
    return false;
  }
  return luhnChecksum(value) === 0;
};

export const validatePhone = (phone) => {
  return phone?.length !== 11;
};