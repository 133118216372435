const HelperMethods = {
    form: {
        areDaysOpen(daysOpen) {
            if (daysOpen) {
                const checkedDays = Array.from(daysOpen).filter(checkbox => checkbox.checked);
                return checkedDays.length;
            }
            return false;
        },
        getRadioValue(radioField) {
            return Array.from(radioField).find(radio => radio.checked)?.value;
        },
        communityDeliveryNotes(fields, fieldsValues) {
            const deliveryNotes = `${this.openClosedTimesFormatted(fields)}; ${this.formattedDaysSiteOpen(fieldsValues)}; ${this.selectedOffloadFacility(fieldsValues)};${this.formattedVehicleRestrictions(fieldsValues)}${this.formattedUvRestrictionInformation(fields)}${this.formattedDate(fieldsValues)}`;
            return deliveryNotes;
        },
        openClosedTimesFormatted(fields) {
            return `${fields.orderOpenHour}:${fields.orderOpenMinute}; ${fields.orderCloseHour}:${fields.orderCloseMinute}`;
        },
        formattedDaysSiteOpen(fieldsValues) {
            const selectedDays = fieldsValues.selectedOpenDays;
            return selectedDays.map(day => day.substr(0, 2)).join('');
        },
        selectedOffloadFacility(fieldsValues) {
            return fieldsValues.selectedOffloadFacility;
        },
        formattedVehicleRestrictions(fieldsValues) {
            const vehicleRestrictions = fieldsValues.selectedVehicleRestrictions && fieldsValues.selectedVehicleRestrictions.length;
            const vrFormat = fieldsValues.selectedVehicleRestrictions.join(', ');
            return vehicleRestrictions ? ` ${vrFormat};` : '';
        },
        formattedUvRestrictionInformation(fields) {
            const uvRestrictionInformation = fields.uvRestrictionInformation ? ` ${fields.uvRestrictionInformation};` : '';
            return uvRestrictionInformation;
        },
        formattedDate(fieldsValues) {
            const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const date = fieldsValues.deliveryDate && Object.keys(fieldsValues.deliveryDate).length;
            return date ? ` ${fieldsValues.deliveryDate.day} ${monthNames[fieldsValues.deliveryDate.month - 1]} ${fieldsValues.deliveryDate.year};` : '';
        },
    },
    fields: {
        isOther(selectedOffloadFacility, selectedVehicleRestrictions) {
            return selectedOffloadFacility === 'Other' || selectedVehicleRestrictions?.includes('Other');
        },
        updateCheckboxesStateArr(selectedValues, newValue, defaultOptions, updateFunc) {
            const valuesTemp = selectedValues;
            if (valuesTemp.indexOf(newValue) !== -1) {
              valuesTemp.splice(valuesTemp.indexOf(newValue), 1);
            } else {
              valuesTemp.splice(defaultOptions.indexOf(newValue), 0, newValue);
            }
            updateFunc([...valuesTemp]);
        },
        handleOpenDays(selectedOpenDays, day, daysSiteIsOpen, setOpenDays) {
            this.updateCheckboxesStateArr(selectedOpenDays, day, daysSiteIsOpen, setOpenDays);
        },
        handleVehicleChange(selectedVehicleRestrictions, restriction, vehicleRestrictions, setVehicleRestrictions) {
            this.updateCheckboxesStateArr(selectedVehicleRestrictions, restriction, vehicleRestrictions, setVehicleRestrictions);
        },
        handleDateInput(e) {
            const min = this.minMaxValues(e.target.name).min;
            const max = this.minMaxValues(e.target.name).max;
            this.dateRangeRestrict(e, min, max);
            this.handleDigitsOnly(e);
        },
        handleDigitsOnly(e) {
            const value = e.target.value;
            e.target.value = parseInt(value.replace(/\D/g, ''), 10);
        },
        dateRangeRestrict(e, min, max) {
            const value = e.target.value;
            if (value > max || value < min) {
              e.target.value = '';
            }
        },
        minMaxValues(fieldName) {
            let min = 0;
            let max = 0;
            switch(fieldName) {
              case "deliveryDay":
                  min = 1; max = 31;
                break;
              case "deliveryMonth":
                  min = 1; max = 12;
                break;
              case "deliveryYear":
                  min = 0; max = 9999;
                break;
              default:
        
                break;
            }
            return { min, max };
        },
        getFieldError(targetName, pageErrors) {
            const errFound = pageErrors && pageErrors.find(err => err.targetName === targetName)
            return errFound?.text || "";
        },
    },
 }
 
 export default HelperMethods;
 