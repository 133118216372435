/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import {
  Button,
  Caption,
  GridCol,
  GridRow,
  H1,
  H2,
  H3,
  Paragraph,
} from "govuk-react";
import { Form } from "react-final-form";
import { useAnalytics } from "@cube/global-components";
import { RequestTranslation } from "../../service/translation";
import { FormContext } from "../../data/contexts/contexts";

const styles = {
  gridMargin: {
    marginTop: "30px !important",
  },
  gridMarginHalf: {
    marginTop: "15px !important",
  },
  spanParagraph: {
    fontSize: "125%",
  },
};

const TestNumbersBoxes = ({ classes }) => {
  const [formContext, setFormContext] = useContext(FormContext);
  const orgType = formContext?.orgType || null;
  const { triggerEvent } = useAnalytics();
  const history = useHistory();
  const windowAllowed = localStorage.getItem("answer");
  const [redirected, setRedirected] = useState(
    formContext?.redirected || false
  );

  const RequestLabels = RequestTranslation(orgType);
  const {
    REQUEST_INPUTS: {
      FORM_TESTING_HEADING_CONFIRMATION,
      TESTNUMBER_HELP,
      TESTNUMBER_HELP_COMMUNITY,
      TESTNUMBER_HELP_COMMUNITY_PART_2,
      TESTNUMBER_HELP_COMMUNITY_PART_3,
    },
  } = RequestLabels;

  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: "nhs:test:organisation-order:test-numbers-boxes",
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "test-numbers-boxes",
        },
      },
    };
    triggerEvent(analyticsData);
  } else {
    window.digitalData = null;
  }
  const getBoldHeaderText = () => {
    if (formContext?.testingApproach === "Bulk Testing") {
      return `Based on the figures entered you have ordered ${
        formContext?.numberOfBoxesToBeDelivered
      } carton${formContext?.numberOfBoxesToBeDelivered < 2 ? "" : "s"}`;
    }
    if (formContext?.testingApproach === "Community Testing") {
      return `Based on the figures entered you have ordered ${
        formContext?.totalNumberOfResidents
      } pallet${formContext?.totalNumberOfResidents < 2 ? "" : "s"}`;
    }
    return `Based on the figures entered you have ordered ${formContext?.numberOfKitsOrdered} test kits`;
  };

  const getNumberOfTestkitsText = () => {
    if (formContext?.testingApproach === "Community Testing") {
      return `You will receive ${formContext?.totalNumberOfResidents} pallet${
        formContext?.totalNumberOfResidents < 2 ? "" : "s"
      } which amounts to
      ${formContext?.numberOfKitsToBeDelivered} test kits.`;
    }
    return `You will receive ${formContext?.numberOfBoxesToBeDelivered} carton${
      formContext?.numberOfBoxesToBeDelivered < 2 ? "" : "s"
    } which amounts to
    ${formContext?.numberOfKitsToBeDelivered} test kits.`;
  };

  const emailLinkRender = ({ href, children }) => <a href={href}>{children}</a>;

  const getContactInfotext = () => {
    if (formContext?.testingApproach === "Community Testing") {
      return (
        <div>
          <Paragraph linkRenderer={emailLinkRender}>
            {TESTNUMBER_HELP_COMMUNITY}
          </Paragraph>
          <Paragraph>{TESTNUMBER_HELP_COMMUNITY_PART_2}</Paragraph>
          <Paragraph>{TESTNUMBER_HELP_COMMUNITY_PART_3}</Paragraph>
        </div>
      );
    }
    return (
      <div>
        <Paragraph>{TESTNUMBER_HELP}</Paragraph>
      </div>
    );
  };

  const onSubmit = () => {
    history.push({
      pathname: redirected
        ? "/confirm-choices"
        : formContext?.testTypeTitle === "Standard swab tests" ||
          formContext?.testTypeTitle ===
            "Swab tests (PCR tests) to return by priority post box"
        ? "/weekendtesting"
        : "/contact",
    });
  };

  return (
    <>
      <Form
        initialValues={formContext}
        onSubmit={(e) => onSubmit(e)}
        render={({ handleSubmit }) => {
          return (
            <form id="testNumbersBoxes" onSubmit={handleSubmit}>
              <GridRow className={classes.gridMargin}>
                <GridCol>
                  <header>
                    <Caption>{formContext?.orderTitle}</Caption>
                    <H1>{FORM_TESTING_HEADING_CONFIRMATION}</H1>
                  </header>
                </GridCol>
              </GridRow>
              <GridRow className={classes.gridMargin}>
                <GridCol>
                  <H2 data-automatedtestingid="orderform-test-numbers-boxes-ordered-amount">
                    {getBoldHeaderText()}
                  </H2>
                </GridCol>
              </GridRow>
              <GridRow className={classes.gridMargin}>
                <GridCol>
                  <span
                    className={classes.spanParagraph}
                    data-automatedtestingid="orderform-test-numbers-boxes-receive-amount"
                  >
                    {getNumberOfTestkitsText()}
                  </span>
                </GridCol>
              </GridRow>
              <GridRow className={classes.gridMargin}>
                <GridCol setWidth="two-thirds">
                  <Paragraph>
                    You can change the number of test kits we will be sending to
                    you by navigating to the previous page and modifying the
                    figures entered there.
                  </Paragraph>
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol>
                  <Button
                    className={classes.gridMarginHalf}
                    data-automatedtestingid="orderform-test-numbers-boxes-continue-button"
                    onClick={(event) => onSubmit(event)}
                  >
                    Confirm
                  </Button>
                </GridCol>
              </GridRow>
              <GridRow className={classes.gridMarginHalf}>
                <GridCol setWidth="two-thirds">{getContactInfotext()}</GridCol>
              </GridRow>
            </form>
          );
        }}
      />
    </>
  );
};

export default withRouter(withStyles(styles)(TestNumbersBoxes));

TestNumbersBoxes.defaultProps = {};

TestNumbersBoxes.propTypes = {
  classes: PropTypes.shape({
    gridMargin: PropTypes.string,
    gridMarginHalf: PropTypes.string,
    spanParagraph: PropTypes.string,
  }).isRequired,
};
