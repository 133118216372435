﻿import React from "react";
import {InputField} from "govuk-react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";
import TextInput from "./TextInput.component";

// eslint-disable-next-line react/prop-types
const HiddenInput = ({ name, initialValue, automatedtestingId, label }) => (

  <Field
    name={name}
    initialValue={initialValue}
    render={({input, meta}) => {
        return (
          <InputField
            
            {...input}
            meta={meta}
            input={{...input, type: "hidden", "data-automatedtestingid": automatedtestingId}}
            name={name}
  
          >
            <span style={{display: "none"}}>{label}</span>
          </InputField>
      );
    }}
  />
);

export default HiddenInput;

HiddenInput.defaultProps = {
  name: "",
};

TextInput.propTypes = {
  name: PropTypes.string,
};