export const inputWidthStyles = {
    'input-width-20': {
        '& input': {
          maxWidth: '41ex',
        }
    },
    'input-width-10': {
        '& input': {
          maxWidth: '23ex',
        }
    },
    'input-width-5': {
        '& input': {
          maxWidth: '10.8ex',
        }
    }, 
    'input-width-4': {
        '& input': {
        maxWidth: '9ex',
        }
    },
    'input-width-3': {
        '& input': {
        maxWidth: '7.2ex',
        }
    },
    'input-width-2': {
        '& input': {
          maxWidth: '5.4ex',
        }
    },
    'width-full': {
        '& input': {
            width: '100% !important',
        }
    },
    'three-quarters': {
        '& input': {
            width: '75% !important',
        }
    },
    'two-thirds': {
        '& input': {
            width: '66.66% !important',
        }
    },
    'one-half': {
        '& input': {
            width: '50% !important',
        }
    },
    'one-third': {
        '& input': {
            width: '33.33% !important',
        }
    },
    'one-quarter': {
        '& input': {
            width: '25% !important',
        }
    },
};