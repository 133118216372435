import React, { useEffect, useState } from "react";
import { GridCol, GridRow, LoadingBox } from "govuk-react";
import { ErrorSummary } from "@cube/global-components";
import ErrorComponent from "../../Error";
import DailyTestingForm from "./DailyTestingForm";
import HelperService from "../../../service/helpers";

const DailyTesting = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorArray, setErrorArray] = useState(null);

  const [serviceErrorFormSub, setServiceErrorFormSub] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [serviceErrorFormSub]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loader]);

  const handleError = (msg) => {
    setServiceErrorFormSub(msg);
  };

  const handleLoader = (hideShowloader) => {
    setLoader(hideShowloader);
  };

  return (
    <>
      <LoadingBox
        loading={loader}
        backgroundColor="#fff"
        timeIn={100}
        timeOut={100}
        backgroundColorOpacity={0.85}
        spinnerColor="#000"
      >
        {errorMessage && (
        <div data-automatedtestingid="orderform-dailytesting-errormessage">
          <GridRow>
            <GridCol setWidth="two-thirds">
              <ErrorSummary
                heading="There is a problem"
                errors={errorArray}
                onHandleErrorClick={HelperService.onHandleErrorClick}
                errorMessage={errorMessage}
              />
            </GridCol>
          </GridRow>
        </div>
        )}
        {serviceErrorFormSub &&
        <ErrorComponent message={serviceErrorFormSub} />}
    
        <DailyTestingForm
          handleSubError={handleError}
          handleLoader={handleLoader}
          setErrorMessage={setErrorMessage}
          setErrorArray={setErrorArray}
        />
      </LoadingBox>
    </>
  );
};

export default DailyTesting;
