import React, { useEffect, useRef } from "react";
import { ErrorSummary } from "govuk-react";

const ErrorSummaryGlobal = (props) => {
    const { automationId, errorMessage, errors } = props;
    const errorSummaryWrapperRef = useRef(null);
    const errorSummaryInnerRef = useRef(null);
  
    useEffect(() => {
        errorSummaryInnerRef.current = errorSummaryWrapperRef.current.querySelector('div');
        errorSummaryInnerRef.current.focus();
    }, [errors, errorMessage]);

    return (
        <div data-automatedtestingid={automationId} ref={errorSummaryWrapperRef}>
            <ErrorSummary {...props} />
        </div>
    );
}

export default ErrorSummaryGlobal;