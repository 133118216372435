/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { Button } from "govuk-react";
import { Form } from "react-final-form";
import { useAnalytics } from "@cube/global-components";
import { RequestTranslation } from "../../service/translation";
import WeekendTestingFields from "./WeekendTestingFields/WeekendTestingFields.component";
import { FormContext } from "../../data/contexts/contexts";

const styles = {
  gridMargin: {
    marginTop: "30px",
  },
};

const WeekendTestingCommitmentForm = ({
  classes,
  handleSubError,
  handleLoader,
}) => {
  const { triggerEvent } = useAnalytics();

  const [formContext, setFormContext] = useContext(FormContext);
  const [selection, setSelection] = useState(formContext?.orgWeekendTestingCommitment || false);
  const orgData = formContext?.crqData || null;
  const orgType = formContext?.orgType || null;
  const history = useHistory();
  const pageLocation = (history.location?.state?.fromSummary) ? './confirm-choices' : './contact';

  useEffect(() => {
    return () => {
      if (history.action === "POP" && history.location.pathname === '/weekendtesting') {
        history.goBack();
      }
    };
  }, []);

  const RequestLabels = RequestTranslation(orgType);
  const {
    REQUEST_INPUTS: { FORM_SUBMIT_BTN_CONTINUE },
  } = RequestLabels;

  const validate = (values) => {};

  const windowAllowed = localStorage.getItem("answer");
  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: "nhs:test:organisation-order:weekend-testing",
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "weekend-testing",
        },
      },
    };
    triggerEvent(analyticsData);
  } else {
    window.digitalData = null;
  }

  const onCheckboxSelect = (checked) => {
    setSelection(checked);
  };

  const onSubmit = (value) => {
    const finalValueToPersist = { ...value };

    let tickState = false;
    if (value.orgWeekendTestingCommitment !== undefined) {
      tickState = Boolean(value.orgWeekendTestingCommitment);
    } else {
      tickState = formContext.orgWeekendTestingCommitment || false;
    }

    setFormContext({
      ...formContext,
      ...finalValueToPersist,
      orgWeekendTestingCommitment: selection,
      pageLocation,
    });

    handleLoader(true);

    history.push({
      pathname: pageLocation
    });

    handleLoader(false);
    handleSubError(null);
  };

  return (
    <div id="weekend-testing-commitment-form">
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <WeekendTestingFields
                orgType={orgType}
                select={onCheckboxSelect}
                testingApproach={orgData.TestingApproach}
                testingType={orgData.TestType}
              />
              <Button
                className={classes.gridMargin}
                data-automatedtestingid="orderform-continue-button"
              >
                {FORM_SUBMIT_BTN_CONTINUE}
              </Button>
            </form>
          );
        }}
      />
    </div>
  );
};

export default withRouter(withStyles(styles)(WeekendTestingCommitmentForm));

WeekendTestingCommitmentForm.defaultProps = {};

WeekendTestingCommitmentForm.propTypes = {
  classes: PropTypes.shape({
    gridMargin: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      crqData: PropTypes.shape({
        City: PropTypes.string,
        OrganisationName: PropTypes.string,
        Postcode: PropTypes.string,
        Street: PropTypes.string,
        TestingApproach: PropTypes.string,
        cqcRegistrationID: PropTypes.string,
      }).isRequired,
      crqId: PropTypes.string.isRequired,
      orgType: PropTypes.string.isRequired,
    }),
  }).isRequired,
  handleSubError: PropTypes.func.isRequired,
  handleLoader: PropTypes.func.isRequired,
};
