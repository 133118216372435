// eslint-disable-next-line import/no-extraneous-dependencies
import React from "react";
import PropTypes from "prop-types";
import {EmailInput, HiddenInput, NumberInput, TextInput, TextInputPostcode} from "./InputField";

const InputComponent = ({ initialBlank, initialValue, label, name, hint, errMessage, errMessageNumber, errMsgBackend, errMsgBackendPresent, width, type, notrequired, automatedtestingId, restrictedCharacters, disallowNumbers, onInput}) => {
  switch (type) {
    case "text":
      return (
        <TextInput
          label={label}
          errMessage={errMessage}
          errMsgBackend={errMsgBackend}
          errMsgBackendPresent={errMsgBackendPresent}
          hint={hint}
          id={name}
          name={name}
          initialValue={initialValue}
          width={width}
          notrequired={notrequired}
          automatedtestingId={automatedtestingId}
          restrictedCharacters={restrictedCharacters}
          disallowNumbers={disallowNumbers}
        />
      );

      case "textPostcode":
        return (
          <TextInputPostcode
            label={label}
            errMessage={errMessage}
            hint={hint}
            name={name}
            initialBlank={initialBlank}
            initialValue={initialValue}
            width={width}
            notrequired={notrequired}
            automatedtestingId={automatedtestingId}
            restrictedCharacters={restrictedCharacters}
            
          />
        );

    case "number":
      return (
        <NumberInput
          label={label}
          errMessage={errMessage}
          errMessageNumber={errMessageNumber}
          errMsgBackend={errMsgBackend}
          errMsgBackendPresent={errMsgBackendPresent}
          hint={hint}
          name={name}
          initialValue={initialValue}
          width={width}
          notrequired={notrequired}
          automatedtestingId={automatedtestingId}
          onInput={onInput || ""}
        />
      );
    case "email":
      return (
        <EmailInput
          label={label}
          errMessage={errMessage}
          hint={hint}
          name={name}
          initialValue={initialValue}
          width={width}
          notrequired={notrequired}
          automatedtestingId={automatedtestingId}
          restrictedCharacters={restrictedCharacters}
        />
      );
    case "hidden":
      return (
        <HiddenInput
          name={name}
          initialValue={initialValue}
          automatedtestingId={automatedtestingId}
          label={label}
        />
      );
    default:
      return null;
  }
};

export default InputComponent;

InputComponent.defaultProps = {
   hint: "",
   errMessage: "",
   errMessageNumber: "",
   initialBlank: false,
   initialValue: "",
   width: "width-full",
   notrequired: false
};

InputComponent.propTypes = {
  initialBlank: PropTypes.bool,
  initialValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "email", "number", "hidden"]).isRequired,
  hint: PropTypes.string,
  label: PropTypes.string.isRequired,
  errMessage: PropTypes.string,
  errMessageNumber: PropTypes.string,
  width: PropTypes.oneOf([
    'input-width-40',
      'input-width-20',
      'input-width-10',
      'input-width-5',
      'input-width-4',
      'input-width-3',
      'input-width-2',
      'width-full',
      'three-quarters',
      'two-thirds',
      'one-half',
      'one-third',
      'one-quarter',
    ]
  )
};
