const numberValidator = (errorMessage, errorMessageNumber) => (value) => {
  const errMsg = !errorMessage ? "Required" : errorMessage;
  const numRegex = /^\d+$/;
  if (value && !numRegex.test(value)) {
    return errorMessageNumber;
  }
  return value ? undefined : errMsg;
};

const textValidator = (errorMessage) => (value) => {
  const errMsg = !errorMessage ? "Required" : errorMessage;
  return value ? undefined : errMsg;
};

export { numberValidator, textValidator };
