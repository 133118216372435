import { CancellationRequestLabels } from "../../data/constants";

const CancellationRequestTranslation = (organisation) => {
  const labels = CancellationRequestLabels;
  labels.INFO = { ...labels.INFO, ...labels.INFO.DEFAULT };
  labels.ERROR_MESSAGES = {
    ...labels.ERROR_MESSAGES,
    ...labels.ERROR_MESSAGES.DEFAULT,
  };

  return {
    ...labels,
  };
};

export default CancellationRequestTranslation;
