import React from "react";
import PropTypes from "prop-types";
import {InputField} from "govuk-react";
import {Field} from "react-final-form";
import withStyles from "react-jss";
import {inputWidthStyles} from "../../../../data/jss/Input.component.jss";
import inputWidthTranslator from "../../../../service/jss";

const styles = inputWidthStyles;

const checkRestricted = (src, restricted) => {
  return !!src?.split("").some(ch => restricted.indexOf(ch) !== -1);
};

const EmailInput = ({label, name, initialValue, hint, errMessage, width, classes, notrequired, automatedtestingId, restrictedCharacters}) => {

  const emailValidator = (errMessage) => (value) => {

    //const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{0,})$/i;
    //const regex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    
    if (regex.test(value) && !checkRestricted(value, restrictedCharacters) && restrictedCharacters !== "") {
      return undefined;
    } else if (checkRestricted(value, restrictedCharacters) && restrictedCharacters !== "") {
      return `${label} must not include ${restrictedCharacters}`;
    } else {
      return regex.test(value) ? undefined : errMessage;
    }
  };
  
  return (
    <>
      <Field
        validate={notrequired ? false : emailValidator(errMessage)}
        label={label}
        name={name}
        initialValue={initialValue}
        render={({input, meta}) => (
            <InputField
                {...input}
                meta={meta}
                value={input.value}
                input={{...input, type: "text", "data-automatedtestingid": automatedtestingId}}
                name={name}
                hint={[hint]}
                className={inputWidthTranslator(width, classes)}
            >
              {label}
            </InputField>
        )}
    />
  </>
  );
};

export default withStyles(styles)(EmailInput);

EmailInput.defaultProps = {
  name: "",
  hint: "",
  label: "",
  errMessage: "",
  errMessageNumber: "",
  width: "",
  initialValue: "",
  restrictedCharacters: ""
};

EmailInput.propTypes = {
  name: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  initialValue: PropTypes.string,
  errMessage: PropTypes.string,
  errMessageNumber: PropTypes.string,
  width: PropTypes.string
};
