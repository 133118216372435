/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable dot-notation */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { Button, GridCol, GridRow, H1, Paragraph } from "govuk-react";
import { ErrorSummary } from "@cube/global-components";
import { Form } from "react-final-form";
import DeliveryInstructionsFields from "./DeliveryInstructionsFields.component";
import HelperService from "../../service/helpers/index";
import { FormContext } from "../../data/contexts/contexts";
import { useAnalytics } from "../../service/adobe-analytics";
import CharRestrictionSets from "../../data/constants/charRestrictionSets.constants";

const styles = {
  gridMargin: {
    marginTop: "30px",
  },
  submitButtonFontSize: {
    fontSize: "19px",
  },
  main: {
    flex: 1,
  },
  h1Margin: {
    marginBottom: "10px",
  },
  pMargin: {
    marginBottom: 0,
  },
};

const DeliveryInstructionsForm = ({
  classes,
  location,
  handleSubError,
  handleLoader,
  helperMethods,
}) => {
  const { triggerEvent } = useAnalytics();
  const history = useHistory();
  const [formContext, setFormContext] = useContext(FormContext);
  const [errorArray, setErrorArray] = useState(null);
  const [errMessage, setErrorMsg] = useState("");
  const [fieldsValues, setFieldsValues] = useState([]);

  const windowAllowed = localStorage.getItem("answer");
  if (windowAllowed !== null) {
    const analyticsData = {
      page: {
        pageInfo: {
          pageName: "nhs:test:organisation-order:delivery-instructions",
        },
        category: {
          primaryCategory: "organisation-order",
          subCategory1: "delivery-instructions",
        },
      },
    };
    triggerEvent(analyticsData);
  } else {
    window.digitalData = null;
  }

  const validate = (values) => {};

  const validateFields = () => {
    const {
      deliveryInstructionsForm: { elements },
    } = window;
    const errorArr = [];

    setErrorArray(null);
    setErrorMsg(null);

    if (
      !elements.orderOpenHour.value ||
      elements.orderOpenHour.value.trim().length === 0 ||
      !elements.orderOpenMinute.value ||
      elements.orderOpenMinute.value.trim().length === 0
    ) {
      errorArr.push({
        targetName: "orderOpenHour",
        text: "Enter the site opening time",
      });
    }

    if (
      !elements.orderCloseHour.value ||
      elements.orderCloseHour.value.trim().length === 0 ||
      !elements.orderCloseMinute.value ||
      elements.orderCloseMinute.value.trim().length === 0
    ) {
      errorArr.push({
        targetName: "orderCloseHour",
        text: "Enter the site closing time",
      });
    }

    if (!helperMethods.form.areDaysOpen(elements.daysOpen)) {
      errorArr.push({
        targetName: "daysOpen",
        text: "Select the days when the site is open",
      });
    }

    if (!helperMethods.form.getRadioValue(elements.orderOffloadFacilities)) {
      errorArr.push({
        targetName: "orderOffloadFacilities",
        text: "Select one of the following offload facilities",
      });
    }

    if (
      elements?.uvRestrictionInformation &&
      HelperService.checkRestricted(
        elements.uvRestrictionInformation.value,
        CharRestrictionSets.DeliveryInstructionsForm.uvRestrictionInformation
      )
    ) {
      errorArr.push({
        targetName: "uvRestrictionInformation",
        text: `Other information must not include ${CharRestrictionSets.DeliveryInstructionsForm.uvRestrictionInformation}`,
      });
    }

    const atLeastOneDateValue =
      elements?.deliveryDay?.value ||
      elements?.deliveryMonth?.value ||
      elements?.deliveryYear?.value;
    const dateCompleted =
      elements?.deliveryDay?.value &&
      elements?.deliveryMonth?.value &&
      elements?.deliveryYear?.value;

    if (atLeastOneDateValue && !dateCompleted) {
      errorArr.push({
        targetName: "deliveryDay",
        text: "Enter the suggested delivery date",
      });
    }

    if (dateCompleted) {
      const inputDate = new Date(
        `${elements?.deliveryMonth?.value}/${elements?.deliveryDay?.value}/${elements?.deliveryYear?.value}`
      );
      const currentDate = new Date();
      if (inputDate <= currentDate) {
        errorArr.push({
          targetName: "deliveryDay",
          text: "The suggested delivery date must be in the future",
        });
      }
    }

    if (errorArr.length > 0) {
      setErrorArray(errorArr);
      setErrorMsg("There is a problem");
      return false;
    }

    return true;
  };

  const onSubmit = (values) => {
    if (validateFields()) {
      history.push({
        pathname: "/confirm-choices",
      });
      const fields = { ...values, ...fieldsValues };
      const uvRestrictionInformation = fields?.uvRestrictionInformation || "";
      setFormContext({
        ...formContext,
        ...fields,
        uvRestrictionInformation,
        deliveryNotes: helperMethods.form.communityDeliveryNotes(
          fields,
          fieldsValues
        ),
      });
    }
  };

  const getFieldValues = (values) => {
    setFieldsValues(values);
  };

  const handleFieldsOnBlur = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {errMessage && (
        <GridRow>
          <GridCol setWidth="two-thirds">
            <ErrorSummary
              heading="There is a problem"
              errors={errorArray}
              onHandleErrorClick={HelperService.onHandleErrorClickAlternative}
              errorMessage={errMessage}
            />
          </GridCol>
        </GridRow>
      )}

      <GridRow>
        <GridCol setWidth="two-thirds">
          <header>
            <H1 className={classes.h1Margin}>Delivery instructions</H1>
          </header>
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol setWidth="two-thirds">
          <Paragraph className={classes.pMargin}>
            Enter the delivery instructions for your organisation to ensure the
            order is delivered successfully to you.
          </Paragraph>
        </GridCol>
      </GridRow>

      <Form
        onSubmit={onSubmit}
        initialValues={formContext}
        validate={validate}
        render={({ handleSubmit, form }) => {
          return (
            <form onSubmit={handleSubmit} id="deliveryInstructionsForm">
              <DeliveryInstructionsFields
                form={form}
                pageErrors={errorArray}
                fieldsValues={(values) => getFieldValues(values)}
                helperMethods={helperMethods.fields}
              />
              <Button
                onClick={(event) => {
                  validateFields(event);
                }}
                onMouseDown={(event) => handleFieldsOnBlur(event)}
                data-automatedtestingid="orderform-continue-button"
                className={`${classes.gridMargin} ${classes.submitButtonFontSize}`}
              >
                Continue
              </Button>
            </form>
          );
        }}
      />
    </>
  );
};

export default withRouter(withStyles(styles)(DeliveryInstructionsForm));

DeliveryInstructionsForm.defaultProps = {};

DeliveryInstructionsForm.propTypes = {
  classes: PropTypes.shape({
    gridMargin: PropTypes.string,
    submitButtonFontSize: PropTypes.string,
  }).isRequired,
  handleLoader: PropTypes.func.isRequired,
};
