let adobeAnalyticsURL;

if (window.location.hostname.includes("gov.uk")) {
  adobeAnalyticsURL =
    "//assets.adobedtm.com/launch-ENe7f6cdd7cc05409b86547d9153429788.min.js";
} else if (window.location.hostname.includes("preprod")) {
  adobeAnalyticsURL =
    "//assets.adobedtm.com/launch-ENa55251f775954d9592d110a6b4ad26a1-staging.min.js";
} else {
  adobeAnalyticsURL =
    "//assets.adobedtm.com/f8560165ec6a/5d3b7fb65898/launch-4f6a26e8db5f-development.min.js";
}

const AdobeAnalyticsURL = adobeAnalyticsURL;

export default AdobeAnalyticsURL;
