import React from "react";
import PropTypes from "prop-types";
import {Checkbox} from "govuk-react";
import {Field} from "react-final-form";

const CheckboxComponent = ({label, name, text, className}, children, automatedtestingId) => (

  <Field
    label={label}
    name={name}
    type="checkbox"
    className={className}
    
    render={({ input, meta }) => {
      return (
        <Checkbox
          {...input}
          value={input.value}
          meta={meta}
          name={name}
          className={className}
          data-automatedtestingid={automatedtestingId}
            >
          {text}
        </Checkbox>
      )
    }}
  />
);

export default CheckboxComponent;

CheckboxComponent.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired, 
    automatedtestingId: PropTypes.string,
};
