/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { BackLink, Footer, Page } from "govuk-react";
import TopNavBar from "./components/TopNavBar";
import UONForm from "./components/UON";
import { ConfirmChoices, Confirmation } from "./components/Confirmation";
import NotFound from "./components/NotFound";
import OrgDetails from "./components/OrgDetails/OrgDetails.component";
import TestNumbers from "./components/TestNumbers/TestNumbers";
import TestNumbersBoxes from "./components/TestNumbersBoxes/TestNumbersBoxes.component";
import { FormContext } from "./data/contexts/contexts";
import OrgOverview from "./components/RequestForm/OrderOverview/OrderOverview.component";
import OrgNotEligible from "./components/OrgNotEligible";
import WeekendTestingCommitment from "./components/WeekendTestingCommitment/WeekendTestingCommitment";
import ContactInfoFormPage from "./components/RequestForm/ContactFields/ContactInfoFormPage";
import MultipleAddress from "./components/MultipleAddress/MultipleAddress.component";
import DailyTesting from "./components/RequestForm/DailyTesting/DailyTesting";
import CookieChoice from "./components/CookieConsent/CookiesChoice/CookieChoice.component";
import CookieConfirm from "./components/CookieConsent/CookieConfirmation";
import CookieBannerActive from "./components/CookieConsent/CookieBanner";
import CookieInfo from "./components/CookieConsent/CookiesInformation/CookieInformation.component";
import TrainingConfirmationPage from "./components/TrainingConfirmation/TrainingConfirmation";
import DeliveryInstructionsFormPage from "./components/DeliveryInstructions/DeliveryInstructionsFormPage";
import CancellationReceived from "./components/CancellationReceived/CancellationReceived.component";
import OrderCancellationRequest from "./components/OrderCancellationRequest/OrderCancellationRequest.component";
import DormancyPage from "./DormancyPage";

//Tag for dormancy, this can be changed to an env variable to make it dormant or not outside of the code.
const IS_DORMANT = true;
function App() {
  const history = useHistory();
  const location = useLocation();
  const [formContext, setFormContext] = useState(FormContext);
  const [locationKeys, setLocationKeys] = useState([]);
  const answered = localStorage.getItem("answer");
  const answeredNo = localStorage.getItem("answerNo");

  useEffect(() => {
    return history.listen((loc) => {
      if (history.action === "PUSH") {
        setLocationKeys([loc.key]);
      }

      if (history.action === "POP") {
        if (locationKeys.length > 1 && locationKeys[1] !== loc.key) {
          setLocationKeys((keys) => [loc.key, ...keys]);
          // Handle back event
          if (history.location.pathname === "/") {
            setFormContext(FormContext);
          }
        } else if (history.location.pathname === "/") {
          setFormContext(null);
        }
      }
    });
  }, [locationKeys, history]);

  const manageBackLink = () => {
    history.goBack();
  };

  // eslint-disable-next-line no-unused-vars
  const [hideBanner, setHideBanner] = useState("");
  const selectedCookie = answered === null && answeredNo === null;
  function BannerTwo(props) {
    const notCookies = window.location.pathname;
    const isLoggedIn = props.isLoggedIn && !notCookies.includes("cookie");
    if (isLoggedIn) {
      return <CookieBannerActive />;
    }
    return true;
  }

  const pathsWithBackButton = [
    "/details",
    "/overview",
    "/address",
    "/test-numbers",
    "/test-numbers-boxes",
    "/not-eligible-to-order",
    "/daily-testing",
    "/weekendtesting",
    "/training-confirmation",
    "/contact",
    "/error",
    "/cookies",
    "/choose-cookies",
    "/cookies-saved",
    "/confirm-choices",
    "/delivery-instructions",
    "/cancellation-request",
  ];

  const RouterRender = () => {
    return (
      <FormContext.Provider value={[formContext, setFormContext]}>

        <Switch>

          <Route path="/cookies">
            <CookieInfo />
          </Route>

          <Route path="/choose-cookies">
            <CookieChoice />
          </Route>

          <Route path="/cookies-saved">
            <CookieConfirm />
          </Route>

          <Route exact path="/details">
            {formContext?.orgType && !(formContext.orderSubmitted) ? <OrgDetails /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/not-eligible-to-order">
            <OrgNotEligible />
          </Route>

          <Route exact path="/overview">
            {formContext?.orgType && !(formContext.orderSubmitted) ? <OrgOverview /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/test-numbers">
            {formContext?.showOrderFormForTestingType && !(formContext.orderSubmitted) ? (<TestNumbers />) : (<Redirect to="/" />)}
          </Route>

          <Route exact path="/test-numbers-boxes">
            {formContext?.showOrderFormForTestingType && (!formContext.orderSubmitted) ? (<TestNumbersBoxes />) : (<Redirect to="/" />)}
          </Route>

          <Route exact path="/daily-testing">
            {formContext?.orgType && !(formContext.orderSubmitted) ? <DailyTesting /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/address">
            {formContext?.orgType && !(formContext.orderSubmitted) ? <MultipleAddress /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/weekendtesting">
            {formContext?.orgType && !(formContext.orderSubmitted) ? (<WeekendTestingCommitment />) : (<Redirect to="/" />)}
          </Route>

          <Route exact path="/contact">
            {formContext?.orgType && !(formContext.orderSubmitted) ? (<ContactInfoFormPage />) : (<Redirect to="/" />)}
          </Route>

          <Route exact path="/training-confirmation">
            {formContext?.orgType && !(formContext.orderSubmitted) ? (<TrainingConfirmationPage />) : (<Redirect to="/" />)}
          </Route>

          <Route exact path="/confirm-choices">
            {formContext?.orgType && !(formContext.orderSubmitted) ? <ConfirmChoices /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/submission">
            {location.state ? <Confirmation /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/delivery-instructions">
            {formContext?.orgType && !(formContext.orderSubmitted) ? <DeliveryInstructionsFormPage /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/cancellation-received">
            {formContext?.orderId ? <CancellationReceived /> : <Redirect to="/" />}
          </Route>
          
          <Route exact path="/cancellation-request">
            {formContext?.orderId ? <OrderCancellationRequest /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/">
            <UONForm />
          </Route>

          <Route exact path="/page-not-found">
            <NotFound />
          </Route>
          
          <Route path="/*">
            <Redirect to="/page-not-found" />
          </Route>
        </Switch>
      </FormContext.Provider>
    );
  };

  const backLinkStyle = {
    cursor: "pointer",
    background: "transparent",
    border: "none",
    borderBottom: "1px solid #0b0c0c",
  };

  return (
    <>
      <Page
        header={[<BannerTwo isLoggedIn={selectedCookie} key="banner-two" />, <TopNavBar key="top-nav-bar" />]}
        beforeChildren={
          // Includes defines whether the back button should show.
          // It takes the current path (minus '/') and checks if it is one of the uniqueId form routes.
          pathsWithBackButton.includes(location.pathname) && (
            <BackLink as="button" style={backLinkStyle} onClick={manageBackLink} />
          )
        }
        footer={(
          <Footer
            meta={(
              <Footer.MetaLinks heading="Support links">
                <Footer.Link href="https://www.gov.uk/help">Help</Footer.Link>
                <Footer.Link href="https://www.gov.uk/help/privacy-notice">
                  Privacy
                </Footer.Link>
                <Footer.Link to="/cookies" as={Link}>
                  Cookies statement
                  {' '}
                  {CookieInfo}
                </Footer.Link>
                <Footer.Link href="https://www.gov.uk/contact">
                  Contact
                </Footer.Link>
                <Footer.Link href="https://www.gov.uk/help/accessibility-statement">
                  Accessibility statement
                </Footer.Link>
                <Footer.Link href="https://www.gov.uk/help/terms-conditions">
                  Terms and conditions
                </Footer.Link>
                <Footer.Link href="https://www.gov.uk/cymraeg">
                  Rhestr o Wasanaethau Cymraeg
                </Footer.Link>
              </Footer.MetaLinks>
            )}
            style={{ marginTop: "auto" }}
            copyright={{
              text: "Crown copyright",
              link:
                "https://www.nationalarchives.gov.uk/information-management/re-using-public-sector-information/uk-government-licensing-framework/crown-copyright/",
              image: {
                source:
                  "https://www.gov.uk/assets/static/images/govuk-crest-bb9e22aff7881b895c2ceb41d9340804451c474b883f09fe1b4026e76456f44b.png",
                height: 102,
                width: 125,
              },
            }}
          />
        )}
      >
        {IS_DORMANT ? (
          <Switch>
            <Route exact path="/*">
              <DormancyPage />
            </Route>
          </Switch>
        ) : (
          <RouterRender />
        )}
      </Page>
    </>
  );
}

App.propTypes = {};

export default App;
