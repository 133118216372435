import React from "react";
import PropTypes from "prop-types";
import { InputField } from "govuk-react";
import { Field } from "react-final-form";
import withStyles from "react-jss";
import { inputWidthStyles } from "../../../../data/jss/Input.component.jss";
import inputWidthTranslator from "../../../../service/jss";

const styles = inputWidthStyles;

const checkRestricted = (src, restricted) => {
  return !!src?.split("").some((ch) => restricted.indexOf(ch) !== -1);
};

const TextInputPostcode = ({
  initialBlank,
  initialValue,
  label,
  name,
  hint,
  errMessage,
  width,
  classes,
  notrequired,
  automatedtestingId,
  restrictedCharacters,
}) => {
  const checkPostcodeFormat = (errMessage) => (value) => {};

  const regexPostcode = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;

  const textValidator = (errMessage) => (value) => {
    if (checkRestricted(value, restrictedCharacters)) {
      return `${label} must not include ${restrictedCharacters}`;
    } else if (!value) {
      return errMessage ? errMessage : "Required";
    } else if (!regexPostcode.test(value)) {
      return "Enter a correct UK postcode";
    } else {
      return undefined;
    }
  };

  const textValidatorNotRequired = (errMessage) => (value) => {
    if (checkRestricted(value, restrictedCharacters)) {
      return `${label} must not include ${restrictedCharacters}`;
    }
  };

  return (
    <Field
      validate={
        notrequired
          ? textValidatorNotRequired(errMessage)
          : textValidator(errMessage)
      }
      label={label}
      name={name}
      initialBlank={initialBlank}
      initialValue={initialValue}
      render={({ input, meta }) => {
        if (initialBlank === true) {
          delete input.value;
          return (
            <InputField
              {...input}
              meta={meta}
              input={{
                ...input,
                type: "text",
                "data-automatedtestingid": automatedtestingId,
              }}
              name={name}
              hint={[hint]}
              className={inputWidthTranslator(width, classes)}
              value=""
            >
              {label}
            </InputField>
          );
        } else {
          return (
            <InputField
              {...input}
              meta={meta}
              input={{
                ...input,
                type: "text",
                "data-automatedtestingid": automatedtestingId,
              }}
              name={name}
              hint={[hint]}
              className={inputWidthTranslator(width, classes)}
              value={input.value}
            >
              {label}
            </InputField>
          );
        }
      }}
    />
  );
};

export default withStyles(styles)(TextInputPostcode);

TextInputPostcode.defaultProps = {
  name: "",
  hint: "",
  label: "",
  width: "full-width",
  errMessage: "",
  initialBlank: false,
  initialValue: "",
  restrictedCharacters: "",
};

TextInputPostcode.propTypes = {
  name: PropTypes.string,
  errMessage: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.string,
  initialBlank: PropTypes.bool,
  initialValue: PropTypes.string,
  restrictedCharacters: PropTypes.string,
};
