/* eslint-disable react/jsx-boolean-value */
import React from "react";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { Caption, GridCol, GridRow, H1, Paragraph } from "govuk-react";
import { RadioComponent } from "@cube/global-components";

const styles = {
  gridMargin: {
    marginTop: "30px",
  },
  checkBoxAlign: {
    "& span": {
      padding: "0 0.5rem",
      marginTop: "-0.2rem",
    },
  },
  hidden: {
    display: "none",
  },
  fieldset: {
    padding: 0,
    border: 0,
    margin: 0,
  },
};

const DailyTestingFields = ({ classes }) => {
  return (
    <>
      <GridRow className={classes.gridMargin}>
        <GridCol setWidth="two-thirds">
          <header>
            <Caption>Lateral flow testing</Caption>
            <H1>Is anyone at your organisation doing daily contact testing?</H1>
          </header>
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol setWidth="two-thirds">
          <Paragraph>
            This is where someone at your organisation testing positive for the
            virus and is self-isolating, but their close contacts take a test
            each day for 7 days so they can keep working.
          </Paragraph>
        </GridCol>
      </GridRow>

      <GridRow className={classes.gridMargin}>
        <GridCol setWidth="two-thirds">
          <div>
            <fieldset className={classes.fieldset}>
              <legend className={classes.hidden}>Is anyone at your organisation doing daily contact testing?</legend>
              <RadioComponent
                label="Yes"
                name="orgDailyContactTesting"
                value="1"
                displayInline
              />
              <RadioComponent
                label="No"
                name="orgDailyContactTesting"
                value="0"
                displayInline
              />
            </fieldset>
          </div>
        </GridCol>
      </GridRow>
    </>
  );
};

export default withStyles(styles)(DailyTestingFields);

DailyTestingFields.propTypes = {
  classes: PropTypes.shape({
    fieldset: PropTypes.string,
    hidden: PropTypes.string,
    gridMargin: PropTypes.string,
    checkBoxAlign: PropTypes.string,
  }).isRequired,
};
