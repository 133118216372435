import axios from "axios";

let url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_URL
    : process.env.REACT_APP_URL;
url = `${url}/services/apexrest/`;

const Axios = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
  },
});

export default {
  request(options) {
    return Axios.request(options);
  },
};
