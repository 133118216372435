import React from "react";

const DormancyPage = () => {
  return (
    <>
      <div class="govuk-width-container">
        <div
          class="govuk-notification-banner"
          role="region"
          aria-labelledby="govuk-notification-banner-title"
          data-module="govuk-notification-banner"
        >
          <div class="govuk-notification-banner__header">
            <h2
              class="govuk-notification-banner__title"
              id="govuk-notification-banner-title"
            >
              Important
            </h2>
          </div>
          <div class="govuk-notification-banner__content">
            <p class="govuk-notification-banner__heading" id="box-title">Form Discontinued</p>
            <p class="govuk-body-s" id="box-date">8th March 2024</p>
          </div>
        </div>
        <div class="govuk-grid-row">
            <div class="govuk-grid-column-two-thirds">
              <h1 class="govuk-heading-s" id="title">This form was withdrawn on 8th March 2024</h1>
              <p class="govuk-body" id="paragraph">This form has been discontinued following the change in Covid-19 guidelines.</p>
              <p class="govuk-body" id="more-info">
              For the latest guidance on COVID 19, please visit - <a id="url" href="https://www.gov.uk/coronavirus" class="govuk-link" rel="noreferrer noopener" target="_blank">COVID-19: guidance and support - GOV.UK (www.gov.uk)</a>
              </p>
            </div>
          </div>
      </div>
    </>
  );
};

export default DormancyPage;
